<template>
    <div class="row">
        <div class="col-lg-9 col-xl-10" id="main-col">
            <job-editor
                v-on:tabChange="showTab"
                :editMode="true"
                :jobType="jobType"
                v-model="yamlContent"
                :activeTab="activeTab"
                :customLint="true"
                :customLintErrors="lintErrors"
            ></job-editor>
        </div>
        <div class="col">
            <button
                :disabled="disableButtons"
                class="btn btn-success d-block mb-2 w-100"
                @click="saveCode"
            >
                <span v-if="saving"
                    >Saving
                    <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                    ></div
                ></span>
                <span v-else>Save</span>
            </button>            
            <div class="btn-group d-block mb-2 " role="group" aria-label="Button group with nested dropdown">
                <span class="d-inline-block" :class="(lastDeployId || this.lastDeployDetails[this.job.name])?'w-75':'w-100'" tabindex="0" data-bs-toggle="tooltip"  data-bs-title="Save changes before deploying" data-bs-placement="left">
                    <button
                        :disabled="disableButtons || disableDeploy"
                        class="btn btn-primary w-100"
                        
                        @click="deployCode"
                    >
                        <span v-if="deploying"
                            >Deploying
                            <div
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></div
                        ></span>
                        <span v-else>Deploy</span>
                    </button>
                </span>
                
                <button v-if="(lastDeployId || this.lastDeployDetails[this.job.name])" type="button" @click="getLastStatus" class="btn btn-outline-primary w-25" data-bs-toggle="tooltip"  data-bs-placement="top" title="Get Last Run Status"><i class="fa fa-info-circle fa-lg" aria-hidden="true" ></i></button>                
            </div>            
            <button
                :disabled="false"
                class="btn btn-secondary d-block mb-2 w-100"
                @click="lintCode"
            >
                <span v-if="linting"
                    >Linting
                    <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                    ></div
                ></span>
                <span v-else>Lint</span>
            </button>
            <button
                :disabled="false"
                class="btn btn-secondary d-block mb-2 w-100"
                @click="formatCode"
            >
                <span v-if="formatting"
                    >Formatting
                    <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                    ></div
                ></span>
                <span v-else>Format</span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import JobEditor from "../../../components/sandbox/JobEditor.vue";
import { mapActions , mapState} from "vuex";
export default {
    components: { JobEditor },
    data() {
        return {
            editMode: false,
            jobType: this.job.kind,
            yamlContent: {},
            initialJobContent: {},
            jobObj: {},
            activeTab: "handler.go",
            sandboxId: this.$route.params.sandboxId,
            lastDeployId: null,
            lintErrors: [],
            disableButtons: false,
            disableDeploy: false,
            deploying: false,
            linting: false,
            formatting: false,
            saving: false,
            lambdaUrl:
                "https://fl6vpsxenljxctui7yfq5ixljq0kwqqg.lambda-url.us-west-2.on.aws",
        };
    },
    props: ["job"],
    computed: {
        handlerCode() {
            return this.yamlContent["handler.go"]
                ? this.yamlContent["handler.go"]
                : "";
        },
       
        ...mapState({
            lastDeployDetails: state => state.aio.handlerLastDeployDetails,
            handlerLogs: state => state.aio.handlerLogs
        }),
    },
    watch: {
        yamlContent: {
            handler (newVal,oldVal) {
                //Disable is false of initial load
                if(oldVal && Object.keys(oldVal).length && (JSON.stringify(newVal) !== JSON.stringify(this.initialJobContent)))
                    this.disableDeploy = true;
                else
                    this.disableDeploy = false;
            },
            deep: true
        },
        handlerLogs: {
            handler (newVal,oldVal) {
                this.getLogErrors(newVal)
            },
            deep: true
        }   
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
            updateSandboxJob: "sandbox/updateSandboxJob",
            fetchSandboxJobRuns: "sandbox/fetchSandboxJobRuns",   
            performLint: "aio/performLint"  
        }),
        initateContent() {
            this.jobObj = this.job;

            let yamlContent = {};

            if (this.job.system_files) {
                for (const i of this.job.system_files) {
                    yamlContent[i.relative_path] = i.content;
                }
            }
            if (this.job.user_files) {
                for (const i of this.job.user_files) {
                    yamlContent[i.relative_path] = i.content;
                }
            }
            //Initially activeTab value is empty or undefined otherwise no need to reset to first value
            if (!this.activeTab || this.activeTab == "")
                this.activeTab = "handler.go";

            this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
            this.initialJobContent = JSON.parse(JSON.stringify(yamlContent));
        },
        showTab(tab) {
            this.lintErrors =[];
            this.activeTab = tab;
        },
        setLintErrors(errorList){
            var errors = [];
            for (const error of errorList) {
               errors.push({
                    message: error["message"],
                    severity: 'error',
                    from: [error["line"], error["char"]],
                    to: [error["line"], error["char"]],
                });
            }
            this.lintErrors = errors
        },
        lintCode() {
            const payload = {
                lint: true,
                code: this.handlerCode,
            };
            this.lintErrors = [];
            this.disableButtons = true;
            this.linting = true;
            const promise = this.performLint(payload).then((res) => {
                    if (res.data.lint_passed)
                        this.$store.dispatch("addToast", {
                            message: "No issues detected",
                            type: "success",
                        });
                    else {
                        const errors = res.data.errors;
                        this.setLintErrors(errors);
                        this.$store.dispatch("addToast", {
                            message:
                                "Error found, please check code",
                            type: "error",
                        });
                    }
                })
                .catch((error) => {
                    self.error = error.message;
                })
                .finally(() => {
                    this.linting = false;
                    this.disableButtons = false;
                });
        },
        formatCode() {
            const payload = {
                format: true,
                code: this.handlerCode,
            };
            this.lintErrors = [];
            this.disableButtons = true;
            this.formatting = true;
            const promise = this.performLint(payload).then((res) => {
                if(!res.data.formatted_code.includes("Syntax error can't format code")){
                    this.$store.dispatch("addToast", {
                        message: "Formatted successfully.",
                        type: "success",
                    });
                    this.yamlContent["handler.go"] = res.data.formatted_code;
                }
                else 
                    this.$store.dispatch("addToast", {
                        message:"Syntax error can't format code",
                        type: "error",
                    });
                })
                .catch((error) => {
                    self.error = error.message;
                })
                .finally(() => {
                    this.disableButtons = false;
                    this.formatting = false;
                });
        },
        async saveCode() {
            this.saving = true;
            this.disableButtons = true;  
            this.lintErrors=[]         
            this.jobObj.system_files = [
                {
                    relative_path: "config.yaml",
                    content: this.yamlContent["config.yaml"],
                },
            ];
            this.jobObj.user_files = [
                {
                    relative_path: "handler.go",
                    content: this.yamlContent["handler.go"],
                },
            ];
            const response = await this.updateSandboxJob(this.jobObj);
            if (response.status) {
                this.$store.dispatch("addToast", {
                    message: "Changes saved successfully.",
                    type: "success",
                });
                this.initialJobContent = JSON.parse(JSON.stringify(this.yamlContent));
            }
            this.saving = false;
            this.disableButtons = false;
            this.disableDeploy = false;
            this.$store.dispatch('aio/setHandlerLog', { handlerId: `${this.job.name}-${this.sandboxId}`, log: "" });
        },
        deployCode() {
            this.disableButtons = true;
            this.deploying = true;
            let self = this;
            this.$store.dispatch('aio/setHandlerLog', { handlerId: `${this.job.name}-${this.sandboxId}`, log: "" });
            const promise = axios
                .post(
                    `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.sandboxId}/jobs/${this.job.name}/executions`,
                    {
                        "env_params": {
                            "OP": "deploy"
                        }
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    //ShowHandlerExecution
                    self.showModal({
                        component: "ShowHandlerExecution",
                        data: {
                            jobName: self.job.name,
                            sandboxId: self.sandboxId,
                            executionId: res.data.execution_id,
                            liveDeployStatus: true
                        },
                    });
                    
                })
                .catch((error) => {
                    self.error = error.message;
                    this.$store.dispatch("addToast", {
                        message: self.error,
                        type: "error",
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.disableButtons = false;
                    this.deploying = false;
                });
        },
        async getLastDeployId() {
            this.lastDeployId = this.lastDeployDetails[`${this.jobName}-${this.sandboxId}`]?this.lastDeployDetails[`${this.jobName}-${this.sandboxId}`].id:null
            if(!this.lastDeployId) {
                try {
                    let response = await this.fetchSandboxJobRuns({jobName:this.job.name, limit:1});
                    this.lastDeployId = (response.data && response.data.length)?response.data[0].id:null;
                } catch (error) {
                    this.lastDeployId = null
                } 

            }
            return (this.lastDeployId);
            
        }, 
        async getLastStatus() {            
            const lastDeployId = await this.getLastDeployId();
            this.showModal({
                component: "ShowHandlerExecution",
                data: {
                    jobName: this.job.name,
                    sandboxId: this.sandboxId,
                    executionId: lastDeployId,
                },
            });                        
        },
        async getLogErrors(val){
            let jobName =`${this.job.name}-${this.sandboxId}`
            //getting stderror log
            let errLog = val[jobName]? val[jobName].split("---- STDERR ----"):[]
            if(errLog.length>=2){
                errLog= errLog[1];
                let errorList=[];
                Object.keys(this.yamlContent).forEach((file)=>{
                    //getting error log based on the file name and split the message and file name
                    let error =errLog.split("./"+file+":")
                    if(error.length>=2){
                        for(let i=1; i<error.length;i++){
                            let details =error[i].split(":");
                            //to ignore lines below build failed
                            let msg = details[2].split("build failed")
                            errorList.push({
                                line: details[0],
                                char: details[1],
                                message:msg.length>=1?msg[0]:""
                            });
                        }
                    }

                })
                this.setLintErrors(errorList)
            }
        },
        onEditClick() {
            this.setEditMode(true);
        },
        setEditMode(mode) {
            this.editMode = mode;
        },
    },
    beforeMount() {
        this.initateContent();
        this.getLogErrors(this.handlerLogs);
    },
    mounted() {
        this.getLastDeployId();
    }
};
</script>

<style scoped>
.btn:disabled {
    cursor: not-allowed
}
</style>
