<template>
    <detail-header
        pretitle="Users"
        :title="user ? user.name : '&nbsp;'"
        :editable="true"
        :editMode="editMode"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push('/admin/users')"
    />

    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
        </div>
        <div v-if="user">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <form-fields
                                :fields="fields"
                                v-model="user"
                                :editMode="editMode"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-3" v-if="$store.state.currentUser.roles.includes('aqfer_admin')">
                    <div class="d-grid">
                        <button class="btn btn-primary" @click="showModal({ component: 'GenerateToken', data: {...user} })">Generate Token</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import Fields from "../../utils/field-constants/userFields";
import FormFields from "../../components/FormFields.vue";
import { mapActions } from "vuex";
export default {
    name: "User",
    components: { DetailHeader, FormFields },
    data() {
        return {
            editMode: false,
            user: null,
            snapshot: null,
            error: "",
            successMessage: "",
        };
    },
    computed: {
        fields () {
            let fields = Fields;
            
            // custom update for role edit, if client_admin has the same scope or better it can update client_user to client_admin
            if(this.userRoleRank == 3) {
                if (this.user.cids.every(id => this.$store.state.currentUser.cids.includes(id))) {
                    let roleField = fields.find(item => item.name === 'roles')
                    roleField.readOnlyIfRoleOver = 3
                    roleField.options = ["client_user","client_admin"]
                }
            }

            return fields
        },
        userRoleRank() {
            return this.$store.getters.userRoles.userRoleRank;
        }    
    },
    beforeMount() {
        this.fetchUser();
    },
    methods: {
        ...mapActions({
            readUser: "admin/readUser",
            updateUser: "admin/updateUser",
            deleteUser: "admin/deleteUser",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchUser() {
            let response={};
            if(!this.$route.params.data){
                try {
                    response = await this.readUser(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.user = await response.data;
            this.snapshot = { ...this.user };
        },
        onCancelClick() {
            this.setEditMode(false);
            this.user = { ...this.snapshot };
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete User",
                    message: `Are you sure you want to delete ${this.user.email}?`,
                    async confirm() {
                        try {
                            const response = await self.deleteUser(self.user);
                            self.$router.push("/admin/users");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        async onSaveClick() {
            var self = this
            try {
                const response = await this.updateUser(this.user);
                this.setEditMode(false);
                this.snapshot = { ...this.user };
                this.successMessage = "User updated successfully.";
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
        },
    },
};
</script>

<style>
</style>