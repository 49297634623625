<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete Domain Records</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the following domain records?</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData.rows" :key="item.index" class="list-group-item">
                        {{item.name}}
                    </li>
                </ul>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Delete</div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            deleteInProgress: false
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteDomainRecords",
    methods: {
        ...mapActions("aut", {
            updateEdgeHostname: "updateEdgeHostname",
        }),
        async onFormSubmit() { 
            this.deleteInProgress = true;
            let toastOptions;
            try {
                await this.updateEdgeHostname(this.modalData.edgeHostObj);
                toastOptions = {
                    message: "Domain record deleted  successfully",
                    type: "success",
                };                
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message

                toastOptions = {
                    message: this.error || "Something went wrong when deleting domain record. Please contact support.",
                    type: "error",
                };
                this.$store.dispatch("addToast", toastOptions);
                
                return
            } finally {
                this.deleteInProgress = false;
            }
           
            this.$store.dispatch("addToast", toastOptions);            
            this.$store.dispatch("aut/getDomainListObject", this.modalData.edgeHostObj.edgeHostname);
            this.$store.dispatch("hideModal");
        },
    },
};
</script>

<style>
</style>