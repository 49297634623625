<template>
    <BasicHeader pretitle="Sandbox Credential" :title="credential ? credential.name : ''" buttonText="Detach"
        buttonClass="btn-danger" @btnClick="onDetachClick" buttonText2="Cancel" @btnClick2="onCancelClick" />
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="credential">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <FormFields :fields="fields" v-model="credValue" :inlineField="true" :editMode="false" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="text-center">
            <div class="spinner-border">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import BasicHeader from '../../components/BasicHeader.vue';
import FormFields from '../../components/FormFields.vue';
import { mapActions } from 'vuex';
import { DateTimeFormat } from '../../utils/commonFunction';
export default {
    name: "SandoxCredential",
    components: { BasicHeader, FormFields },
    data() {
        return {
            credValue: {},
            credential: {},
            error: null,
            loading: false,
            sandboxCredential: {},
            fields: []
        }
    },
    beforeMount() {
        this.fetchCredential();
        this.fetchSandboxCredential();
    },
    watch: {
        credential() {
            //Setting default fields and value
            let fields = [{
                name: "sandboxName",
                type: "text",
                label: "Sandbox Name"
            },
            {
                name: "credentialName",
                type: "text",
                label: "Credential Name"
            },
            {
                name: "credentialType",
                type: "text",
                label: "Credential Type"
            }
            ];
            let credValue = {
                sandboxName: this.$route.params.sandboxId,
                credentialType: this.credential.type,
                credentialName: this.credential.name
            }
            if (this.credential.fields) {
                this.credential.fields.map((field) => {
                    if (field.type == "url")
                        credValue[field.name] = field.value !== '' ? "***********" : '';
                    else credValue[field.name] = field.value;
                    fields.push({
                        name: field.name,
                        type: "text",
                        label: field.name,
                        placeholder: field.description
                    })
                })
            }
            //these values will be only if navigate from list
            if (this.sandboxCredential.attached_by) {
                fields.push({
                    name: "attached_by",
                    type: "text",
                    label: "Attached By"
                },
                    {
                        name: "attached_at",
                        type: "text",
                        label: "Attached at"
                    })
                credValue["attached_by"] = this.sandboxCredential.attached_by;
                credValue["attached_at"] = DateTimeFormat(this.sandboxCredential.attached_at);
            }
            this.credValue = credValue
            this.fields = fields
        } 
    },
    methods: {
        ...mapActions({
            readCredential: "amdp/readCredential",
            upsertSandboxCredential: "amdp/upsertSandboxCredential",
            showModal: "showModal"
        }),
        async fetchCredential() {
            let response = {}
            this.loading = true
            try {
                response = await this.readCredential(this.$route.params.id);
            } catch (error) {
                if (error.response.data)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }
            this.loading = false
            this.credential = await response.data;

        },
        async fetchSandboxCredential() {
            if (this.$route.params.data)
                this.sandboxCredential = JSON.parse(this.$route.params.data);
        },
        onDetachClick() {
            const self = this;
            let toastoptions;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Detach Credential",
                    message: `Are you sure you want to detach the credential ${this.credValue.credentialName} from ${this.credValue.sandboxName} ?`,
                    async confirm() {
                        try {
                            await self.upsertSandboxCredential({ sandboxName: self.credValue.sandboxName, payload: { "remove_credentials": [self.credValue.credentialName] } });
                            toastoptions = {
                                message: "Sandbox Credentials detached successfully.",
                                type: "success"
                            }
                        } catch (error) {
                            this.error = error.message;
                            toastoptions = {
                                message: "Something went wrong when detaching sandbox credential. Please contact support."
                            }
                        }finally{
                            self.$store.dispatch("hideModal");
                            self.$store.dispatch("sandbox/setCurrentSandbox", self.credValue.sandboxName);
                            self.$store.dispatch('addToast', toastoptions);
                            self.onCancelClick()
                        }
                    }

                }
            })
        },
        onCancelClick() {
            this.$router.push({ name: "Sandbox Credentials" });
        }
    }


}
</script>
<style></style>