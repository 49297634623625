<template>
	<basic-header pretitle="Aqfer graph managment" title="IDR Jobs" />
	<div
		class="container-fluid job-list-view"
		v-if="!loading && sandBoxListOptions.length"
	>
		<input-field
			label="IDR Sandbox"
			type="select"
			:options="sandBoxListOptions"
			v-model="selectedSandbox"
			:editable="true"
		/>
		<div class="row mb-1">
			<div class="col-md-8">
				<p></p>
				<p>Jobs List</p>
			</div>
			<div class="col-md-4">
				<div class="text-end">
					<button
						@click="
							showModal({
								component: 'AddIDRJob',
								data: { kind: 'TRANSFORM' },
							})
						"
						class="btn lift btn-primary"
					>
						Create Job
					</button>
				</div>
			</div>
		</div>
		<div class="card">
			<datatable
				tableId="idrListTable"
				:columns="columns"
				:orderColumn="orderColumn"
				uniqueField="name"
				:formatColumn="formatColumn"
				:data="jobList"
				:deletable="true"
				:showLoading="jobList === null"
				@deleteRows="showDeleteSandboxes"
				:compact="true"
				:selectCheckboxes="true"
				:detailColumn="{
					renderBaseUrl: (data) => {
						return `jobs/${data.name}/configuration`;
					},
				}"
			/>
		</div>
	</div>
	<div
		v-else-if="!loading && !sandBoxListOptions.length"
		class="container-fluid"
	>
		<div class="alert alert-secondary" role="alert">
			No Sandboxes available!
		</div>
	</div>
	<div v-else class="text-center">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</template>

<script>
	import BasicHeader from "../../components/BasicHeader.vue";
	import InputField from "../../components/InputField.vue";
	import Datatable from "../../components/Datatable.vue";
	import { secondsTohms } from "../../utils/commonFunction";
	import { mapActions, mapState } from "vuex";

	export default {
		components: { BasicHeader, InputField, Datatable },
		data() {
			return {
				orderColumn: [3, "desc"],
				columns: [
					{
						data: "name",
						title: "Job Name",
					},					
					{
						data: "last_run_started_at",
						title: "Last Run At",
					},
					{
						data: "last_run_time",
						title: "Last Run Time",
						render: function (data, type, row) {
							return secondsTohms(data);
						},
					},
					{
						data: "last_run_status",
						title: "Last Run Status",
					},
					{
						data: "created_by",
						title: "Created by",
					},
				],
				formatColumn: [
					{
						index: 3,
						type: "datetime",
					},
				],
				loading: true,
			};
		},
		computed: {
			selectedSandbox: {
				get() {
					return this.$store.state.sandbox.currentSandbox;
				},
				set(value) {
					if(value) {
						this.$store.dispatch('sandbox/setCurrentSandbox',value);   
						this.$router.push({
							name: "IDR",
							params: {
								sandboxId: `${this.selectedSandbox}`,
							},
						});   
					}
											
				}
			},
			sandBoxListOptions() {
				const filteredList = this.$store.state.sandbox.devSandboxes? this.$store.state.sandbox.devSandboxes.filter((sandbox) => {
						return (sandbox.name).includes("edge-extract");
					}):[];
				return filteredList? filteredList.map((sandbox) => {
						return { label: sandbox.name, value: sandbox.name };
					}):[];
			},
			jobList() {
				let jobList = [];
				this.$store.state.sandbox.sandboxJobs.map((job) => {
					let status = job.last_run_status
						? job.last_run_status.toLowerCase()
						: "";
					let statusStr = job.last_run_status;
					if (status === "succeeded" || status === "completed") {
						statusStr =
							"<span class='text-success'>●</span> " +
							job.last_run_status;
					} else if (status === "failed") {
						statusStr =
							"<span class='text-danger'>●</span> " +
							job.last_run_status;
					} else if (status === "running") {
						statusStr =
							"<span class='text-primary'>●</span> " +
							job.last_run_status;
					} else if (status === "submitted") {
						statusStr =
							"<span class='text-warning'>●</span> " +
							job.last_run_status;
					} else if (status === "cancelled") {
						statusStr =
							"<span class='text-secondary'>●</span> " +
							job.last_run_status;
					}
					job["last_run_status"] = statusStr;
					jobList.push(job);
				});
				return jobList;
			},
		},
		async beforeMount() {
			const sandboxIdFromRoute = this.$route.params.sandboxId;
			if(!sandboxIdFromRoute || ((sandboxIdFromRoute) && !((sandboxIdFromRoute).includes("edge-extract")))) {
				const filteredList = this.$store.state.sandbox.devSandboxes? this.$store.state.sandbox.devSandboxes.filter((sandbox) => {
						return (sandbox.name).toLowerCase().includes("edge-extract");
						}):[];
				const initialIdrSandbox = filteredList.length?filteredList[0].name:null;
				this.$store.dispatch(
					"sandbox/setCurrentSandbox",
					initialIdrSandbox
				);
				this.$router.push({
					name: "IDR",
					params: {
						sandboxId: `${initialIdrSandbox}`,
					},
				});
			}
			
			await this.fetchSandboxJobs();
			await this.fetchJobKinds();
			this.loading = false;
		},

		methods: {
			...mapActions({
				fetchSandboxJobs: "sandbox/fetchSandboxJobs",
				fetchJobKinds: "amdp/fetchJobKinds",
				showModal: "showModal",
			}),
			showDeleteSandboxes(rows) {
				this.showModal({ component: "deleteSandboxJobs", data: rows });
			},
			setCurrentSandbox(sandbox) {
				this.$store.dispatch("sandbox/setCurrentSandbox", sandbox);
			},
		},
	};
</script>

<style scoped>
	.job-list-view::v-deep select {
		text-transform: capitalize !important;
	}
</style>
