<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Clone Sandbox Job</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <div class="mb-3">
                        <h3 for="exampleFormControlInput1" class="form-label">What would you like to name this sandbox job?
                        </h3>
                        <input placeholder="placeholder" class="form-control" v-model="sandboxJobName">
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button>&nbsp;
            <button class="btn btn-primary" id="myButton" @click="cloneSandboxJob()">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    Clone
                </div>
            </button>&nbsp;
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isDuplicate } from '../../utils/commonFunction';
export default {
    data() {
        return {
            sandboxJob: this.modalData,
            sandboxJobName: `${this.modalData.name}-`,
            loading: false
        }
    },
    props: {
        modalData: {
            type: Object
        },
    },
    methods: {
        ...mapActions('sandbox', {
            createSandboxJob: 'createSandboxJob',
            fetchSandboxJobs: 'fetchSandboxJobs'
        }),
        async cloneSandboxJob() {
            let valid = false;
            this.loading = true;
            let toastoptions;
            this.sandboxJob.name = this.sandboxJobName;
            //For cloning these datas are not needed
            delete this.sandboxJob.updated_at
            delete this.sandboxJob.updated_by
            delete this.sandboxJob.type
            delete this.sandboxJob.created_at
            delete this.sandboxJob.created_by
            let response = null;
            try {
                var re = new RegExp("^[a-z0-9-]*$");
                if (re.test(this.sandboxJobName)) {
                    //Note: If Reload happen in view sandbox job then list of sandbox jobs wont available
                    if (!this.$store.state.sandbox.sandboxJobs.length) {
                        await this.fetchSandboxJobs().then(() => {
                            valid = !isDuplicate(this.sandboxJobName, this.$store.state.sandbox.sandboxJobs, "name", null)
                        })
                    } else {
                        valid = !isDuplicate(this.sandboxJobName, this.$store.state.sandbox.sandboxJobs, "name", null)
                    }

                    if (valid) {
                        try {
                            response = await this.createSandboxJob(this.sandboxJob)
                            toastoptions = {
                                message: "Sandbox Job cloned successfully.",
                                type: "success"
                            }
                        } catch (error) {
                            toastoptions = {
                                message: error.response.data.error,
                                type: "error"
                            }
                            this.loading = false;
                            this.$store.dispatch('addToast', toastoptions);
                            return
                        }
                    } else {
                        toastoptions = {
                            message: "Already '" + this.sandboxJobName + "' name exits.'",
                            type: "error",
                        };
                    }
                } else {
                    toastoptions = {
                        message: "Job name only supports lowercase letters, numbers, and dashes.",
                        type: "error"
                    }
                    this.loading = false;
                    this.$store.dispatch('addToast', toastoptions);
                    return
                }
            } catch (error) {
                toastoptions = {
                    message: "Something went wrong when cloning the Sandbox Job. Please contact support.",
                    type: "error"
                }
                this.loading = false;
                this.$store.dispatch('addToast', toastoptions);
                return
            }
           // After creating sandbox job reposne data is null else error is returned
            if (valid && response && !response.data) {
                this.$store.dispatch('hideModal')
                this.$router.push({
                    name: "Sandbox Job Configuration",
                    params: {
                        sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
                        jobId: `${this.sandboxJobName}`
                    },
                });
                this.$store.dispatch('sandbox/fetchSandboxes')
            } else if (response && response.data) {
                toastoptions = {
                    message: response.data.error,
                    type: "error"
                }
            }
            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
        }
    }

}
</script>

<style></style>