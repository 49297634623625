<template>
    <ul v-for="blog in blogList"  :key="blog.id" class="list-group list-group-lg list-group-flush list my-n4">
        <li class="list-group-item" v-if="blog.yoast_head_json">
            <div class="row align-items-center">
                <div class="col-auto">
                    <a :href="blog?.yoast_head_json?.og_url"
                        target="_blank" class="avatar avatar-xl">
                        <img :src="blog?.yoast_head_json?.og_image[0].url"
                             class="avatar-img rounded">
                     </a>
                </div>
                <div class="col ms-n2">
                    <h4 class="mb-1 name">
                    <a :href="blog?.yoast_head_json?.og_url"
                        target="_blank">{{ blog?.yoast_head_json?.title }}</a>
                    </h4>
                    <p class="card-text small text-muted mb-1">{{formatDate(blog.date)}}</p>
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
export default{
    name:"BlogList",
    props: {
        blogList:{
            type: Array,
            default: function(){
                return []
            }
        }
    },
    methods:{
        formatDate(date){
            date = new Date(date)
            let formattedMonth = date.toLocaleString('default', { month: 'short' })
            return formattedMonth+" "+ date.getDate()+", "+date.getFullYear()
        }
    }
}
</script>
<style>

</style>