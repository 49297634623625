<template>
	<div>
		<input
			class="form-control"
			:value="cronVal"
			@input.stop.prevent="update($event.target.value)"
			type="text"
			:disabled="!editable"
			:readonly="!editable"
		/>
		<div v-if="error" class="text-danger">{{ error }}</div>
		<div v-else-if="cronDescription" class="mt-2 small text-primary">
			<strong>"{{ cronDescription }}"</strong>
		</div>
	</div>
</template>

<script>
	import cron from "cron-validate";
	import cronstrue from "cronstrue";
	export default {
		props: {
			modelValue: {
				type: String,
				default: "",
			},
			editable: {
				type: Boolean,
				default: true,
			},
			visible: {
				type: Boolean,
				default: true,
			},
		},

		data() {
			return {
				cronVal: this.modelValue,
				value1: "",
				error: "",
				cronDescription: null,
			};
		},

		watch: {
			modelValue(val) {
				this.cronVal = val;
			},
		},

		methods: {
			update(val) {
				this.validateCron(val);
				this.cronVal = val;
				this.$emit("update:modelValue", val);
			},
			validateCron(val) {
				if (val !== "" && val !== "DISABLED") {
					const cronResult = cron(val, { preset: "aws-cloud-watch" });
					if (cronResult.isValid()) {
						this.error = null;
						this.cronDescription = cronstrue.toString(val);
					} else {
						this.error = cronResult.getError();
					}
				} else {
					this.error = null;
					this.cronDescription = null;
				}
			},
		},
		beforeMount() {
			this.validateCron(this.cronVal);
		},
		emits: ["update:modelValue", "change"],
	};
</script>
<style scoped></style>
