<template>
    <div class="modal-card card">
        <form @submit.prevent="onPromoteJob()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Promote Job
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="promotion" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="promotion" :loading="loading"/>
            </div>
        </div>
        </form>
    </div>
  
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import axios from "axios";
import { DateTimeFormat } from "../../utils/commonFunction";
import Fields from "../../utils/field-constants/jobPromotionCreateFields"
export default {
    components: { formFields, submitButton },
    data(){        
        return {
            error: "",
            promotion: this.modalData.promoteObject,            
            fields: Fields,
            loading: false
        }
    },
    name: "Promote Job",  
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        sandboxListOptions() {            
            return this.$store.state.sandbox.prodV2Sandboxes? this.$store.state.sandbox.prodV2Sandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
        },
        jobListOptions() {
            return this.$store.state.sandbox.successfulJobRunList? this.$store.state.sandbox.successfulJobRunList.map((job) => {
                return {"label": `${job.id}  - ${DateTimeFormat(job.completed_at)}`, "value": job.id };                
            }) : [];            
        }
    },
    methods: {       
        async onPromoteJob() {
            let self = this;
            this.loading = true;
            // Codemirror adding empty string on inital editor load. Remove the vars key its value is ''.
            if(this.promotion.vars == '') {
                delete this.promotion.vars
            }            
            axios.post(`${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/promotions`, this.promotion, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) =>{
                self.$store.dispatch('hideModal');
                self.$router.push({
					name: "Promotion Overview",
					params: {
						sandboxId: response.data.dst_sandbox_name,
                        promotionId: response.data.id
					}
				});
                
            }).catch((error)  => {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    beforeMount() {
        let self = this;
        this.fields = this.fields.map((field) => {
            if (field.name == "dst_sandbox_name") {
                field.options = self.sandboxListOptions;
            } else if(field.name == "src_job_run_id")  {
                // Only assigning recent 15 job run ids
                field.options = self.jobListOptions.slice(0, 15);
            }
            return field;
        });
    }
    
}
</script>

<style>

</style>