<template>
    <BasicHeader 
        pretitle= "aqfer Universal Tag"
        title= "Initiators"
        buttonText= "Add Initiator"
        :buttonRoles= "btnRoles"
        @btnClick= "showModal({ component: 'AddInitiator'})"
    />
    <div class="container-fluid">
        <div class="card">
            <Datatable
                tableId="inititorsList"
                :columns="columns"
                :data="$store.state.aut.initiators"
                @deleteRows="showDeleteInitiators"
                :deletable="deletable"
                :compact ="true"
                :selectCheckboxes="true"
                :detailColumn= "{baseUrl:'initiators', name: 'Initiator', identifier: 'id'}"
            />
        </div>
    </div>
</template>
<script>
import BasicHeader from '../../components/BasicHeader.vue';
import Datatable from '../../components/Datatable.vue';
import { mapActions } from 'vuex';
export default{
    name:"Initiators",
    components: { BasicHeader, Datatable },
    data(){
        return {
            columns: [
                { data: 'name' },
                { data: 'value' },
                { data: 'url' },
                { data: 'description'}
            ],
            btnRoles: [1],
            allowedRoles: [1]
        }
    },
    beforeMount(){
        this.$store.dispatch("aut/fetchInitiators");
    },
    methods: {
        showDeleteInitiators(rows){
            this.showModal({ component: "DeleteInitiators", data: rows });
        },
        ...mapActions({
            showModal :"showModal",
        })
    },
    computed: {
        deletable(){
            return this.allowedRoles.includes(this.$store.getters.userRoles.userRoleRank)
        }
    }
}
</script>
<style>
</style>