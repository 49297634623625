const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Client name is required" }
        ]
    },
    {
        name: "cid",
        label: "Client ID",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "label",
        label: "Label",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "cookieName",
        label: "Cookie Name",
        type: "text",
        tooltip:"Name of the cookie used by the client for identifying their unique users",
        editable: true,
        validation: [
            { type: "required" }
        ],
    }, {
        name: "cookieFormat",
        label: "Cookie Format",
        type: "radio",
        tooltip:"Format to generate the client cookie 1. uuidv4: hexadecimal 32 bytes long 2. aqfer24: hexadecimal 24 bytes long 3. aqfer: base64 encoded 24 bytes long 4. apache_moduid2: base64 encoded 21 bytes long 5. compact: base64 encoded 17 bytes long",
        editable: true,
        visible: true,
        options: [
            { label: "aqfer", value: "aqfer" },
            { label: "aqfer24", value: "aqfer24" },
            { label: "apache_moduid2", value: "apache_moduid2" },
            { label: "compact", value: "compact" },
            { label: "uuidv4", value: "uuidv4" }
        ]
    }, {
        name: "cookieDomain",
        label: "Cookie Domain",
        type: "text",
        tooltip:"Domain on which the cookie will be set",
        editable: true,
        validation:[
            {
                type:'regex',
                pattern:/^\.?(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]+$/,
                errMsg:"Allowed Characters: 'a-z0-9-'. Domain labels can only start and end with an alphanumeric character. The top level domain only accepts: a-z"
            }
        ]
    }, {
        name: "akamaiProperty",
        label: "Akamai Property",
        tooltip:"Name of the property where the cid is deployed",
        type: "text",
        editable: true,
    }, {
        name: "httpOnlyCookie",
        label: "HTTP-Only Cookie",
        type: "checkbox",
        tooltip:"Add the HttpOnly directive when setting cookie when enabled",
        // options: [
        //     { label: "Enabled", value: true },
        // ],
        editable: true,
    }, {
        name: "pubDenyIds",
        label: "Deny Publisher IDs",
        type: "multiselect",
        tooltip:"List of publisher ids for which the tag will not be loaded",
        editable: true,
    }, {
        name: "pubDenyDomains",
        label: "Deny Publisher Domains",
        type: "multiselect",
        tooltip:"List of publisher domains for which the tag will not be loaded",
        editable: true,
    }, {
        name: "notificationEmails",
        label: "Notification Email(s)",
        type: "multiselect",
        tooltip:"List of email ids to which deployment information will be notified",
        editable: true,
    }, {
        name: "dailyCountEmails",
        label: "Daily Count Email(s)",
        type: "multiselect",
        tooltip:"List of email ids to which daily counts emails will be sent",
        editable: true,
    }, {
        name: "dailyCountLimit",
        label: "Daily Count Limit",
        type: "number",
        tooltip:"When sending daily count emails, any entry with counts below this limit is omitted",
        editable: true,
    },
];

export default Fields;
