<template>
    <BasicHeader pretitle="aqfer Marketing Data Platform" title="Sandbox Credentials" buttonText="Attach Credential"
        @btnClick="showAddSandboxCredential" buttonText2="Refresh" buttonClass2="btn-primary" @btnClick2="onRefreshClick" :buttonRoles= "allowedUserRoles"/>
    <div  v-if="loading && !sandboxOptions.length" class="text-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="container-fluid" v-else-if="sandboxOptions.length>0">
        <div class="row">
            <div class="col-md-6 col-lg-4 mb-2">
                <InputField label="Choose a Sandbox" type="select" :options="sandboxOptions" v-model="selectedSandbox" />
            </div>
        </div>
        <div class="card">
            <div v-if="error" class="text-danger">
					{{ error }}
			</div>
            <Datatable tableId="sandboxCredentials" :columns="columns" uniqueField="client_credential_name" :data="credentialList"
                @deleteRows="showDetachSandboxCredential" 
                :deletable = "isCredentialDelete"
                deleteTxt ="Detach"
                :selectCheckboxes="isCredentialDelete"
                :formatColumn="formatColumn"
                :compact="true"
                :detailColumn="{ baseUrl: 'credentials/', name: 'Sandbox Credential', identifier: 'client_credential_name' }"
                :showLoading="credentialList==null" />
        </div>
    </div>
    <div v-else class="container-fluid" >
        <div class="alert alert-secondary" role="alert">
                No Sandbox available!
        </div>        
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import InputField from "../../components/InputField.vue";
import { deepEqual } from "../../utils/commonFunction";
import { mapActions } from "vuex";
export default {
    components: { Datatable, BasicHeader, InputField },
    name: "SandboxCredentials",
    data() {
        return {
            columns: [
                {
                    data: "client_credential_name",
                    title: "Client Credential Name",
                },
                {
                    data: "sandbox_credential_name",
                    title: "Credential Name",
                },
                {
                    data: "type",
                    title: "Credential Type",
                },                
                {
                    data: "attached_by",
                    title: "Attached By",
                },
                {
                    data: "attached_at",
                    title: "Attached At",
                }
            ],
            loading: true,
            formatColumn: [
                {
                    index: 6,
                    type: "datetime"
                }
            ],
            error:null,
            showRefreshBtn: false,
            allowedUserRoles:[1] //only aqfer admin can delete or add credential to sandbox
        };
    },
    computed: {
        selectedSandbox: {
            get() {
                return this.$store.state.sandbox.currentSandbox;
            },
            set(value) {
                if(value) {                   
                    this.$store.dispatch('sandbox/setCurrentSandbox',value); 
                    this.$router.push({
                            name: "Sandbox Credentials",
                            params: {
                                sandboxId: `${value}`
                            },
                        });

                }
                       
            }

        },
        sandboxOptions() {
            let devSandboxes =this.$store.state.sandbox.devSandboxes ? this.$store.state.sandbox.devSandboxes : [];
            let prodV2Sandboxes =this.$store.state.sandbox.prodV2Sandboxes ? this.$store.state.sandbox.prodV2Sandboxes : [];
            let sandboxes = [...devSandboxes, ...prodV2Sandboxes]
            return sandboxes.map((sandbox) => {
                return { "label": sandbox.name+" - "+sandbox.env, "value": sandbox.name };
            });
        },
        credentialList(){
            let creds = this.$store.state.amdp.credentials;
            let self = this;
            const list = this.$store.state.amdp.sandboxCredentials || [];
            const updatedList = list.map((sandboxCred) => {
                const name = sandboxCred.client_credential_name;
                const matchCred = creds.find(cred => cred.name == name);
                if(matchCred){                    
                    sandboxCred["status"] = `<span class='text-success'>●</span>up-to-date`;                    
                } else {
                    this.showRefreshBtn = true;
                    sandboxCred["status"] = `<span class='text-danger'>●</span>stale`;   
                }   
                
                return sandboxCred;
                                
            })
            return updatedList;
        },
        sandboxCredList(){
            return this.credentialList?this.credentialList.map((cred)=> cred.client_credential_name):[]
        }, 
        sandboxCredListAlias(){
            let alias={}
             if(this.credentialList){
                this.credentialList.map((cred)=>  { alias[cred.client_credential_name]=cred.sandbox_credential_name})
             }
             return alias;
        },       
        isCredentialDelete() {
            return this.isAccessByUserRole(this.allowedUserRoles);
        }
    },    
    methods: {
        ...mapActions({
            showModal: "showModal",
            getSandboxCredentials: "amdp/getSandboxCredentials",
            fetchCredentials: "amdp/fetchCredentials"
        }),
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentCredential", data);
        },
        showDeleteCredentials(rows) {
            this.showModal({ component: "DeleteCredentials", data: rows });
        },        
        showAddSandboxCredential(){
            this.showModal({ component: 'AddSandboxCredentials',
                             data:{ 
                                sandboxOptions:this.sandboxOptions, 
                                selectedSandbox: this.selectedSandbox,
                                sandboxCredList: this.sandboxCredList,
                                sandboxCredListAlias: this.sandboxCredListAlias
                                }
                            })
        },
        showDetachSandboxCredential(rows){
            let credNames = rows.map((val)=>val.client_credential_name)
            this.showModal({ component: 'DetachSandboxCredentials', data: {sandboxName: this.selectedSandbox, rows, credNames:{"remove_credentials":credNames} } })
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        },
        onRefreshClick() {
            this.showModal({ component: 'AddSandboxCredentials',
                data:{ 
                    selectedSandbox: this.selectedSandbox,
                    sandboxCredList: this.sandboxCredList,
                    sandboxCredListAlias: this.sandboxCredListAlias,
                    refresh:true
                }
            })
        }
    },
    async beforeMount() {       
        this.fetchCredentials();
        try {
            await this.getSandboxCredentials(this.selectedSandbox);
        } catch(error) {
            if (error.response.data)
                this.error = error.response.data.error;
            else this.error = error.message;
        } finally {            
            this.loading = false;
        }
    },
};
</script>

<style></style>