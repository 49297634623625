<template>
  <div class="modal-card card">
    <form @submit.prevent="onCreateJobTemplate()">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Create Job Template</h4>
        <!-- Close -->
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="card-body">
        <FormFields :fields="fields" v-model="jobTemplate" />
        <div v-if="jobTemplate.type">
          <InputField
            label="Create default files"
            type="checkbox"
            v-model="addDefaultFile"
            :editable="true"
            :visible="true"
          />
          <span class="text-primary">Files: {{ defaultFile }}</span>
        </div>
        <div v-if="error" class="text-danger">
          {{ error }}
        </div>
      </div>
      <div class="card-footer">
        <div class="text-end">
          <SubmitButton :fields="fields" v-model="jobTemplate" :loading="loading" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import FormFields from "../FormFields.vue";
import SubmitButton from "../SubmitButton.vue";
import { mapState, mapActions } from "vuex";
import Fields from "../../utils/field-constants/jobTemplateFields";
import InputField from "../InputField.vue";
export default {
  components: { FormFields, SubmitButton, InputField },
  data() {
    return {
      error: "",
      jobTemplate: {},
      jobFields: [],
      loading: false,
      addDefaultFile: false,
      defaultFile: "",
      requiredFiles: [],
    };
  },
  name: "AddJobTemplate",
  props: {
    modalData: {
      type: [Object, Array, String],
      default: "",
    },
  },
  computed: {
    ...mapState({
      jobKinds: (state) => state.amdp.jobKinds,
    }),
    fields() {
      let options = [];
      this.jobKinds.map((type) => {
        options.push({
          label: type.name,
          value: type.name,
        });
      });
      return Fields.map((field) => {
        if (field.name == "type") {
          field.options = options;
        }
        return field
      });
    },
  },
  watch: {
    "jobTemplate.type": {
      handler: function (newVal) {
        let files = "";
        this.jobKinds.forEach((type) => {
          if (newVal == type.name) {
            this.requiredFiles = type.required_files;
            files = type.required_files ? type.required_files.toString() : "";
          }
        });
        this.defaultFile = files;
      },
    },
  },
  beforeMount() {
    if (!this.jobKinds.length) {
      this.fetchJobKinds();
    }
  },
  methods: {
    ...mapActions({
      createJobTemplate: "admin/createJobTemplate",
      fetchJobKinds: "amdp/fetchJobKinds",
    }),
    async onCreateJobTemplate() {
      this.loading = true;
      let response = {};
      try {
        let system_files = [];
        if (this.addDefaultFile) {
          this.requiredFiles.map((file) => {
            system_files.push({
              content: "",
              relative_path: file,
            });
          });
        }

        this.jobTemplate.system_files = JSON.stringify(system_files);
        this.jobTemplate.tag = this.jobTemplate.tag
          ? this.jobTemplate.tag.toString()
          : "";
        response = await this.createJobTemplate(this.jobTemplate);
      } catch (error) {
        this.loading = false;
        if (error.response.data.error) this.error = error.response.data.error;
        else this.error = error.message;

        return;
      }
      this.loading = false;
      this.$store.dispatch("hideModal");
      if (response.data) {
        this.$router.push({
          name: "Job Template",
          params: {
            id: `${response.data.id}`,
          },
        });
      }
    },
  },
};
</script>

<style></style>
