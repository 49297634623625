<template>
    <BasicHeader pretitle="aqfer Marketing Data Platform" title="Dashboards" 
        :compact="true"/>
    <div class="container-fluid">
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div v-if="!quickSightDashboards.length && !error">
                <p class="mt-4">
                    No Dashboards detected for client
                    <b>{{ $store.state.currentClient.cid }}</b>
                </p>
            </div>
            <tabs v-else :tabs="quickSightDashboards" />
        </div>  
        <div v-if="error" class="text-danger">
            <p class="mt-4">
                {{error}}
            </p>
        </div>      
    </div>
    
</template>
<script>
import BasicHeader from '../../components/BasicHeader.vue';
import Tabs from "../../components/Tabs.vue";
import { mapState } from "vuex";
import axios from 'axios';
export default {
    components: { BasicHeader, Tabs},
    name: "Jobs Dashboard",
    data() {
        return {
            loading: true,
            quickSightDashboards: [],
            iframeSrc : "",
            error:null

        }
    },
    computed: {
        ...mapState({
            currentClient: (state) => state.currentClient,
            domainList: (state) => state.aut.domainList,
        }),
        jobDashboardsList() {           
            if (this.currentClient.config && this.currentClient.config.features){
                let featureList = this.currentClient.config.features;
                const dataLakeObject = featureList.filter((f) => {
                    return (f.name === "DATA_LAKE" && f.jobs_dashboards);
                })
                if(dataLakeObject.length) {
                    return dataLakeObject[0].jobs_dashboards;
                } else {
                    return [];
                }
                                 
            } else {
                return [];
            }
        }
    },
    methods: {
        async getQuicksightDashboards() {
            this.loading = true;
            this.quickSightDashboards = [];
            this.error=null;
            const features = this.$store.state.currentClient.config.features;           
        
            for (const dashboard of this.jobDashboardsList) {
                const response = await this.getQuicksightUrl(
                    dashboard.id,
                    dashboard.account,
                );
                let dashboardData = await response.data;
                if(dashboardData && dashboardData.EmbedUrl){
                    this.quickSightDashboards.push({
                        name: dashboard.name,
                        iframe:
                            dashboardData.EmbedUrl
                    });
                }
            }
        
            
            this.loading = false;
        },
        async getQuicksightUrl(id, account="") {
            if (!account)
                account = 'aqfer'
            return axios.get(
                `${this.$store.state.pailUrl}/v1/cids/${this.$store.state.currentClient.cid}/quicksight/${id}?account=${account}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            ).then((response)=>{
                return response
            }).catch(error=>{
                this.loading=false
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else if (error.response.data.message) 
                    this.error = error.response.data.message
                else 
                    this.error = error.message
                return error
            });
        },

    },
    mounted() {
        this.getQuicksightDashboards();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setCurrentClient") {
                this.getQuicksightDashboards();
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    },
}
</script>
<style>
</style>