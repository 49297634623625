<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddSandboxUser()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    {{userOperation}} User to Sandbox
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body" style="height:250px">
                <label>Choose a user to allow access to <b>{{ modalData.sandbox }}</b> sandbox in client
                    <b>{{ cid }}</b></label>
                <div class="mb-4">
                    <Multiselect 
                        v-model="user" 
                        :options="userOptions" 
                        :searchable=true 
                        :disabled="!editable" />
                </div>
                <div class="">
                    <InputField :editable="true" label="Access Level" :options="accessOptions"
                                        type="select" v-model="accessLevel" />
                </div>
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
            </div>
            <div class="card-footer" v-if="!message">
                <div class="text-end">
                    <button type="submit" class="btn btn-primary" :disabled="!user">
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>{{ userOperation }} User</div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState } from 'vuex'
import InputField from '../InputField.vue';
export default {
    components: { Multiselect, InputField },
    data() {
        return {
            error: "",
            message: "",
            user: null,
            loading: false,
            accessLevel:"PROMOTER",
            editable:true,
            accessOptions:[
                { label: "PROMOTER ", value: "PROMOTER" }
            ]
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "AddSandboxUser",
    methods: {
        ...mapActions('sandbox', {
            addSandboxUser: "addSandboxUser",
        }),
        async onAddSandboxUser() {
            this.loading = true;
            let toastoptions;
            try {
                const response = await this.addSandboxUser({ "sandboxName": this.modalData.sandbox, "email": this.user, payload:{"access_level":this.accessLevel} });
                let message ="User is added to sandbox successfully."
                if(!this.editable){
                   message= "User data updated successfully in sandbox."
                }
                toastoptions = {
                    message:  message,
                    type: "success"
                }
                this.modalData.fetchUsers(this.modalData.sandbox);
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message

                toastoptions = {
                    message: this.error || "Something went wrong when updating hostname. Please contact support.",
                    type: "error",
                };

            }
            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
            this.$store.dispatch('hideModal')
        }
    },
    computed: {
        ...mapState({
            cid: (state) => state.currentClient.cid
        }),
        userOptions() {
            let options = this.$store.state.admin.users.filter((user) => {
                //User should be part of current cid
                if (user.cids && user.cids.includes(this.cid)) {
                    return user.email;
                } else {
                    return false;
                }
            }).map((filteredUser => filteredUser.email));
            return options
        },
        userOperation(){
            return this.editable? "Add" :"Update";
        }
    },
    mounted(){
        if( this.modalData.data && this.modalData.data.email){
            this.editable= false;
            this.user =this.modalData.data.email;
            this.accessLevel= this.modalData.data.access_level;
        }
    }
};
</script>

<style></style>