<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Code Editor
            </h4>

            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <codemirror-editor              
                :editorId="'code-editor-' + mode"
                :mode="mode"
                :type="mode"
                :readOnly = "readOnly"
                v-model="editorContent" 
                :refreshOn= "refreshOn"          
            /> 
            <div v-if="successMessage" class="text-info">
                    {{successMessage}}
            </div>                      
        </div>
        <div class="card-footer">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-primary me-md-2" @click="saveContent" type="button">Save</button>
                <button class="btn btn-danger" @click="onCancelClick" type="button"  >Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import CodemirrorEditor from "../CodemirrorEditor.vue";
export default {
    name: "CodemirrorModal",
    components: { CodemirrorEditor },
    data() {
        return {
            refreshOn: false,
            editorContent: this.modalValue,
            error: null,
            successMessage: null,
            mode: this.modalData.subYamlType,
            readOnly:this.modalData.readOnly
        }
    },    
    props: {       
        modalData: {
            type: [Object, Array, String],
        },
        modalValue: {
            type: [Object, Array, String]
        }
    },
    mounted() {        
        let self = this;
        setTimeout(()=>{
                self.refreshOn = true;
            },500);
    },
    methods: {
        saveContent() {
            this.modalData.saveMethod(this.editorContent, this.modalData.codeKey);
            this.successMessage = "Editor Content is saved";
            this.$store.dispatch('hideModal')
        },
        onCancelClick() {            
            this.$store.dispatch('hideModal')
        }
    }
};
</script>

<style>
</style>