import axios from 'axios'

const store = {
    namespaced:true,
    state: {
        jobLastRunDetails:{}
    },
    mutations: {        
        setJobLastRunDetails(state, runDetailsObj) {
            state.jobLastRunDetails[runDetailsObj.jobId] = runDetailsObj.runDetails;
        }
    },
    actions: {        
        setJobLastRunDetails({commit}, {jobId, runDetails}){
            commit('setJobLastRunDetails', {jobId, runDetails});

        }        
    }
}

export default store