<template>
    <div>
        <textarea :id="editorId" v-model="inputVal"></textarea>
    </div>
</template>

<script>
import CodeMirror from "codemirror";
import yaml from "js-yaml";
import jsonlint from "jsonlint";
import { JSHINT } from "jshint"; 
window.jsonlint = jsonlint;
window.jsyaml = yaml;
window.JSHINT = JSHINT;

import "codemirror/mode/javascript/javascript";
import "codemirror/mode/go/go";
import "codemirror/mode/sql/sql";
import "codemirror/mode/python/python";
import "codemirror/mode/yaml/yaml";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/yaml-lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/json-lint";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/display/autorefresh.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/search/search.js";
import "codemirror/addon/comment/comment.js";
import eYAML from 'yaml';


import {mapActions} from 'vuex'


export default {
    name: "CodemirrorEditor",    
    computed: {
        codemirrorMode() {
            let mode = this.mode.toLowerCase();
            if(mode == 'py')
                return 'python'
            return mode
        },
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit("update:modelValue", val);
            },
        },
    },
    props: {
        editorId: {
            type: String,
            default: "codeEditor",
        },
        theme: {
            type: String,
            default: "eclipse",
        },
        mode: {
            type: String,
            default: "javascript",
        },
        type: {
            type: String,
            default: "",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        refreshOn: {
            type: Boolean,
            default: false,
        },
        modelValue: [Object, String],
        editorType: {
            type: String
        },
        performLint: {
            type: Boolean,
            default: true
        },
        lineWrapping:{
            type: Boolean,
            default: false
        },
        customLint:{
            type: Boolean,
            default: false
        },
        customLintErrors: null

    },
    watch: {
        inputVal() {
            //if the value changed externally, update it
            if (!this.editor.hasFocus())
                this.updateValue(this.inputVal);
        },
        refreshOn(val) {
            var self = this
            if(val && !this.isRefreshed){
                setTimeout(()=>{                    
                    self.editor.refresh()
                },0);
                this.isRefreshed = true
            }            
        },
        readOnly(val) {
            const readOnlyOption = val ? true : false
            this.editor.setOption("readOnly", readOnlyOption);

            if (val) {
                let $wrapper = this.editor.getWrapperElement();
                $wrapper.classList.add("CodeMirror-readonly");
            } else {
                let $wrapper = this.editor.getWrapperElement();
                $wrapper.classList.remove("CodeMirror-readonly");
            }
        },
        customLintErrors(val) {
           this.editor.performLint();
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        updateValue(newValue) {
            try { 
                if(this.editor &&  (newValue != this.editor.getValue())) {
                    this.editor.getDoc().setValue(newValue || "");
                }
            } catch(e) {
                console.log(e);
            }
            
        },
        handleLintErrors(unsortedErrors, sortedErrors) {
            const isError = (sortedErrors.length)? true : false;
            this.$emit('lintFailure', isError);           
        },

        onEditorChange(editor) {
            let newVal = editor.getValue();           
            this.$emit("update:modelValue", newVal);
        },
        
        customLinter(text) {
            if (!this.customLintErrors)
                return
            var errors = []
            for (var error of this.customLintErrors) {
                errors.push({ 
                    from: CodeMirror.Pos(error.from[0]-1, error.from[1]), 
                    to: CodeMirror.Pos(error.to[0]-1, error.to[1]),
                    message: error.message,
                    severity: error.severity
                });  
            }
            return errors
        },

        customYamlLinter(text) {
            // Regex for match pattern *word
            const ignoreRegex = /\*\w+\b/g;
            const errors = [];
            // Split the text into lines
            const lines = text.split("\n");
            // Loop through the lines
            for (let i = 0; i < lines.length; i++) {
                lines[i] = lines[i].replace(ignoreRegex,'')
            }
            text = lines.join("\n");
           
            try 
            { 
                yaml.loadAll(text); 
            } catch(e) {  
                console.log(e);             
                let loc = e.mark,
                from = loc ? CodeMirror.Pos(loc.line, loc.column) : CodeMirror.Pos(0, 0),
                to = from;
                errors.push({ from: from, to: to, message: e.message });                    
            }
            return errors;
        }

    },
    mounted() {        
        const self = this;          
        this.editor = CodeMirror.fromTextArea(
            document.getElementById(this.editorId),
            {
                lineNumbers: true, 
                theme: this.theme,              
                mode: this.codemirrorMode,
                autoRefresh: true, 
                matchBrackets: true, 
                readOnly: this.readOnly ? true:false,          
                gutters: ["CodeMirror-linenumbers", "CodeMirror-lint-markers"],  
                lint:  this.customLinter ? {
                            getAnnotations: self.customLinter,
                            "onUpdateLinting": self.handleLintErrors
                        } :this.performLint? (this.codemirrorMode=="yaml"? {
                            getAnnotations: self.customYamlLinter,
                            "onUpdateLinting": self.handleLintErrors
                        }: {
                            "onUpdateLinting": self.handleLintErrors
                        }):false, 
                extraKeys: {
                    "Ctrl-F": "findPersistent",
                    "Cmd-/":"toggleComment",
                    "Ctrl-/":"toggleComment"
                },
                lineWrapping: self.lineWrapping,
            }
        ); 
        
        if (this.readOnly) {
            let $wrapper = this.editor.getWrapperElement();
            $wrapper.classList.add("CodeMirror-readonly");
        } 
       
        this.editor.on("change", (editor) => {
            self.onEditorChange(editor);           
        });
        this.updateValue(this.inputVal);
        
        let $wrapper = this.editor.getWrapperElement();
        $wrapper.style.resize= "vertical"
    },
};
</script>

<style>
</style>