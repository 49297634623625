const Fields = [
    {
        name: "sku",
        label: "SKU",
        type: "text",
        editable: true,
        validation: [
            { type: "regex", pattern: "^[a-zA-Z0-9-_]+$",
              errMsg: "Product SKU may contain only alphabets or numbers and underscore" 
            },
            { type: "required", errMsg: "SKU Field is required" }
        ]
    },
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" }
        ]
    },
    {
        name: "duration",
        label: "Duration",
        type: "number",
        editable: true,
        validation: [               
            { type: "minMax", minValue:0, maxValue:1000 }
        ],
    },
    {
        name: "description",
        label: "Description",
        type: "textarea",
        editable: true,
    },
    {
        name: "created_by",
        label: "Created By",
        type: "text",
        editable: false,
    },
    {
        name: "created_at",
        label: "Created At",
        type: "text",
        editable: false,
        format:"datetime"
    },
    {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        editable: false,
    },
    {
        name: "updated_at",
        label: "Updated at",
        type: "text",
        editable: false,
        format:"datetime"
    }
];

export default Fields;