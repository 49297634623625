<template>
    <div class="card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">{{ this.modalData.executionId }}</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div>

                    </div>
                </div>
            </div> <!-- / .row -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header d-inline" v-if="executionObj && statusObj" style="height: auto;">
                            <strong>Status : </strong> &nbsp; <strong :class="statusObj.class">{{ executionObj['status']
                            }}</strong>
                            <div v-if="status && status.toLowerCase() != 'failed' && status.toLowerCase() != 'succeeded' && status.toLowerCase() != 'cancelled'"
                                style="margin-left:10px" class="spinner-border spinner-border-sm text-primary">&nbsp;</div>
                        </div>
                        <div class="card-header d-inline" v-if="status === 'failed'" style="height: auto;">
                            <strong>Logs</strong>
                        </div>
                        <div class="card-body mt-2" v-if="status === 'failed'">
                            <pre class="mb-0" v-if="logs" id="logs"><code>{{ logs }}</code></pre>
                            <pre v-else><code>please wait...</code></pre>
                        </div>
                    </div>
                    <div class="card"
                        v-if="status && status.toLowerCase() == 'succeeded' && outputPaths && outputPaths.length">
                        <div class="card-header">
                            <h4 class="card-header-title">
                                Output Paths
                            </h4>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li v-for="path in outputPaths" :key="path.index">{{ path }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import { relativeTime } from '../../utils/commonFunction';
export default {
    data() {
        return {
            listItems: [
                { data: "status", title: "Status" },

            ],
            outputPaths:null,           
            executionObj: {},
            executionId: this.modalData.executionId,
            jobName: this.modalData.jobName,
            sandboxId: this.modalData.sandboxId,
            status: null,
            logs: "",
            liveDeployStatus: this.modalData.liveDeployStatus ? true : false
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        }
    },
    computed: {
        baseEndpoint() {
            let endpoint = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/`;
            return endpoint + this.sandboxId
        },
        statusObj() {
            let status = (this.executionObj&&this.executionObj.status)?this.executionObj.status.toLowerCase():'';
            if (status == 'succeeded') {
                return { class: 'text-success' };
            } else if (status == 'running') {
                return { class: 'text-primary' };
            } else if (status == 'failed') {
                return { class: 'text-danger' };
            } else if (status == 'submitted') {
                return { class: 'text-warning' };
            } else {
                return { class: '' };
            }
        },
    },
    methods: {
        handleJobChecking() {
            const statusPromise = this.getExecutionStatus();
            statusPromise.finally((respones) => {
                if (this.status && this.status != 'failed' && this.status != 'succeeded' && this.status != 'cancelled') {
                    setTimeout(() => {
                        this.handleJobChecking();
                    }, 2000)
                } else if (this.status == "failed") {
                    this.getExecutionLogs();
                } else if (this.status == 'succeeded' && this.liveDeployStatus) {
                    setTimeout(() => {
                        this.hideModal();
                    }, 1000);

                }
            })
        },
        getExecutionStatus() {
            let self = this;
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}?include_metrics=all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                self.loading = false;
                if (response.data) {
                    self.executionObj = response.data;
                    if(this.modalData.feature && this.modalData.feature=="AGM") {
                        this.$store.dispatch('agm/setJobLastRunDetails', { jobId: `${self.jobName}-${self.sandboxId}`, runDetails: { ...response.data, executionId: self.executionId } });
                    } else {
                        this.$store.dispatch('aio/setHandlerLastDeployDetails', { handlerId: `${self.jobName}-${self.sandboxId}`, deployDetails: { ...response.data, executionId: self.executionId } });
                    }
                    
                    this.status = response.data.status.toLowerCase();
                    
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            });
        },
        async getExecutionLogs() {
            await axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}/logs`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).catch((error) => {
                this.logsError = true
                if (error.response.data.error)
                    //don't show pre-job errors
                    if (error.response.data.error.indexOf("the server rejected our request") != -1)
                        this.logs = "";
                    else
                        this.logs = error.response.data.error
                else
                    this.logs = error.message
            }).then((response) => {
                this.logsError = false;
                if (response && response.data) {
                    if (this.logs != response.data.Logs) {
                        this.logs = response.data.Logs

                        const regex = /Copied to S3: WrappedArray\((.*)\)/gi
                        const matches = [...this.logs.matchAll(regex)]
                        let locations = []
                        for (const match of matches) {
                            if (match[1]) {
                                let paths = match[1].split(',').map((path) => {
                                    return 's3://' + path;
                                })
                                locations = locations.concat(paths)
                            }
                        }
                        this.outputPaths = locations

                        //scroll to bottom of logs
                        setTimeout(() => {
                            var logsDiv = document.getElementById("logs");
                            if (logsDiv)
                                logsDiv.scrollTop = logsDiv.scrollHeight;
                        }, 0)
                    }
                    if (response.data.log_files) {
                        let logfiles = response.data.log_files
                        this.outputLogLink = logfiles["stdout.txt"].url;
                        this.outputLog = (logfiles["stdout.txt"].length / (1024 ** 2)).toFixed(2);
                        this.errorLogLink = logfiles["stderr.txt"].url;
                        this.errorLog = (logfiles["stderr.txt"].length / (1024 ** 2)).toFixed(2);
                    }
                } else {
                    this.logs = "No Log files";
                }

            })
            this.$store.dispatch('aio/setHandlerLog', { handlerId: `${this.jobName}-${this.sandboxId}`, log: this.logs });         
        },
        convertToRelativeTime(timestamp) {
            return relativeTime(Date.now(), dayjs(timestamp));
        },
        formatTime(timestamp) {
            return dayjs(timestamp).format('MMM D, YYYY h:mm:ss A');
        },
        hideModal() {
            this.$store.dispatch('hideModal')
        }

    },
    beforeMount() {
        this.handleJobChecking();
    }
   


}
</script>

<style></style>