function getJobFields() { 
    return [
        {
            key: true,
            name: "name",
            label: "Name",
            type: "text",
            editable: true,           
            validation: [
                { type: "required"},
                {type: "regex", pattern:"^[a-z0-9-]*$",
                errMsg:"Job name only supports lowercase letters, numbers and dashes."}
            ],
            helpText: `<p><a href="https://help.aqfer.net/lakeview-api/job-naming-convention?from_search=129606375" target="_blank">Click here to view Naming Convention rules</a></p>`
        },
        {
            name: "kind",
            label: "Kind",
            visible: true,
            type: "select",
            editable: true,
            options:[
               
            ]   
        },
        {
            name: "system_files",
            label: "System Files",
            visible: false,
            editable: false,
            type: "array"          
        },        
        {
            name: "created_at",
            label: "Created At",
            type: "text",
            editable: false,
            visible: false,
        }, {
            name: "created_by",
            label: "Created By",
            type: "text",
            editable: false,
            visible: false,
        }, {
            name: "updated_at",
            label: "Updated At",
            type: "text",
            editable: false,
            visible: false,
        },{
            name: "user_files",
            label: "User Files",
            visible: false,
            editable: false,
            type: "array"          
        },

    ];
}

export default getJobFields;

