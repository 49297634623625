<template>
    <div class="row">
        <div class="col-md-12">
            <!-- Nav -->
            <ul class="nav nav-tabs nav-overflow" :class="btnTabs?'btn-tabs':''">
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item" :class="tab.hide? 'd-none':''">
                    <a
                        class="nav-link"
                        v-if="showTab(tab.environmentRestrictions)"
                        :class="[activeTab == tab.name ? 'active':'', tab.hide? 'd-none':'']"
                        :id="'tab'+index"
                        data-bs-toggle="tab"
                        :data-bs-target="'#tab' + index + 'Content'"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        @click="tabClicked(tab)"
                        >{{ tab.label || tab.name }}</a
                    >
                </li>
                <li class="nav-item ms-auto p-2" :key="tabs.length">
                    <slot name="tab-action-buttons"></slot>
                </li>
            </ul>
        </div>
        <!-- <div class="col-md-2">
            <slot name="tab-action-buttons"></slot>
        </div> -->
    </div>
    <div class="row">
        <div class="col">
            <div class="tab-content" :class="btnTabs?'btn-tabs':''" id="tabContent">
                <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="tab-pane fade"
                    :class="[activeTab == tab.name ?'active show':'']"
                    :id="'tab' + index + 'Content'"
                    role="tabpanel"
                    :aria-labelledby="tab.id"
                >
                    <div v-if="tab.content">
                        <div v-html="tab.content"></div>
                    </div>
                    <div v-if="tab.iframe">
                        <iframe class="tab-frame" :src="tab.iframe"></iframe>
                    </div>
                    <div v-if="tab.slot">
                        <slot :name="tab.slot"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        tabs: Object,
        btnTabs: {
            type: Boolean,
            default: false,
        }        
    },
    computed:{
        //active tab will be computed from store
        activeTab(){
            let activeTab=this.tabs[0].name
            //If we navigate to another menu that tab may not available so verify that
            let tabFind=this.tabs.find(tab=>tab.name==this.$store.state.activeTab); 
            if(this.$store.state.activeTab && tabFind)
                activeTab=this.$store.state.activeTab
            return activeTab
        }
    },    
    methods: {
        tabClicked(tab){
            this.$store.dispatch('setActiveTab',tab.name)
            if(tab.onClick){
               tab.onClick();
            }
        },
        showTab(value){
            if(value && value.indexOf(this.$store.state.environment) === -1)
                return false
            return true
        }
    }
};
</script>

<style scoped>
.tab-frame{
    width:100%;
    height:70vh;
}

</style>