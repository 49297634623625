<template>
    <basic-header
        pretitle="Administration"
        title="Dashboards"
        :compact="true"
    />
    <div class="container-fluid">
        <dashboard-tabs />
    </div>
</template>

<script>
import axios from "axios";
import BasicHeader from "../../components/BasicHeader.vue";
import DashboardTabs from '../../components/DashboardTabs.vue';
export default {
    components: { BasicHeader, DashboardTabs },
};
</script>

<style>
</style>