import { isDuplicate as Duplicatecheck } from "./commonFunction";
export default function useValidators() {

    const isEmpty = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')?ruleParams.errMsg:`${fieldLabel} is required`;
        if(Array.isArray(fieldValue)) {
            const arrLen = fieldValue.length;
            return (arrLen === 0 || (arrLen === 1 && fieldValue[0]==""))? errMessage : "";            
        }        
        return (fieldValue===null || fieldValue === "" || typeof fieldValue === 'undefined') ? errMessage : "";
    }    

    const isEmail = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')?ruleParams.errMsg:`The input is not a valid ${fieldLabel} address`;
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(Array.isArray(fieldValue)) {
            for (const item of fieldValue) {
                if(!re.test(item))
                    return errMessage;                
            }            
            return "";            
        } else {            
            return !re.test(fieldValue) ? errMessage : "";
        }
    }

    const minLength = (fieldName, fieldLabel, fieldValue, ruleParams) => {       
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `The ${fieldLabel} field must be atleast ${ruleParams.length} characters long`;
        return fieldValue.length < ruleParams.length ? errMessage : "";
    }

    const isRegex = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const regex = new RegExp(ruleParams.pattern); 
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `The ${fieldLabel} field must match regex` ;
        if(Array.isArray(fieldValue)) {
            for (const item of fieldValue) {
                if(!(regex).test(item))
                    return errMessage;                
            }            
            return "";            
        } else {
            return (fieldValue && fieldValue!="")?((!(regex).test(fieldValue))? errMessage : ""):"";
        }
    }
    const isURL = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `The ${fieldLabel} field must be url` ;
        try { 
            return (fieldValue && fieldValue!="")?Boolean (new URL(fieldValue)) && fieldValue.includes(ruleParams.base)? "":errMessage:""; 
        }
        catch(e){ 
            return errMessage; 
        }
    }
    const isDuplicate = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `Already '${fieldValue+"' "+ fieldLabel} exits` ;
        return Duplicatecheck( fieldValue, ruleParams.list, fieldName, ruleParams.referenceName) ? errMessage : ""
    }

    const minMax = (fieldName, fieldLabel, fieldValue, ruleParams) => {
        const errMessage = (ruleParams.errMsg && ruleParams.errMsg!=='')? ruleParams.errMsg : `${fieldLabel} should have min value ${ruleParams.minValue} and max value ${ruleParams.maxValue}` ;
        const max = Math.max(ruleParams.minValue, ruleParams.maxValue);
        const min = Math.min(ruleParams.minValue, ruleParams.maxValue);
        return (fieldValue && fieldValue!="")?(fieldValue >= min && fieldValue <= max)? "" : errMessage:"";
    }
    return { isEmpty, isEmail , minLength, isRegex, isURL, isDuplicate, minMax }
}