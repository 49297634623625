<template>
    <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <div v-if="!dashboards.length">
            <p class="mt-4">
                No Dashboards detected for client
                <b>{{ $store.state.currentClient.cid }}</b>
            </p>
        </div>
        <tabs v-else :tabs="dashboards">
            <template v-slot:error>
                <div class="text-danger">
                    <p class="mt-4">
                        error
                        {{error}}
                    </p>
                </div>  
            </template>
        </tabs>       
    </div>
</template>

<script>
import axios from "axios";
import Tabs from "./Tabs.vue";
export default {
    components: { Tabs },
    data() {
        return {
            loading: true,
            dashboards: [],
            error: null
        };
    },
    props: {
        inFeature: {
            type: String,
            default: ""
        }
    },
    methods: {
        async checkDashboards() {
            this.loading = true;
            this.dashboards = [];
            const features = this.$store.state.currentClient.config.features;
            this.error=null
            for (const feature of features) {
                if ((!this.inFeature || feature["name"] == this.inFeature) && feature["dashboards"]) {
                    for (const dashboard of feature.dashboards) {
                        const response = await this.getQuicksightUrl(
                            dashboard.id,
                            dashboard.account,
                        );
                        let dashboardData = await response;
                        if(dashboardData.data && dashboardData.data.EmbedUrl){
                            let embedUrl = dashboardData.data.EmbedUrl;
                            this.dashboards.push({
                                name: dashboard.name,
                                iframe:
                                    embedUrl +
                                    "#p.Client=" +
                                    this.$store.state.currentClient.cid,
                            });
                        } else {
                            this.dashboards.push({
                                name: dashboard.name,
                                content: `<div v-if="error" class="text-danger"><p class="mt-4">${dashboardData}</p></div>`
                            })
                        }
                    }
                }
            }
            this.loading = false;
        },
        async getQuicksightUrl(id, account="") {
            if (!account)
                account = 'aqfer'
            return axios.get(
                `${this.$store.state.pailUrl}/v1/cids/${this.$store.state.currentClient.cid}/quicksight/${id}?account=${account}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            ).then((response)=>{
                return response
            }).catch(error=>{
                this.loading=false
                if (error.response.data.error) 
                    return error.response.data.error
                else if (error.response.data.message) 
                    return error.response.data.message
                else 
                    return error.message
            });
        },
    },
    mounted() {
        this.checkDashboards();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setCurrentClient") {
                this.checkDashboards();
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    },
};
</script>

<style>
</style>