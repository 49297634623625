<template>
    <basic-header
        pretitle="aqfer Universal Tag"
        title="Browse Public Tag Templates"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="publicTagList"
                :columns="columns"
                :formatColumn="formatColumn"
                :orderColumn="orderColumn"
                :data="$store.state.aut.publicTagTemplates"
                :compact="true"
                :multiselectColumn="multiselectColumn"
                :detailColumn="{baseUrl:'browse-tag-templates/', name:'Public Tag Template', identifier:'id'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapState } from "vuex";

export default {
    name: "PublicTagTemplates",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "name" },
                { data: "event_type", title: "Event Type" },
                { data: "initiator" },
                { data: "tag_type", title: "Tag Type" },
                { data: "tag", title: "Tags" },
                { data: "owner" },
                { data: "modified_at", title: "Updated" },
                { data: "recommended", title: "Recommended" },
            ],
            formatColumn:[
                {
                    index:7,
                    type:"datetime"
                }
            ],
            orderColumn: [8, "desc"],
            multiselectColumn:[ 5 ]
        };
    },
    beforeMount() {
        this.$store.dispatch("aut/fetchPublicTagTemplates");
    }    
};
</script>

<style scoped>
</style>