<template>
    <BasicHeader title="Change Log"/>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <ChangeLogList :logList="logList" />
            </div>
        </div>
    </div>
</template>
<script>
import BasicHeader from "../components/BasicHeader.vue"
import ChangeLogList from "../components/ChangeLogList.vue";
import data from '../../changelog.yaml'
export default {
    name: "ChangeLog",
    components: { BasicHeader, ChangeLogList },
    data() {
        return {
            logList: data.changelog
        }
    },

}
</script>

<style>
</style>