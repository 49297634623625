<template>
    <BasicHeader pretitle="aqfer Marketing Data Platform" title="File Browser" />
    <div class="container-fluid">
        <div v-if="error" class="text-danger mb-2">
                    {{ error }}
        </div>
        <div v-if="message" class="container-fluid" >
            <div class="alert alert-secondary" role="alert">
                {{ message }}
            </div>        
        </div>
        <div class="row" v-if="selectedSandbox || selectedBucket || selectedCredential" :class="dropDownDisable?'dropDownMenu':''">
            <div v-if="sandboxOptions.length>0 || dropDownLoading=='sandbox'" class="col-md-6 col-lg-3 mb-2">
                <label class="form-label">Choose a Sandbox <span class="text-danger">&nbsp;*</span></label>
                
                <select  v-if="selectedSandbox " class="form-select form-control" @change="selectedSandbox=$event.target.value" v-model="selectedSandbox">
                        <option  v-for="option in sandboxOptions" :key="option.index" :value="option.value">
                            {{ option.label }}
                        </option>
                        
                </select>
                <div v-else class="spinner-border spinner-border-sm loader">
					<span class="visually-hidden">Loading...</span>
				</div>
            </div>
            <div  v-if="credentialOptions.length>0 || dropDownLoading=='credential'" class="col-md-6 col-lg-3 mb-2">
                <label class="form-label">Choose a Credential <span class="text-danger">&nbsp;*</span></label>
                <select v-if="selectedCredential" class="form-select form-control" @change="selectedCredential=$event.target.value" v-model="selectedCredential">
                        <option v-for="option in credentialOptions" :key="option.index" :value="option.value">
                            {{ option.label }}
                        </option>
                </select>
                <div v-else 
					class="spinner-border spinner-border-sm loader">
					<span class="visually-hidden">Loading...</span>
				</div>
            </div>
            <div  v-if="bucketOptions.length>0 || dropDownLoading=='bucket'" class="col-md-6 col-lg-3 mb-2">
                <label class="form-label">Choose a bucket <span class="text-danger">&nbsp;*</span></label>
                <select v-if ="selectedBucket" class="form-select form-control" @change="onSelectBucket($event.target.value)" v-model="selectedBucket">
                        <option v-for="option in bucketOptions" :key="option.index" :value="option.value">
                            {{ option.label }}
                        </option>
                </select>
                <div  v-else class="spinner-border spinner-border-sm loader ">
					<span class="visually-hidden">Loading...</span>
				</div>
            </div>
            <div class="col text-end" > 
                <button style="margin-top: 28px" :disabled="loading" type="button" @click="onRefresh()" class="btn lift btn-primary float-right" ><i class="fa fa-refresh"/></button>
                &nbsp;
                <button style="margin-top: 28px" :disabled="uploadDisable" type="button" @click="showModal({ component: 'uploadFile' })" class="btn lift btn-primary float-right" ><i class="fa fa-upload"/>&nbsp;Upload</button>
            </div>
        </div>
        <div v-else-if="dropDownLoading == 'sandbox'" class="row">
            <div class="col-md-12">
                <div class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DetailFolderView v-if="!loading" />
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import DetailFolderView from "./DetailFolderView.vue";
import { mapActions } from "vuex";
export default {
    components: { BasicHeader, DetailFolderView },
    name: "FileBrowser",
    data() {
        return {
            bucketList:[],
            credentialList:[],
            loading: true,
            dropDownLoading: '',
            selectedBucket: null,
            selectedCredential:null,
            selectedSandbox:null,
            error :null,
            unmountRoute: null,
            message: null,
        };
    },
    computed: {
        bucketOptions(){
            if(this.bucketList && this.bucketList.length >0){
                return this.bucketList.map((bucket)=>{
                    return {label: bucket.name, value: bucket.name}
                })
            }
            return []
        },
        sandboxOptions(){
            return this.$store.state.sandbox.devSandboxes? this.$store.state.sandbox.devSandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
            
        },
        credentialOptions(){
            if(this.credentialList && this.credentialList.length >0){
                return this.credentialList.map((credential)=>{
                    return {label: credential.client_credential_name, value: credential.client_credential_name}
                })
            }
            return []
        },
        bucketLoading(){
            return this.selectedBucket || this.selectedCredential ==''
        },
        uploadDisable(){
            //in view file page disable upload option
            return this.$route.matched.some(route => route.path.includes('file/')) || this.selectedCredential =='' || this.selectedBucket ==''
        },
        dropDownDisable(){
            return this.dropDownLoading !=''
        }
    },
    watch:{
        sandboxOptions(newSandboxList) {
            const defaultSandboxRegex = /(c\d\d\d-dev)$/g
            const currentStateSandbox = this.$store.state.sandbox.currentSandbox;
            if(!newSandboxList.length) {
                this.selectedSandbox =  null;
                this.message = "No Sandboxes available!";
            } else if(newSandboxList.filter((e) => { return e.value === currentStateSandbox; }).length > 0) {
               this.selectedSandbox = currentStateSandbox;
            } else {
                const filteredSandboxList = newSandboxList.filter((e) => {
                    return e.value.match(defaultSandboxRegex);                    
                })
                this.selectedSandbox =  (filteredSandboxList.length)?filteredSandboxList[0].value:newSandboxList[0].value;
            }   
            this.$route.params.sandboxId = this.selectedSandbox;         
        },
        async selectedSandbox(newSandboxName){
            this.dropDownLoading="credential"
            this.message=null;
            this.error= null;
            this.selectedCredential='';
            this.selectedBucket=''
            this.$store.dispatch('setBreadcrumbs',[])  
            this.bucketList =[]
            if(newSandboxName && newSandboxName != ""){
                let response = await this.getSandboxCredentials(newSandboxName)
                if(!response.data){
                    this.message=`There is no credential for sandbox ${this.selectedSandbox}`
                    this.dropDownLoading=''
                }
                if(response.data && response.data.length>0){ 
                    this.credentialList= response.data.filter((cred)=>cred.type=="aws")
                }
            }else{
                this.message ="No Sandboxes available!"
            }
            this.loading = false
        },
        credentialList(newValue){
            let credential;
            if(newValue && newValue.length>0){
                this.selectedCredential=newValue[0].client_credential_name
                credential=this.selectedCredential
            }else{
                credential=null  
            }
            this.$store.dispatch('amdp/setSelectedCredential',credential)
        },
        async selectedCredential(newValue){
            this.dropDownLoading="bucket";
            if(newValue && newValue != ""){
                this.message=null;
                this.error= null;
                this.selectedBucket='';
                this.$store.dispatch('setBreadcrumbs',[])  
                this.bucketList =[]
                this.$store.dispatch('amdp/setSelectedCredential',newValue)
                await this.fetchBucketList() 
            }
        }

    },
    mounted() {
        if(this.$route.params.sandboxId) {
            this.$store.dispatch('sandbox/setCurrentSandbox',this.$route.params.sandboxId);  
        } 
        this.fetchSandboxes();
    },
    methods: {
        ...mapActions({
            getBucketList: "amdp/getBucketList",
            getSandboxCredentials: "amdp/getSandboxCredentials",
            showModal:"showModal"
        }),
        async fetchBucketList(refresh=false){
            let response;
            this.loading=true;
            this.error=null;
            if(refresh){
               this.dropDownLoading= 'bucket'
            }
            try {
                let param ={
                    credential:this.$route.params.cid+"."+this.selectedCredential,
                    type:"s3",
                    sandboxName:this.selectedSandbox
                }
                response = await this.getBucketList(param);
            } catch (error) {
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
                this.dropDownLoading=''
                this.$store.dispatch('setBreadcrumbs',[]) 
                this.loading = false;
                return;
            }
            this.bucketList = await response.data? response.data :[];
            if(this.bucketList && this.bucketList.length >0){
                //On reload based on path selecting the bucket
                this.selectedBucket = this.$route.params.bucket?this.$route.params.bucket:this.bucketList[0].name;
                this.onSelectBucket(this.selectedBucket)
            }else{
                this.selectedBucket =""
                this.$store.dispatch('setBreadcrumbs',[])  
                this.message=`There is no bucket for sandbox ${this.selectedSandbox} and credential ${this.selectedCredential}`
            }
            this.dropDownLoading=''
            this.loading = false
        },
        onSelectBucket(bucket){
            let breadcrumbPath=[{
                name:bucket,
                route:{
                    name: "File Browser List",
                    params: {
                                bucket: `${bucket}`,
                                cid:this.$store.state.currentClient.cid,
                                sandboxId:this.selectedSandbox
                            }
                    }
            }]
            //Showing the bucket folders
            this.$store.dispatch('setBreadcrumbs',breadcrumbPath)
            //whenever bucket changes reset folder path
            this.$store.dispatch('amdp/setFolderPath',"");
            //to avoid unmount reloading
            if(this.unmountRoute)
                return
            this.$router.push({
                name:'File Browser List',
                params: {
                     bucket: `${bucket}`,
                     cid:this.$store.state.currentClient.cid,
                     sandboxId:this.selectedSandbox
                    } 
            })
        },
        async fetchSandboxes() {
            this.dropDownLoading = 'sandbox';
            try{
                await this.$store.dispatch("sandbox/fetchSandboxes"); 
            }catch(response){
                this.error=response.data.error
            }finally{
                this.dropDownLoading='';
                this.loading=false
            }
        },
        onRefresh(){
            if(this.$store.state.breadcrumbs.length){
                let path =this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length-1].route
                path.query = { refresh: Date.now()}
                this.$router.push(path);
            }else{
                //Refresh for credential
                this.fetchBucketList(true)
            }
        }
    },
    unmounted(){
        this.unmountRoute= true;
    }
};
</script>

<style scoped>
.loader{
    display: block!important;;
}
.dropDownMenu{
    pointer-events:none;
    opacity: 0.6;
}
</style>