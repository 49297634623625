<template>
	<div v-if="errorTxt">
		<p>{{ errorTxt }}</p>
	</div>
	<div v-else>
		<div v-for="stat in progressStats" :key="stat.stage_name" class="mb-5">
			<h5 class="text-center fw-bold text-uppercase">{{ stat.stage_name }}</h5>
			<div style="overflow:auto">
				<table class="table table-bordered table-responsive mb-0 table-nowrap">
					<thead>
						<tr>

							<th scope="col">Started At</th>
							<th scope="col">State</th>
							<th scope="col">Duration</th>
							<th scope="col">Tasks Progress</th>
							<th scope="col">Total Retries</th>
							<th scope="col" v-if="!stat.is_completed">Estimate completion time</th>
							<th scope="col" v-if="stat.is_completed">Input Records</th>
							<th scope="col" v-if="stat.is_completed">Output Records</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{ DateTimeFormat(stat.start_time) }}</td>
							<td v-if="stat.is_completed"><span class="text-success">●</span> Completed</td>
							<td v-if="!stat.is_completed"><span class="text-primary">●</span> In progress</td>
							<td>{{ secondsToHourMinSec(stat.duration) }}</td>
							<td>
								<span class="px-2 ">Completed: <span class="badge rounded-pill bg-success">{{ stat.completed_tasks }}</span></span> <span class="px-2"> In Progress: <span class="badge rounded-pill bg-primary">{{ stat.in_progress_tasks }}</span></span>  <span >Total: <span class="badge rounded-pill bg-light text-dark">{{ stat.total_tasks }}</span></span>
								<div class="progress mt-2" >
									<div class="
											progress-bar
											progress-bar-striped
											bg-success
										" role="progressbar" aria-label="Animated striped example" :aria-valuenow="stat.total_tasks"
										aria-valuemin="0" :aria-valuemax="stat.total_tasks" :style="`width:${calcPercentage(
											stat.completed_tasks,
											stat.total_tasks
										)}`">
										{{ stat.completed_tasks }} 
									</div>
									<div class="
											progress-bar
											progress-bar-striped
											progress-bar-animated
										" role="progressbar" aria-label="Animated striped example" :aria-valuenow="stat.in_progress_tasks"
										aria-valuemin="0" :aria-valuemax="stat.total_tasks" :style="`width:${calcPercentage(
											stat.in_progress_tasks,
											stat.total_tasks
										)}`">
										{{ stat.in_progress_tasks }}
									</div>
									<div class="
											progress-bar
											bg-light text-dark
										" role="progressbar" aria-label="Animated striped example" :aria-valuenow="stat.in_progress_tasks"
										aria-valuemin="0" :aria-valuemax="stat.total_tasks" :style="`width:${calcPercentage(
											stat.total_tasks-(stat.in_progress_tasks + stat.completed_tasks),
											stat.total_tasks
										)}`">
										{{ stat.total_tasks-(stat.in_progress_tasks + stat.completed_tasks)  }}
									</div>							 
								</div>
							</td>
							<td>{{stat.total_retries}}</td>
							<td v-if="!stat.is_completed">{{ secondsToHourMinSec(stat.eta) }}</td>
							<td v-if="stat.is_completed">{{ stat.input_record_count }}</td>
							<td v-if="stat.is_completed">{{ stat.output_record_count }}</td>
						</tr>
						<tr v-for="(executorStat, index) in stat.executor_groups" :key="index">
							<td colspan="8" class="p-0">
								<table class="table mb-0">
									<thead data-bs-toggle="collapse"
										:data-bs-target="`#accordion-${stat.stage_name}-${index}`" class="collapsed">
										<tr role="button">
											<th scope="col" colspan="10" class="text-center">
												<i class="fa fa-chevron-up float-start"></i>
												Executor Group {{ index }}:- <span style="text-transform: none;">CPUs: </span>{{ executorStat.cpus }}, Memory: {{
													formatBytes(executorStat.memory) }}, Workers: {{ executorStat.workers }},
												Count: {{ executorStat.count }}
											</th>
										</tr>
										<tr :id="`accordion-${stat.stage_name}-${index}`"
											class="accordion-collapse collapse">
											<th scope="col">Executor Name</th>
											<th scope="col">State</th>
											<th scope="col">Created At</th>
											<th scope="col">Started At</th>
											<th scope="col">Ended At</th>
											<th scope="col">Pending Duration</th>
											<th scope="col">Duration</th>
											<th scope="col">Taks completed</th>
											<th scope="col">CPU Utilization</th>
											<th scope="col">Max Memory</th>
										</tr>
									</thead>
									<tbody :id="`accordion-${stat.stage_name}-${index}`"
										class="accordion-collapse collapse">
										<tr v-for="(executor, key, index) in executorStat.executors" :key="index">
											<td>{{ key }}</td>
											<td v-html="addStatusIcon(executor.state)"></td>
											<td>{{ DateTimeFormat(executor.created_time) }}</td>
											<td>{{ DateTimeFormat(executor.start_time) }}</td>
											<td>{{ DateTimeFormat(executor.end_time) }}</td>
											<td>{{ secondsToHourMinSec(executor.pending_duration) }}</td>
											<td>{{ secondsToHourMinSec(executor.duration) }}</td>
											<td>{{ executor.completed_tasks }}</td>
											<td>{{ (executor.cpu_utilization) ? executor.cpu_utilization.toFixed(1)+" %" : "" }}</td>
											<td>{{ executor.max_memory ? formatBytes(executor.max_memory) : "" }}</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { DateTimeFormat, formatBytes, secondsToHourMinSec } from '../../utils/commonFunction';
export default {
	data() {
		return {
			errorTxt: false,
			breakCheck: false
		};
	},
	props: ["sandboxName", "jobName", "executionId"],
	computed: {
		progressStats() {
			if (this.$store.state.sandbox.jobExecutionProgress && this.$store.state.sandbox.jobExecutionProgress.stages) {
				const stages = this.$store.state.sandbox.jobExecutionProgress.stages;
				const filteredstages = stages.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
				return filteredstages
			} else {
				return [];
			}
		},
	},
	methods: {
		DateTimeFormat,
		formatBytes,
		secondsToHourMinSec,
		...mapActions({
			getJobExecutionProgress: "sandbox/getJobExecutionProgress",
		}),
		calcPercentage(a, b) {
			const percent = (a / b) * 100;
			return percent.toFixed(2) + "%";
		},
		async handleGetProgress() {
			if (this.breakCheck)
				return;

			let response;
			try {
				response = await this.getJobExecutionProgress({
					sandboxName: this.sandboxName,
					jobName: this.jobName,
					executionId: this.executionId,
				});
				setTimeout(() => {
					this.handleGetProgress();
				}, 5000)
			} catch (e) {
				if (e.response && e.response.status)
					this.errorTxt = (e.response.status == 204) ? e.response.data.error : "Progress stats not available";


				this.breakCheck = true;
			}

		},
		addStatusIcon(status) {
			let statusStr = "";
			if(status.toLowerCase()==="succeeded"){
			statusStr = "<span class='text-success'>●</span> ";
			} else if(status.toLowerCase()==="failed"){
				statusStr = "<span class='text-danger'>●</span> ";
			} else if(status.toLowerCase()==="pending"){
				statusStr = "<span class='text-warning'>●</span> ";
			} else if(status.toLowerCase()==="running"){
				statusStr = "<span class='text-primary'>●</span> ";
			} else if(status.toLowerCase()==="cancelled"){
				statusStr = "<span class='text-secondary'>●</span> ";
			}

			return statusStr + status;
		}
	},


	beforeMount() {
		this.handleGetProgress();

	},
	beforeUnmount() {
		this.breakCheck = true;
	}
};
</script>

<style scoped>
.collapsed .fa {
	transform: rotate(180deg);
}
</style>
