<template>
	<div>
		<div id="override-div">
			<job-editor
				v-on:tabChange="showTab"
				:editMode="editMode"
				v-model="yamlContent"
				:activeTab="activeTab"
			></job-editor>
		</div>
	</div>
</template>
<script>
	import JobEditor from "../../../components/sandbox/JobEditor.vue";
	export default {
		components: { JobEditor },
		data() {
			return {
				activeTab: "override.yaml",
				yamlContent: {},
			};
		},
		props: ["editMode", "isSandbox", "job", "override"],
		mounted() {
			this.initateJobEditorContent();
			this.$emit("hideEdit", true);
			this.$emit("hideSaveBtn", true);
			this.$emit("showOverrideEdit", false);
			this.$emit("hideRunJob", true);
			this.$emit("hidePromoteBtn", true);
		},
		methods: {
			showTab(tab) {
				this.activeTab = tab;
			},
			initateJobEditorContent() {
				let yamlContent = {};
				if (this.override) {
					yamlContent["override.yaml"] = this.override?.override;
					this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
				}
			},
            saveOverride() {
                //Revisit this method when POST/PUT api's are  created
            }
            
		},
		watch: {
			override() {
				this.initateJobEditorContent();
			},
            saveJobOverrideTrigger() {
                this.saveOverride();
            },
		},
		emits: [
			"hideEdit",
			"hideSaveBtn",
			"showOverrideEdit",
			"hideRunJob",
			"hidePromoteBtn",
			"saveJobOverrideTrigger",
		],
	};
</script>

<style></style>
