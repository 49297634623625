<template>
    <BasicHeader pretitle="Token" 
        :title="token ? token.id : '&nbsp;'" 
        :buttonText="!tokenRevoked? 'Revoke' : null"
        buttonClass="btn-danger"
        @btnClick="onRevokeClick" 
        :buttonText2="!tokenRevoked? 'Cancel' : 'Back'"
        @btnClick2="onCancelClick" />
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="token">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <FormFields
                                :fields="fields"
                                v-model="token"
                                :editMode="editMode"
                                :inlineField=true
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Fields from "../../utils/field-constants/generateTokenFields";
import FormFields from "../../components/FormFields.vue";
import { mapActions } from "vuex";
export default {
    name: "Token",
    components: { FormFields, BasicHeader },
    data() {
        return {
            editMode: false,
            token: null,
            snapshot: null,
            error: "",
            fields: [],
            tokenRevoked: false
        };
    },
    beforeMount() {
        this.fetchToken();
        this.fields = JSON.parse(JSON.stringify(Fields))
        //All fields should be visible in view option
        this.fields.map((field)=>{
            //Note: As of now client config is not storing in DB , unable to view
            if(field.name !== "cc"){
                field.visible =true
            }else{
                field.visible =false
            }
            if(field.type =='multiselect'){
                field.type='text'
            }
        })
    },
    methods: {
        ...mapActions({
            readToken: "admin/readToken",
            revokeToken: "admin/revokeToken",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.error = null;
        },
        async fetchToken() {
            let response={};
            if(!this.$route.params.data){
                try {
                    response = await this.readToken(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.token = await response.data;
            //from contents getting cids and roles
            let contents=JSON.parse(this.token.contents)
            this.token["cids"]=contents.scope.cids
            this.token["roles"]=contents.roles
            //if revoked_at is not null then token is revoked else check token Expired
            if(this.token.revoked_at || new Date()>new Date(this.token.valid_until)){
                this.tokenRevoked=true
            }
        },
        onRevokeClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Revoke Token",
                    message: `Are you sure you want to revoke ${this.token.id}?`,
                    async confirm() {
                        try {
                            const response = await self.revokeToken(self.token.id);
                            self.$router.push("/admin/tokens");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        onCancelClick(){
            this.$router.push("/admin/tokens");
        }
        
    },
};
</script>

<style>
</style>