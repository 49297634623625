<template>
    <basic-header pretitle="Sandbox" title="Unit Test"></basic-header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <input-field label="Choose Job Type" v-model="jobType" type="select" :options="jobTypeOptions" />
            </div>
            <div class="col-md-4">
                <label class="form-label">&nbsp;</label>
                <button class="btn btn-secondary d-block w-100" data-bs-toggle="collapse" href="#advancedOptions" role="button" aria-expanded="false" aria-controls="advancedOptions">
                    Advanced Options
                </button>
            </div>
        </div>
        
        
        <div class="collapse" id="advancedOptions">
            <div class="card card-body">
                <div class="row">
                    <div class="col-md">
                        <input-field type="text" label="Region" v-model="$store.state.sandboxUnitTest.region" helpText='AWS region name if different from default, i.e. "us-east-1"' />
                    </div>
                    <div class="col-md">
                        <input-field label="Environment" v-model="$store.state.sandboxUnitTest.environment" type="select" :options="environmentOptions" />
                    </div>
                    <div class="col-md">
                        <input-field type="text" label="Job Name Override" v-model="$store.state.sandboxUnitTest.jobNameOverride" helpText='' />
                    </div>                  
                </div>
                <div class="row" v-if="Object.keys($store.state.sandboxUnitTest.advancedPropsYaml).length">
                    <div class="col-md">
                        <label class="form-label">Advanced Properties</label>
                        <codemirror-editor :key="componentKey"              
                            editorId="key"
                            mode="yaml"
                            :readOnly= true 
                            editorType="advanced_props"
                            v-model="advancedPropsYaml"
                            :refreshOn= "refreshOn"                          
                        />
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div v-if="jobType == 'connector'">
                <connector-job></connector-job>
            </div>
            
            <div v-if="jobType == 'mapper'">
                <mapper-job></mapper-job>
            </div>

            <div v-if="jobType == 'mapperv2'">
                <mapper-v2-job></mapper-v2-job>
            </div>

            <div v-if="jobType == 'collator'">
                <collation-job></collation-job>
            </div>
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import InputField from '../../components/InputField.vue';
import CollationJob from '../../components/sandbox/unit-test/CollationJob.vue';
import ConnectorJob from '../../components/sandbox/unit-test/ConnectorJob.vue';
import MapperJob from '../../components/sandbox/unit-test/MapperJob.vue';
import MapperV2Job from '../../components/sandbox/unit-test/MapperV2Job.vue';
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
export default {
    components: { BasicHeader, MapperJob, MapperV2Job, CollationJob, ConnectorJob, InputField, CodemirrorEditor },
    data() {
        return {
            environmentOptions: [
                { label: "prod", value: "prod" },
                { label: "preprod", value: "preprod" },
            ],
            jobTypeOptions: [
                { label: "Connector", value: "connector" },
                { label: "Mapper v2", value: "mapperv2" },
                { label: "Mapper v1", value: "mapper" },
                { label: "Collator", value: "collator" },
            ],
            refreshOn: false,
            componentKey: 0
           
            
        }
    },
     mounted() {        
        let self = this;
        var myCollapsible = document.getElementById('advancedOptions');
        myCollapsible.addEventListener('shown.bs.collapse', function () {
            self.componentKey++;
            self.refreshOn = true;  
              
        });
    },
    computed: {
        jobType: {
            get() {
                return this.$store.state.sandboxUnitTest.jobType;
            },
            set(value) {
                 this.$store.dispatch("sandboxUnitTest/setJobType", value);
            }
        },
        
        advancedPropsYaml: {
            get() {
                return this.$store.state.sandboxUnitTest.advancedPropsYaml;
            },
            set(value) {
            }

        }
    }
};
</script>

<style>
.job-type-btn {
    border-color: #B1C2D9 !important;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2em;
    width:200px;
}
#advancedOptions .CodeMirror {
    height: 15rem !important;
}

</style>