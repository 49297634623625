import { Tooltip } from "bootstrap";

function getClassFields() { 
    return[
        {
           
            name: "description",
            label: "Description",
            type: "text",
            editable: true,
            visible: true,
        },       
        {
           // Load Type is based on pixel batch size
            name: "loadType",
            label: "Load Type",
            tooltip: "Choose pixel Loading 1. Batch: Fire pixels on every page load 2. Lazy: Fire pixels after a delay on same page load",
            type: "radio",
            editable: true,
            options:[
                { label: "Batch Load", value: "BATCH_LOAD" },
                { label: "Lazy Load", value: "LAZY_LOAD" }
            ],
            visible: true,

        },
        { 
            name: "initLoad",
            label: "Initial Load",
            type: "number",
            editable: true,
            visible: true,
            validation: [
                { type: "required" }
            ],
            toolTip: 'Maximum number of pixels to fire when the page loads for the first time',
            suppressIfField: 'loadType', suppressIfValueContains: ["LAZY_LOAD"]
        },
        {
            name: "subsLoad",
            label: "Subsequent Loads",
            type: "number",
            editable: true,
            visible: true,
            validation: [
                { type: "required" }
            ],
            toolTip: 'Maximum number of pixels to fire when the page loads for subsequent times.',
            suppressIfField: 'loadType', suppressIfValueContains: ["LAZY_LOAD"]
        },
        {
            name: 'pixelBatchSize',
            label: 'Batch Size',
            type: 'number',
            editable: true,
            visible: true,
            toolTip: 'No of pixels to load after "Batch Period" seconds. If the user stays on the page',
            suppressIfField: 'loadType', suppressIfValueContains: ["BATCH_LOAD"]
          },
          {
            name: 'pixelBatchPeriod',
            label: 'Batch Period',
            type: 'number',
            editable: true,
            visible: true,
            toolTip: 'Time (in seconds) after which to load additional set of pixels',
            suppressIfField: 'pixelBatchSize', suppressIfValueContains: [0]
          },
        {
           
            name: "pkMap",
            label: "Key-Value Map",
            type: "object",
            editable: true,
            visible: true,
            toolTip: 'Key-value map encoded in JSON. These can be used, for example, to refer to redirect-URL\nin r pixel ' +
            'by using pk=<key> query parameter instead of hard-coded ru=<url>'
        },
        {
           
            name: "enabledRegions",
            label: "Enabled Regions",
            type: "array",
            editable: true,
            visible: true,
            toolTip: 'List of regions that will have direct non ESI files served along with region info'
        },
        {
            name: "logCookies",
            label: "Log Request Cookies",
            type: "radio",
            editable: true,
            visible: true,
            tooltip:"Choose to log all cookies or specify which cookies to log",
            options:[
                { label: "ALL", value: "ALL" },
                { label: "SPECIFIC", value: "SPECIFIC" }
            ] 
        },
        {
            name: "cookieList",
            label: "Specific Cookies to log",
            toolTip: 'List of case sensitive cookies to log',
            type: "multiselect",
            editable: true,
            visible: true,
            suppressIfField: 'logCookies', 
            suppressIfValueContains: ['ALL'] 
        },
        {
            name: "log",
            label: "Log",
            type: "object",
            tooltip:"Log mode of logging beacon.Delivery: Batch/Streaming, Level:Base/Extended.If extend,the logs will also include geo information, Ip Format: No IP/Raw/Hashed Ip",
            parseObject: true,
            editable: true,
            visible: true
        },
        {
            name: "logBeacon.mobile",
            label: "Log Beacon(Mobile)",
            type: "object",
            tooltip:"Config to fire logging beacon in case of lazy load of pixels for mobile devices",
            parseObject: true,
            editable: true,
            visible: true

        },
        {
            name: "logBeacon.std",
            label: "Log Beacon(Standard)",
            type: "object",
            tooltip:"Config to fire logging beacon in case of lazy load of pixels",
            parseObject: true,
            editable: true,
            visible: true

        },
        {
            name: "splitDl",
            label: "Split DL",
            type: "text",
            toolTip: "Field in data layer which will have large payload. This field will be chopped and logged separately",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1
        },
        {
            name: "logSplitDl",
            label: "Log Split Dl",
            type: "multiselect",
            toolTip: "List of data layer fields that will be logged when logging large payloads",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            suppressIfField: 'splitDl', suppressIfValueContains: [null] 
        },
        {
            name: "splitDlSize",
            label: "Split DL Size",
            toolTip: "Size (in bytes) of chopped string that will be logged with each request",
            type: "number",
            editable: true,
            visible: true,
            readOnlyIfRoleOver: 1,
            validation: [               
                { type: "minMax", minValue:512, maxValue:4096 }
            ],
            suppressIfField: 'splitDl', suppressIfValueContains: [null] 
        },
        {
            name: "baseClass",
            label: "Based on Class",
            type: "select",
            tooltip:'If a base class is set, the derived class inherits enabledRegions, codeSnippet, and pixels which can\'t be reconfigured. Pixels from the base class trigger based on the derived class settings.',
            editable: true,
            visible: true,
            options:[
                { label: "NONE", value: null }
            ]    
        },
        {
            name: 'maxPixels',
            label: 'Maximum Pixels from Base Class',
            type: 'number',
            tooltip: 'Maximum pixels from base class to inherit',
            suppressIfField: 'baseClass', suppressIfValueContains: [null],
            visible: true
        },        
        {
            name:'rTag.redirect_restriction',
            label: "Redirect Access",
            toolTip: "Restrict redirect functionality for the tag deployment.",
            type: "select",
            editable: true,
            visible: true,
            options:[
                { label: "NONE", value: "NONE" },
                { label: "ALLOW", value: "ALLOW" },
                { label: "DENY", value: "DENY" }
            ]   
        },{
            name:'rTag.restricted_redirect_domains',
            label: "Redirect Domains",
            toolTip: "Whitelist of ALLOW/DENY target domains for redirect",
            type: "multiselect",
            editable: true,
            visible: true,
            validation: [                
                {type: "regex", pattern:"^[A-Za-z0-9][\\w\\.\\-]*\\.[A-Za-z0-9]+(\\/[\\w\\.\\-]*)*\\.?$", errMsg: "Invalid domain"}
            ],
            suppressIfField: 'rTag.redirect_restriction', suppressIfValueContains: ["NONE"],
        }, 
        {
            name:'rTag.redirect_enabled',
            label: "Allow Redirect",
            toolTip: "Enable redirect functionality for the tag deployment",
            type: "checkbox",
            editable: true,
            visible: true
        }, 
        {
            name:'rTag.setClientCookie',
            label: "Set Client Cookie(rTag)",
            toolTip: "If set to true, client cookie will be set by r tag",
            type: "checkbox",
            editable: true,
            visible: true
        }, 
        {
            name:'rTag.fireOnlyIfCookieExists',
            label: "Fire Only If Cookie Exists(rTag)",
            toolTip: "If set to true, r tag will not fire if client cookie does not exist",
            type: "checkbox",
            editable: true,
            visible: true
        },          
        {   // Not avilable in Old Portal
            name: "rotations",
            label: "Rotation Configs",
            type: "object",
            tooltip:"List of configurations to set rotation cookie",
            inputType: "json",
            editable: true,
            visible: true
        }
    ]

}

export default getClassFields;