<template>
    <input-field
        v-for="field in fields"
        :inlineField="inlineField"
        :colField="colField"
        :visible="field.visible"
        :editable="editMode ? ((field.readOnlyIfRoleOver && field.readOnlyIfRoleOver < userRoleRank )? false: field.editable) : editMode"
        :refreshOn="refreshOn"
        :parseObject="field.parseObject"
        :key="field.name"
        :label="field.label"
        :placeholder="field.placeholder"
        :toolTip="field.toolTip||field.tooltip"
        :validate="field.validation"
        :type="field.type"
        :inputType="field.inputType"
        :name="field.name"
        :defaultValue="field.defaultValue"
        :modelValue="getDeepValForKey(field.name)"
        @update:modelValue="setDeepValForKey"
        :options="field.options"
        :customComponent="field.customComponent"
        :component="field.component"
        :url="field.url"
        :format="field.format"
        :maxSize="field.maxSize"
        :helpText="field.helpText"
        :toolTipHtml="field.toolTipHtml"
    />
</template>
<script>
import InputField from "./InputField.vue";
import store from '../store';
export default {
    components: { InputField },
    name: "FormFields",
    props: {
        fields: Array,
        modelValue: Object,
        inlineField: {
            type: Boolean,
            default: false,
        },
        refreshOn: Boolean,
        editMode: {
            type: Boolean,
            default: true,
        },
        colField: {
            type: String,
            default: null,
        },
    },
    computed: {
        inputValObj: {
            get() {
                return this.modelValue;
            },
        },
        userRoleRank() {
            return this.$store.getters.userRoles.userRoleRank;
        }        
    },
    methods: {
        getDeepValForKey(key) {
            let self = this;
            const val = key.split(".").reduce((o, i) => o[i], self.inputValObj);
            return val;
        },
        setDeepValForKey(val, key) {
            let self = this;
            var keys = key.split(".");
            if (keys.length > 1) {
                var beforeLast = keys
                    .slice(0, -1)
                    .reduce((o, i) => o[i], self.inputValObj);
                beforeLast[keys[keys.length - 1]] = val;
            } else {
                self.inputValObj[key] = val;
            }
            this.$emit(`update:modelValue`, self.inputValObj);
        },
    },
    emits: ["update:modelValue", "change"],
};
</script>
