import { reactive } from "@vue/reactivity"
import validators from './validators'
const errors = reactive({});

export default function formValidation() {
    const validatorsObj = validators();

    // Object lookup for the validation name and its corresponding validators function name
    const rulesLookUp = {
        required: "isEmpty",
        email: "isEmail",
        regex: "isRegex",
        min: "minLength",
        url:"isURL",
        duplicate: "isDuplicate",
        minMax: "minMax"
      };

    const validateInput = (fieldName, fieldLabel, fieldValue, validateRules) => {
        let passed = true;
        let rules = [];
        if(Array.isArray(validateRules)) {           
            rules = validateRules.map((rule) => {
                const validator = validatorsObj[rulesLookUp[rule.type]];
                const errMsg = validator(fieldName, fieldLabel, fieldValue, rule);
                passed = (errMsg!=='')? false: true;
                return  {  
                        name: rule.type,  
                        passed: passed,
                        message: errMsg
                    };                 
            });
            passed = (rules.filter(rule => !rule.passed).length > 0)?false:true;

        } else if(typeof validateRules === 'string'){
            let ValidationRulesArr = validateRules.split(',');           
            rules = ValidationRulesArr.map((rule) => { 
                const validator = validatorsObj[rulesLookUp[rule]];
                const errMsg = validator(fieldName, fieldLabel, fieldValue);
                passed = (errMsg!=='')? false: true;
                return  {  
                        name: rule,  
                        passed: passed,
                        message: errMsg
                    }; 
            }); 
        }

        errors[fieldName]  = { passed, rules };
        return { passed, rules };  
             
    }
    return { errors, validateInput }
}