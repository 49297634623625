<template>
  <div class="container-fluid">
    <nav-header
      :navItems="[
        {
          name: 'Job Templates',
          link: {
            name: 'Job Templates',
          },
        },
        {
          name: `${jobTemplate ? jobTemplate.name : 'Job Template'}`,
          link: {
            name: 'Job Template',
            params: {
              id: ` `,
            },
          },
          closeTab: true,
          disable: true,
        },
        {
          name: 'Job Template Groups',
          link: {
            name: 'Job Template Groups',
          }
        },
        {
          name: `${jobTemplateGroup ? jobTemplateGroup.name : 'Job Template Group'}`,
          link: {
            name: 'Job Template Group',
            params: {
              id: ` `,
            },
          },
          closeTab: true,
          disable: true,
        },
      ]"
      pretitle="Template"
      :breadcrumbTitle="true"
      :editMode="editMode"
      @nav-close-clicked="closeClick"
      @prevent-navigation="showPopup"
    >
      <template v-slot:breadcrumb-title>
        <nav aria-label="breadcrumb" class="header-title">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a @click="closeClick" href="#" class="h1">Job Templates</a>
            </li>
            <li class="breadcrumb-item active h1" aria-current="page">
              {{ currentPage }}
            </li>
          </ol>
        </nav>
      </template>
      <template v-slot:header-button-content>
        <div v-if="currentPage.includes('Job Template Group')" class="custom-btn-group">
          <span v-if="editModeGroup">
            <button @click.prevent="onSaveClickGroup()" class="btn btn-success">Save</button>
            <button @click.prevent="onDeleteClickGroup()" class="btn btn-danger">
              Delete
            </button>
            <button @click.prevent="onCancelClickGroup()" class="btn btn-secondary">
              Cancel
            </button>
          </span>
          <span v-else-if="!editModeGroup && !hideEditBtnGroup">
            <button type="button" @click.prevent="onEditClickGroup()" class="btn btn-primary">
              Edit
            </button>
          </span>
          <span v-else-if="hideEditBtnGroup">
            <button
              type="button"
              @click.prevent="showModal({ component: 'AddJobTemplateGroup' })"
              class="btn btn-primary"
            >
              Create Template Group
            </button>
          </span>
        </div>
        <div v-else class="custom-btn-group">
          <span v-if="editMode">
            <button @click.prevent="onSaveClick()" class="btn btn-success">Save</button>
            <button @click.prevent="onDeleteClick()" class="btn btn-danger">
              Delete
            </button>
            <button @click.prevent="onCancelClick()" class="btn btn-secondary">
              Cancel
            </button>
          </span>
          <span v-else-if="!editMode && !hideEditBtn">
            <button type="button" @click.prevent="onEditClick()" class="btn btn-primary">
              Edit
            </button>
          </span>
          <span v-else-if="hideEditBtn">
            <button
              type="button"
              @click.prevent="showModal({ component: 'AddJobTemplate' })"
              class="btn btn-primary"
            >
              Create Template
            </button>
          </span>
        </div>
       
      </template>
    </nav-header>
    <div v-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div>
      <router-view
        :editMode="editMode"
        :editModeGroup="editModeGroup"
        :saveJobTrigger="saveJobTrigger"
        :cancelClick="cancelClick"
        :saveJobGroup="saveJobGroup"
        :cancelClickGroup="cancelClickGroup"
        v-on:setSaveConfigBtn="setSaveConfigBtn"
        v-on:hideEdit="setHideEdit"
        v-on:triggerEditMode="setEditMode"
        v-on:hideEditGroup="setHideEditGroup"
        v-on:triggerEditModeGroup="setEditModeGroup"
        v-on:setJobTemplate="setJobTemplate"
        v-on:setJobTemplateGroup="setJobTemplateGroup"
      >
      </router-view>
    </div>
  </div>
</template>

<script>
import NavHeader from "../../components/NavHeader.vue";
import { mapActions } from "vuex";
export default {
  components: { NavHeader },
  data() {
    return {
      error: null,
      jobTemplate: null,
      snapshot: null,
      saveJobTrigger: false,
      cancelClick: false,
      editMode: false,
      hideEditBtn: true,
      showSaveConfigBtn: false,
      currentPage:"",
      saveJobGroup: false,
      cancelClickGroup: false,
      editModeGroup: false,
      hideEditBtnGroup: true,
      showSaveConfigBtnGroup: false,
      jobTemplateGroup:null
    };
  },
  watch: {
    "$route.params.id": {
      handler: function (to, from) {
        if (to) {
          this.setHideEdit(true);
          this.setHideEditGroup(true);
        } else {
          this.setEditMode(false);
          this.setHideEdit(true);
          this.setEditModeGroup(false);
          this.setHideEditGroup(true);
        }
      },
    },
    $route (to, from){
        if(to.name && to.name.includes("Job Template Group")){
          this.currentPage= "Job Template Group"
        }else{
          this.currentPage= "Job Template"
        }
    }
  },
  methods: {
    ...mapActions({
      showModal: "showModal",
      deleteJobTemplate: "admin/deleteJobTemplate",
      deleteJobTemplateGroup: "admin/deleteJobTemplateGroup",
    }),
    setJobTemplate(job) {
      this.jobTemplate = job;
    },
    setJobTemplateGroup(job) {
      this.jobTemplateGroup = job;
    },
    closeClick() {
      this.jobTemplate = null;
      this.$router.push({
        name: "Job Templates",
      });
    },
    showPopup() {
      var self = this;
      this.showModal({
        component: "confirm",
        data: {
          title: "Save Changes",
          message: `Do you want to save changes ?`,
          async confirm() {
            self.onSaveClick();
          },
          async cancel() {
            self.onCancelClick();
          },
        },
      });
    },
    setHideEdit(hideEdit) {
      this.hideEditBtn = hideEdit;
    },
    setEditMode(mode) {
      this.editMode = mode;
      this.successMessage = null;
      this.error = null;
    },
    setSaveConfigBtn(val) {
      this.showSaveConfigBtn = val;
    },
    onEditClick() {
      this.setEditMode(true);
    },
    onSaveClick() {
      this.successMessage = null;
      this.error = null;
      this.saveJobTrigger = !this.saveJobTrigger;
      this.setEditMode(false);
    },
    onDeleteClick() {
      const self = this;
      this.showModal({
        component: "confirm",
        data: {
          title: "Delete Job Template",
          message: `Are you sure you want to delete ${this.jobTemplate.name}?`,
          async confirm() {
            try {
              await self.deleteJobTemplate(self.jobTemplate.id);
              let toastoptions = {
                message:
                  "Job template '" + self.jobTemplate.name + "' deleted successfully.",
                type: "success",
              };
              self.$store.dispatch("addToast", toastoptions);
              self.$store.dispatch("hideModal");
              self.$router.push({
                name: "Job Templates",
              });
            } catch (error) {
              if (error.response && error.response.data.error)
                self.error = error.response.data.error;
              else self.error = error.message;
              return;
            }
          },
        },
      });
    },
    onCancelClick() {
      this.cancelClick = !this.cancelClick;
      this.setEditMode(false);
    },
    setHideEditGroup(hideEdit) {
      this.hideEditBtnGroup = hideEdit;
    },
    setEditModeGroup(mode) {
      this.editModeGroup = mode;
      this.successMessage = null;
      this.error = null;
    },
    onEditClickGroup() {
      this.setEditModeGroup(true);
    },
    onSaveClickGroup() {
      this.successMessage = null;
      this.error = null;
      this.saveJobGroup = !this.saveJobGroup;
      this.setEditModeGroup(false);
    },
    onDeleteClickGroup() {
      const self = this;
      this.showModal({
        component: "confirm",
        data: {
          title: "Delete Job Template",
          message: `Are you sure you want to delete ${this.jobTemplateGroup.name}?`,
          async confirm() {
            try {
              await self.deleteJobTemplateGroup(self.jobTemplateGroup.id);
              let toastoptions = {
                message:
                  "Job template group '" + self.jobTemplateGroup.name + "' deleted successfully.",
                type: "success",
              };
              self.$store.dispatch("addToast", toastoptions);
              self.$store.dispatch("hideModal");
              self.$router.push({
                name: "Job Template Groups",
              });
            } catch (error) {
              if (error.response && error.response.data.error)
                self.error = error.response.data.error;
              else self.error = error.message;
              return;
            }
          },
        },
      });
    },
    onCancelClickGroup() {
      this.cancelClickGroup = !this.cancelClickGroup;
      this.setEditModeGroup(false);
    },
  },
  emits: [
    "triggerEditModeGroup",
    "hideEditGroup",
    "setJobTemplateGroup",
    "setSaveBtnGroup",
  ],
};
</script>

<style scoped>
.custom-btn-group {
  display: flex;
  align-items: center;
}
.btn {
  margin-right: 12px;
}
</style>
