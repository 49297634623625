<template>
  <detail-header
        pretitle="Tag Templates"
        :title="tagTemplate ? tagTemplate.name : '&nbsp;'"
        :secondaryTitle="subtitle"
        :editable="true"
        :editMode="editMode"
        @editClick="onEditClick"
        :editRoles="editRoles"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push(`/${cid}/aut/tag-templates`)"
    />
    <tag-template v-if="tagTemplate" :editMode="editMode" v-model="tagTemplate" ></tag-template>
    <div class="row container-fluid">
        <div class="col-md-6">
            <div v-if="error" class="alert alert-danger" role="alert">
                        {{error}}
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import TagTemplate from "../../components/aut/TagTemplate.vue";
import { DateTimeFormat, isDuplicate } from "../../utils/commonFunction";
import { mapActions, mapState } from "vuex";
export default {
    components: { DetailHeader,TagTemplate },
    data() {
        return {
            editMode: false,
            snapshot: null,
            tagTemplate: null,
            error: "",
            editRoles: [1,3]

        }

    },
    beforeMount() {          
        this.fetchTagTemplate();        
    },
    computed: {
        subtitle() {
            if(this.tagTemplate) {
                return `<span style="font-weight:100;">
                    <b>Owner:</b> ${this.tagTemplate.owner} | 
                    <b>Updated:</b> ${DateTimeFormat(this.tagTemplate.modified_at)}
                     - ${this.tagTemplate.modified_by}</span>`
            }
            return ''
        },
        ...mapState({ 
            cid: state => state.currentClient.cid,
        })       
    },   
    methods: {
        ...mapActions({            
            readTagTemplate: "aut/readTagTemplate",
            updateTagTemplate: "aut/updateTagTemplate",
            deleteTagTemplate: "aut/deleteTagTemplate",
            showModal: "showModal",
            fetchTagTemplates: "aut/fetchTagTemplates"
        }),        
        onCancelClick() {
            this.setEditMode(false);
            this.tagTemplate = JSON.parse(JSON.stringify( this.snapshot ));
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Tag Template",
                    message: `Are you sure you want to delete "${this.tagTemplate.name}"?`,
                    async confirm() {
                        try {
                            const response = await self.deleteTagTemplate(
                                self.tagTemplate
                            );
                            self.$router.push(`/${self.cid}/aut/tag-templates`);
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        async onSaveClick() {
            try {
                let valid= false;
                //Note: If Reload happen in view tag template then list of tag template wont available
                if(!this.$store.state.aut.tagTemplates.length){   
                   await this.fetchTagTemplates().then(()=>{
                       valid = !isDuplicate(this.tagTemplate.name, this.$store.state.aut.tagTemplates, "name", this.snapshot.name)
                   })
                }else{
                    valid = !isDuplicate(this.tagTemplate.name,this.$store.state.aut.tagTemplates, "name", this.snapshot.name  )
                }
                let options ={}
                if(valid){
                    let tagtemplate = JSON.parse(JSON.stringify(this.tagTemplate ));
                    tagtemplate.tag =tagtemplate.tag? tagtemplate.tag.toString():""
                    const response = await this.updateTagTemplate(tagtemplate);
                    this.setEditMode(false);
                    this.snapshot = JSON.parse(JSON.stringify(this.tagTemplate ));
                    options = {
                        message: "Tag template saved.",
                        type: "success",
                    };
                }else{
                    options = {
                        message: "Already '"+this.tagTemplate.name+"' name exits.'",
                        type: "error",
                    };
                }
                this.$store.dispatch("addToast", options);
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
                const options = {
                    message: self.error,
                    type: "error",
                };
                this.$store.dispatch("addToast", options);
            }
        },
        async fetchTagTemplate() {
            let response={};
            if(!this.$route.params.data){
                try {
                    response = await this.readTagTemplate(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            response.data.tag = response.data.tag ?response.data.tag.split(","):[]
            this.tagTemplate = await response.data;
            this.snapshot = JSON.parse(JSON.stringify(this.tagTemplate));
        },
        setEditMode(mode) {
            this.editMode = mode;
            this.error = null;
        },
    }   

}
</script>

<style>

</style>