<template>
  <Sidebar />
  <MainContentArea />
  <Modal />
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
import MainContentArea from '../components/MainContentArea.vue'
import Modal from '../components/Modal.vue'
import { mapActions } from "vuex";
export default {
  name: 'Portal',
  data() {
    return {
      inactivityTimer: null,
      warningTimer: null,
    };
  },
  components: {
    Sidebar,
    MainContentArea,
    Modal
  },
  methods: {
    ...mapActions({
            showModal: "showModal",
        }),
        startInactivityTimer() {
          this.clearTimers();
          this.inactivityTimer = setTimeout(() => {
            this.showModal({ component: 'AccessTokenWarning', data: { onConfirm:this.resetTimer, onCancel:this.logout }})
            this.startWarningTimer();
          }, 3600000); // 1 hour 
        },
        startWarningTimer() {
          this.warningTimer = setTimeout(() => {
            this.logout();
          }, 60000); // 1 minute
        },
        resetTimer() {
          this.clearTimers();
          this.startInactivityTimer();
        },
        clearTimers() {
          clearTimeout(this.inactivityTimer);
          clearTimeout(this.warningTimer);
        },
        logout() {
          this.$store.dispatch('hideModal'); 
          // redirect to login page
          this.$store.dispatch('logout');
        },
  },
  mounted() {
    this.startInactivityTimer();
    window.addEventListener('mousemove', this.resetTimer);
    window.addEventListener('keyup', this.resetTimer);
  },
  beforeUnmount() {
    this.clearTimers();
    window.removeEventListener('mousemove', this.resetTimer);
    window.removeEventListener('keyup', this.resetTimer);
  },
  beforeMount(){
    
  }
}
</script>

<style>
</style>