<template>
  <div :class="[compact ? 'compact' : '', 'header']">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">{{pretitle}}</h6>
            <!-- Title -->
            <h1 class="header-title" v-html="title"></h1>
            <!-- Secondary Title -->
            <h4 class="header-title">{{secondaryTitle}}</h4>
          </div>
          <!-- Button -->
          <div v-if="buttonText" class="col-auto">
            <button @click="btnClick" class="btn lift" :class="buttonClass" :disabled="!buttonRoles.length || isAccessByUserRole(buttonRoles) ? false:true"><slot></slot> {{buttonText}} 
              <i v-if="buttonRoles.length && !isAccessByUserRole(buttonRoles)" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right"></i>                                    
            </button>
          </div>
           <div v-if="buttonText2" class="col-auto">
            <button @click="btnClick2" class="btn lift" :class="buttonClass2" :disabled="!button2Roles.length || isAccessByUserRole(button2Roles) ? false:true"><slot></slot> {{buttonText2}} 
              <i v-if="button2Roles.length && !isAccessByUserRole(button2Roles)" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right"></i>                                   
            </button>
          </div>
          <div v-if="sideTitle" class="col-auto">
            <h3>{{sideTitle}}</h3>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'BasicHeader',
    props: {
        'pretitle': String,
        'title': String,
        'secondaryTitle': String,
        'sideTitle': String,
        'buttonText': String,
        'compact': Boolean,
        'buttonClass': {
          type: String,
          default: "btn-primary"
        },
        'buttonText2': String,
        'buttonClass2': {
          type: String,
          default: "btn-secondary"
        },
        'buttonRoles':{
          type: Array,
          default: function () {
              return []
          }

        },
        'button2Roles': {
          type: Array,
          default: function () {
              return []
          }

        }

    },
    methods:{
      btnClick() {
        this.$emit('btnClick')
      },
      btnClick2() {
        this.$emit('btnClick2')
      },
      isAccessByUserRole(roles) {
        return this.$store.getters.userRoles.isAccessByRole(roles);
      } 
    },   
    emits: [
      'btnClick',
      'btnClick2'
    ]
};
</script>

<style>
</style>