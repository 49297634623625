function GenerateCurlCommand(method, url, headers, body){
    return `curl ${getMethod(method)} "${url}" ${getHeaders(headers)} ${getBody(method,body)}`
      .trim()
}
function getMethod(method){
    return `-X ${method.toUpperCase()}`;
}
function getHeaders(headers){
    let curlHeaders= ""
    Object.keys(headers).forEach(function(key) {
       curlHeaders += `-H '${key}: ${headers[key]}' `
    })
    return curlHeaders
}
function getBody(method,body){
    if(body && body!=='' && method.toUpperCase() !== 'GET')
        return `--data '${JSON.stringify(body)}'`
    return ''
}

export { GenerateCurlCommand }