<template>
  <li class="nav-item">
    <router-link 
      :class="[active ? 'active' : '', 'nav-link']"
      v-if="item.route"
      :to="to"
    ><i :class="`fa fa-${item.icon}`"></i><span class="name">{{item.name}}</span>
    </router-link>
    <a 
      v-else-if="item.children"
      :class="[active ? 'active' : '', 'nav-link']"
      :href="`#${groupName}`"
      data-bs-toggle="collapse"
      role="button"
      :aria-expanded="expandChildren"
      :aria-controls="groupName"
    ><i :class="`fa fa-${item.icon}`"></i><span class="name">{{item.name}}</span>
    </a>
    <div v-if="item.children" :class="[expandChildren ? 'show' : '', 'collapse children']" :id="groupName">
      <ul class="nav nav-sm flex-column">
        <NavItem v-for="child in item.children" :key="child.name" :item="child" :parent="item" /> 
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    item: Object,
    parent: Object,
  },
  data() {
    return {
      expandChildren: false
    }
  },
  mounted(){
      this.expandChildren = this.active
  },
  watch: {
    active(val) {
      this.expandChildren = val;
    }
  },
  computed: {
    groupName() {
      let groupName = this.item.name;
      if(this.parent){
        groupName = this.parent.name + groupName
      }
      return groupName.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    active() {
      //check children for active class - and make parent active/expanded
      if(this.item.children){
        for (const child of this.item.children) {
          if ((this.$route.path == child.route) || (this.$route.matched[1].path === child.route)) {
            return true;
          }
          if(child.children){
            for (const grandchild of child.children) {
              if ((this.$route.path == grandchild.route)||(this.$route.matched[1].path === grandchild.route)) {
                return true;
              }

              if((this.$route.path.indexOf(grandchild.route+'/') > -1) || (this.$route.matched[1].path.indexOf(grandchild.route+'/') > -1))
                return true

            }
          }

          if((this.$route.path.indexOf(child.route+'/') > -1) || (this.$route.matched[1].path.indexOf(child.route+'/') > -1))
            return true
        }
      }
     
      if((this.$route.path.indexOf(this.item.route+'/') > -1) || (this.$route.matched[1].path.indexOf(this.item.route+'/') > -1) )
          return true

      return ((this.$route.path === this.item.route) || (this.$route.matched[1].path ===this.item.route));
    },
    
    to() { 
		let route =  this.item.route;
		if(route.match(/:sandboxId/g)) {
			route = this.$store.getters["sandbox/computeNavRoute"](this.item);
		}		
		route = route.replace(/:cid/g, this.$store.state.currentClient.cid);
		return route;
    }	
  }
};
</script>

<style scoped>
.navbar-vertical-sm .nav-item .name, .navbar-vertical-sm .nav-item .children {
  display: none;
}
.navbar-vertical.navbar-vertical-sm .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  display:none;
}
</style>