<template>
    <div class="container-fluid">
        <div class="m-2">
            <Breadcrumbs :breadcrumbItems="this.$store.state.breadcrumbs" />
        </div>
        <div class="card">
            <router-view></router-view>
        </div>
    </div>
    
</template>
<script>
import Breadcrumbs from "../../components/Breadcrumbs.vue";
export default {
    components: { Breadcrumbs },
    name: "DetailFolderView",
};
</script>

<style></style>