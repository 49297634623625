<template>
  <div class="card">
    <Datatable
      tableId="jobTemplatesTable"
      uniqueField="id"
      :columns="columns"
      :data="jobTemplates"
      :compact="true"
      :selectCheckboxes="true"
      :orderColumn="orderColumn"
      :formatColumn="formatColumn"
      :deletable="true"
      @deleteRows="showDeletePage"
      :detailColumn="{
        baseUrl: 'job-templates/',
        identifier: 'id',
        name: 'Job Template',
      }"
    />
  </div>
</template>

<script>
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";

export default {
  name: "JobTemplates",
  components: { Datatable },
  data() {
    return {
      columns: [
        { data: "name" },
        { data: "type" },
        { data: "created_by", title: "Created by" },
        { data: "created_at", title: "Created at" },
      ],
      orderColumn: [5, "desc"],
      //Note: index - table column index which need to formatted
      formatColumn: [
        {
          index: 5,
          type: "datetime",
        },
      ],
      loading: false,
    };
  },
  computed: {
    jobTemplates() {
      return this.$store.state.admin.jobTemplates;
    },
  },
  beforeMount() {
    this.$store.dispatch("admin/fetchJobTemplates");
  },
  methods: {
    ...mapActions({
      showModal: "showModal",
    }),
    showDeletePage(rows) {
      this.showModal({ component: "DeleteJobTemplate", data: rows });
    },
  },
};
</script>

<style scoped></style>
