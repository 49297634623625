<template>
    <unit-test-job :jobType="jobType" :activeTab="activeTab" :iframeSrc="iframeSrc" v-model="yamlContent" :advancedProps="advancedProps"></unit-test-job>
</template>

<script>
import UnitTestJob from './UnitTestJob.vue';

export default {
    components: { UnitTestJob },
    data() { 
        return {
            jobType: "Collator",
            activeTab: this.$store.state.sandboxUnitTest.collatorActiveTab,
            yamlContent: this.$store.state.sandboxUnitTest.collatorYamlList,
            advancedProps: this.$store.state.sandboxUnitTest.collatorAdvancedProps,
            iframeSrc: `${this.$store.state.docsUrl}/docs/amdp/services/collation-service/collator/examples.html`    
        };
    },    
    watch: {        
        'yamlContent.config': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setCollationConfigYaml", newVal);
            }
        }
    }, 
       
};
</script>

<style scoped>
</style>