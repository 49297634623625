<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title text-primary">{{tagName}}</h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <p>{{scriptTag}}</p>
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button
            >&nbsp;
            <button class="btn btn-primary" id="myButton" @click="copy()">Copy</button
            >&nbsp;
            <button @click="onDownloadClick" class="btn btn-primary ok-btn" >
                Download
            </button>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import axios from "axios";
export default {
    computed: {
        tagName() {            
            return 'scriptTag_' + dayjs().format('YYYYMMDD_hhmmss') + '.txt';
        },
        scriptTag() {
            let sTO = this.modalData;
            const label = sTO.label;

            let sTT = `<script title="pxsnpt">
                        var  ${label}Data={cdmn:'${sTO.cdmn}',lm:'${sTO.lm}',tt:'${sTO.tt}'};`;
            let params = "";
            if(sTO.cls && sTO.cls != "NA") {
                params += `a.cls='${sTO.cls}';`
            }
            if (sTO.dmn) {
                params += `a.dmn='${sTO.dmn}';`
            }
            if (sTO.pubid) {
                 params += `a.pubid='${sTO.pubid}';`
            }
            if (sTO.uu) {
                params += `a.uu='${sTO.uu}';`
            }
            if (sTO.suu) {
                params += `a.suu='${sTO.suu}';`
            }
            for (var i = 0; i < sTO.vars.length; i++) {
                if (sTO.vars[i]) {
                    params += 'a.' + sTO.vars[i].k + "='" + sTO.vars[i].v + "';"
                }
            }
            if (sTO.payload) {
                params += `a.payload='${sTO.payload}';`
            }
            if (sTO.aqet) {
                params += `a.aqet='${sTO.aqet}';`
            }
            if (sTO.aq_m) {
                params += `a.aq_m= ${sTO.aq_m};`
            }
            if (sTO.evid) {
                params += `a.evid='${ sTO.evid }';`
            }
            if (params != "") {
                sTT += `!function(a){${params}}(${label}Data);`
            }
            
            sTT += `function _pxTagInject(p,d,w,l){var o,k=[],t=p.tt.slice(-1),x=
            {cdmn:1,lm:1,tt:1},y={cid:1,cls:1,dmn:1,pubid:1,evid:1,aq_m:1};p.dmn=(p.dmn
            ||(w.top[l]===w[l]?''+w[l]:d.referrer).split('/')[2]).split(':')[0];
            for(o in p){if(y[o]||((t!='j'||p.aq_m)&&!x[o])){k.push(o+"="+p[o]);};};var s=
            d.createElement(t=='f'?'img':'script');s.id=s.title='${label}scrpt';s.async=s.defer
            =!0;s.src='//'+p.cdmn+'/1/'+p.lm+'/'+p.tt+'?'+k.join("&");d.body.
            appendChild(s);}_pxTagInject(${label}Data ,document,window,'location');</sc`+`ript>`    

           return sTT;
        }

    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.scriptTag);
            const options = {
                message: "Tag copied to clipboard.",
                type: "info"
            }
            this.$store.dispatch('addToast', options);
        },
        onDownloadClick () {
            var data = encodeURI('data:text/html;charset=utf-8,' + this.scriptTag);
            var txtData = decodeURIComponent(data);
            if (window.navigator.msSaveBlob) {
              var blob = new Blob([txtData], {
                type: "text/html;charset=utf-8;"
              });
              navigator.msSaveBlob(blob, this.tagName);
            }
            var link = document.createElement('a');
            data = data.replace(/#/g, '%23');
            link.setAttribute('href', data);
            link.style.visibility = "hidden";
            link.setAttribute('download', this.tagName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);            
        }
    }
}
</script>

<style>

</style>