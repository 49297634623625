<template>
    <div class="modal-card card">
        <form @submit.prevent="onGenerateToken()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Generate Token</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body" >
                <form-fields v-if="!message" :fields="fields" v-model="user" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
                <div v-if="message">
                    {{ message }}

                    <div class="mt-3">
                        <label for="basic-url" class="form-label"><strong>Generated Token :</strong></label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Recipient's username" :value="token" readonly aria-label="Token" aria-describedby="copy-token"/>
                            <span class="input-group-text border border-0" id="copy-token">
                                <button type="button" @click="onCopyToken" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy Token"><i class='fa fa-copy' /></button>&nbsp;                    
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer" v-if="!message">
                <div class="text-end">
                    <submit-button
                        :fields="fields"
                        v-model="user"
                        :loading="loading"
                        btnText="Generate"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import formFields from "../FormFields.vue";
import submitButton from "../SubmitButton.vue";
import { mapActions } from "vuex";
import Fields from "../../utils/field-constants/generateTokenFields";
export default {
    components: { formFields, submitButton },
    props: {
        modalData: {
            type: [Object],
        },
    },
    data() {
        return {
            error: "",
            message: "",
            loading: false,
            user: this.modalData,
            fields: [],
            token: null
            
        };
    },
    name: "AddUser",
    methods: {
        ...mapActions("admin", {
            generateToken: "generateToken",
        }),
        async onGenerateToken() {
            this.loading = true;
            this.error = null;
            try {
                const res = await this.generateToken(this.user);
                this.message = "Success! Token has been emailed to " + this.user.email + ".";
                if(res && res.data && res.data.jwt_token) {
                    this.token = res.data.jwt_token;
                }
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                this.loading = false;
                return;
            }
            this.loading = false;
            //if GenerateToken modal called from Tokens, then after adding refresh it
            if (this.$route.path=="/admin/tokens"){
                if(this.$store.getters.userRoles.isAqferAdmin){
                     this.$store.dispatch("admin/fetchAllRefreshTokens");
                }
                this.$store.dispatch("admin/fetchClientsAllRefreshTokens");
            }
        },
        async onCopyToken(){     
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(this.token)
            } else {
                const input = document.createElement('textarea')
                input.style = 'position: absolute; left: -1000px; top: -1000px';
                input.value = this.token;
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, 99999); 
                document.execCommand('copy')
                document.body.removeChild(input)
            }           
            const options = {
                message: "Token copied to clipboard.",
                type: "info"
            }
            this.$store.dispatch('addToast', options);        
            
        }
    },
    beforeMount(){
        this.fields = JSON.parse(JSON.stringify(Fields))
        //if GenerateToken modal called from Tokens, then show email field is editable
        if (this.$route.path=="/admin/tokens"){
            this.fields.map((field)=>{
                if(field.name =="email"){
                    field.editable = true
                }
                if(this.$store.getters.userRoles.isClientAdmin){
                    if(field.name == "cids"){
                            field.editable = false
                            field.defaultValue = [this.$store.state.currentClient.cid]
                            this.user["cids"]=field.defaultValue
                    }
                    if(field.name == "roles"){
                        field.options=["client_admin","client_user"]
                    }
                }
            })
        }
    },
    
};
</script>

<style>
</style>