<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">cURL Command</h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col" >
                  <CodemirrorEditor 
                    editorId="copyCurl" 
                    :lineWrapping="true"
                    v-model="curlCommand"  mode="text/x-sh, application/x-sh" class="form-control" :disabled="true" :readOnly="true"></CodemirrorEditor> 
                   <!--  Here curl command is set so perform copy operation -->
                    <input type="hidden" v-on:focus="$event.target.select()" 
                    class="copy"  v-model="curlCommand"/>
            </div>         
            </div> 
        </div>
        <div class="card-footer text-end">
            <button @click="copy" :class="copyText=='Copy'? 'btn btn-primary':'btn btn-success'" >{{ copyText }}</button
            >&nbsp; 
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button
            >&nbsp;          
        </div>
    </div>   
</template>

<script>

import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
export default {
    name: "CopyCurl",
    components:{ CodemirrorEditor },
    data() {
        return {
            curlCommand: this.modalData.curl,
            copyText:"Copy",
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        }
    },
    methods:{
        copy() {
            //select the text in input field and perform copy
            let codeToCopy = document.querySelector('.copy')
            codeToCopy.setAttribute('type', 'text')
            codeToCopy.select()
            document.execCommand('copy');
            codeToCopy.setAttribute('type', 'hidden')
            this.copyText = "Copied!"
        }
    },

}
</script>

<style>
</style>