<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddInitiator()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Initiator
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <FormFields  :fields="fields" v-model="initiator" />
        </div>
        <div class="card-footer">
            <div class="text-end">
                <SubmitButton :fields="fields" v-model="initiator" :loading="loading" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import FormFields from '../FormFields.vue';
import SubmitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/initiatorCreateFields";
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { FormFields, SubmitButton },
    data(){
        return {
            initiator: {},            
            fields: Fields,
            loading: false,
            logo: null
        }
    },
    name: "AddInitiator",  
    watch: {
        'initiator.logo': {
            handler:function(newval){
                if(newval)
                    this.convertImgToBase64(newval)
                else this.logo = null
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('aut', {
            createInitiator: 'createInitiator'
        }),
        async onAddInitiator() {
            this.loading = true;
            this.initiator.logo =this.logo;
            let valid = true;
            let validValue = true;
            let toastoptions;
            try {
                valid = !isDuplicate(this.initiator.name,this.$store.state.aut.initiators, "name", null)
                validValue = !isDuplicate(this.initiator.value,this.$store.state.aut.initiators, "value", null)
                if( valid && validValue){
                    await this.createInitiator(this.initiator)
                    toastoptions = {
                        message: "Inititator '"+this.initiator.name+"' added successfully.",
                        type: "success",
                    };
                }else{
                    if(!valid){
                        toastoptions = {
                            message: "Already '"+this.initiator.name+"' name exits.'",
                            type: "error",
                        };
                    }else{
                        toastoptions = {
                            message: "Already '"+this.initiator.value+"' value exits.'",
                            type: "error",
                        };
                    }
                }
            } catch (error) {                
                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
                return
            }

            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
            if(valid && validValue){
                this.$store.dispatch('hideModal')
                this.$store.dispatch('aut/fetchInitiators');
            }
        },
        convertImgToBase64(img){
            if(typeof img!=="string"){
                const reader = new FileReader();
                reader.onloadend = () => {
                this.logo = reader.result;
                }
                reader.readAsDataURL(img);
            }else {
                this.logo = img;
            }
        }
    }
};
</script>

<style>
</style>