<template>
    <basic-header
        pretitle="aqfer Universal Tag"
        title="Initiator Fields"
        buttonText="Add Initiator Field"
        :buttonRoles= "btnRoles"
        @btnClick="showModal({ component: 'AddInitiatorField' })"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="initiatorList"
                :columns="columns"
                :data="$store.state.aut.initiatorFields"
                @deleteRows="showDeleteInitiatorFields"
                :deletable="deletable"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'initiator-fields/', name:'Initiator Field', identifier:'id'}"
                :dropDownFilterColumn=dropDownFilterColumn
            />
        </div> 
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                {
						data: "name",
						title: "Name",
						render: function (data, type, row) {
							if (!row.description || row?.description=="") return data;
                            return `<span class="datatable-title">${data}</span><span   v-if="${row.description!==''}" class="fa fa-circle-info mx-1 text-muted" data-bs-title="${row.description}"  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-container="body" data-bs-html="true"></span>`
							
						},
					},
                { data: "initiator", title: "Initiator" },
                { data: "input" },
                { data: "parameter"},
                { data: "rank" }
            ],
            btnRoles: [1],
            dropDownFilterColumn: [ 3 ]
        };
    },
    beforeMount() {
        this.$store.dispatch("aut/fetchInitiatorFields");
    },
    methods: {
        showDeleteInitiatorFields(rows) {
            this.showModal({ component: "DeleteInitiatorFields", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
    computed: {       
        deletable() {
            const allowedRoles = [1];
            return  (allowedRoles.includes(this.$store.getters.userRoles.userRoleRank));
        }

    }    
};
</script>

<style scoped>
</style>