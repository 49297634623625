<template>
	<template v-if="rows.length">
		<tr v-for="(row, rowIndex) in rows" :key="rowIndex">
			<template
				v-for="(column, columnIndex) in orderedColumns"
				:key="`${columnIndex}`"
			>
				<td
					v-if="!rowIndex && column.data === groupBy[columnIndex]"
					v-show="showGroupTd(groupKeys, columnIndex)"
					@click="
						toggleGroup(
							joinUpToIndex(groupKeys, columnIndex),
							groupKeys[columnIndex]
						)
					"
					:rowspan="groupData.rowLength"
					class="group-col"
				>
					<span class="group-toggle"
						>{{
							collapsedGroups[
								joinUpToIndex(groupKeys, columnIndex)
							]
								? "+"
								: "-"
						}}
						{{ groupKeys[columnIndex] }}</span
					>
				</td>

				<td
					:class="
						columnIndex == orderedColumns.length - 1
							? 'sum-col'
							: ''
					"
					v-else-if="
						(!rowIndex && columnIndex >= groupBy.length) ||
						(rowIndex && columnIndex >= groupBy.length)
					"
					v-show="showGroupTd(groupKeys, columnIndex)"
				>
					{{ row[column.data] }}
				</td>

				<td
					class="sum-col"
					:colspan="
						orderedColumns.length -
						currentGroupStr.split(',').length
					"
					v-if="
						!rowIndex &&
						columnIndex == orderedColumns.length - 1 &&
						getGroupSum(groupKeys)
					"
				>
					<span>{{ getGroupSum(groupKeys) }}</span>
				</td>
			</template>
		</tr>
	</template>
	<template v-else>
		<template v-for="(groupData, groupIndex) in groupedData">
			<tr
				v-for="(row, rowIndex) in groupData.rows"
				:key="`${groupIndex}-${rowIndex}`"
			>
				<template
					v-for="(column, columnIndex) in orderedColumns"
					:key="`${columnIndex}`"
				>
					<!-- group key col td's -->
					<td
						v-if="!rowIndex && column.data === groupBy[columnIndex]"
						v-show="
							(columnIndex >= groupData.level ||
								(columnIndex == 0 &&
									columnIndex == groupData.index)) &&
							showGroupTd(groupData.keys, columnIndex)
						"
						@click="
							toggleGroup(
								joinUpToIndex(groupData.keys, columnIndex),
								groupData.keys[columnIndex]
							)
						"
						:rowspan="getRowSpan(groupData.keys, columnIndex)"
						:colspan="getSumColSpan(groupData.keys, columnIndex)"
						class="group-col"
					>
						<span class="group-toggle me-1"
							>{{
								collapsedGroups[
									joinUpToIndex(groupData.keys, columnIndex)
								]
									? "+"
									: "-"
							}}
						</span>
						{{ groupData.keys[columnIndex] }}
					</td>
					<!-- Non group col td's -->
					<td
						v-else-if="columnIndex >= groupByColumns.length"
						:class="
							columnIndex == orderedColumns.length - 1
								? 'sum-col'
								: ''
						"
						v-show="showGroupTd(groupData.keys, columnIndex)"
					>
						{{ row[column.data] }}
					</td>
					<!-- //Aggregate col -->
					<td
						class="aggrgate sum-col"
						v-if="
							!rowIndex &&
							columnIndex == orderedColumns.length - 1 &&
							showAggregateCol(
								groupData.keys,
								groupData.level,
								groupData.index
							)
						"
					>
						<span>{{ getGroupSum(groupData.keys) }}</span>
					</td>
				</template>
			</tr>
		</template>
	</template>
</template>

<script>
	import { computed } from "vue";

	export default {
		props: {
			rows: Array,
			subGroup: Map,
			orderedColumns: Array,
			groupBy: Array,
			collapsedGroups: Object,
			groupKey: String,
			groupKeys: Array,
			sumByKey: Object,
			rowCountByKey: Object,
			groupIndex: Number,
		},
		emits: ["update:collapsedGroups"],
		setup(props, { emit }) {
			const groupedData = computed(() => {
				const groups = [];
				const level = 1;
				// Create a stack by mapping over the entries of the `props.subGroup` object
				// Each entry is transformed into an object
				const stack = [...props.subGroup.entries()].map(
					([key, groupData], index) => ({
						key,
						...groupData,
						level: level,
						index: index,
					})
				);
				let subLevel = 2;

				// Loop until the stack is empty
				while (stack.length > 0) {
					const { key, subGroups, level, index, ...rest } =
						stack.shift();

					const group = {
						key,
						level,
						index,
						...rest,
						subGroups: [...subGroups.entries()],
					};
					// If the group has subgroups
					if (group.subGroups.length > 0) {
						stack.push(
							...group.subGroups.map(
								([subKey, subGroupData], subIndex) => ({
									key: subKey,
									...subGroupData,
									level:
										level + subIndex < subLevel
											? level + subIndex
											: subLevel,
									index: subIndex + group.index,
								})
							)
						);
						delete group.subGroups;
					}

					if (group.rows && group.rows.length) {
						groups.push(group);
					}
				}
				return groups;
			});

			return {
				groupedData,
				currentGroupStr: null,
				groupByColumns: props.orderedColumns.slice(
					0,
					props.groupBy.length
				),
				otherColumns: props.orderedColumns.slice(
					props.groupBy.length + 1
				),
				renderedKeys: {},
			};
		},
		methods: {
			getGroupSum(groupKeys) {
				for (let i = 0; i < groupKeys.length; i++) {
					let keyStr = this.joinUpToIndex(groupKeys, i);
					if (this.collapsedGroups[keyStr]) {
						return this.sumByKey[keyStr];
					}
				}
				return null;
			},
			getSumColSpan(groupKeys, columnIndex) {
				let colGroupStr = this.joinUpToIndex(groupKeys, columnIndex);
				if (this.collapsedGroups[colGroupStr]) {
					return this.orderedColumns.length - 1 - columnIndex;
				}
				return null;
			},
			getRowSpan(groupKeys, columnIndex) {
				let colGroupStr = this.joinUpToIndex(groupKeys, columnIndex);
				let thisColumnsRowSpan = this.rowCountByKey[colGroupStr];
				if (thisColumnsRowSpan == 1) return thisColumnsRowSpan;

				if (this.collapsedGroups[colGroupStr]) {
					return 1;
				} else if (
					columnIndex + 1 <= groupKeys.length &&
					this.collapsedGroups[
						this.joinUpToIndex(groupKeys, columnIndex + 1)
					]
				) {
					let nextColumnRowSpan =
						this.rowCountByKey[
							this.joinUpToIndex(groupKeys, columnIndex + 1)
						];
					let colspanDiff = thisColumnsRowSpan - nextColumnRowSpan;
					return colspanDiff ? thisColumnsRowSpan : 1;
				}

				return thisColumnsRowSpan;
			},
			showAggregateCol(keyArr, groupLevel, groupIndex) {
				for (let i = 0; i < keyArr.length; i++) {
					let keyStr = this.joinUpToIndex(keyArr, i);
					if (this.collapsedGroups[keyStr]) {
						if (!groupIndex) {
							return true;
						} else if (
							groupLevel < i ||
							(groupIndex && groupLevel == i)
						) {
							return true;
						} else {
							return false;
						}
					}
				}
				return false;
			},
			showGroupTd(keyArr, columnIndex) {
				if (columnIndex >= keyArr.length) {
					columnIndex == keyArr.length;
				}
				for (let i = columnIndex - 1; i >= 0; i--) {
					if (this.collapsedGroups[this.joinUpToIndex(keyArr, i)])
						return false;
				}

				// If no parent group is found in collapsedGroups, show the group by default
				return true;
			},
			toggleGroup(groupKey, colName) {
				if (!this.collapsedGroups[groupKey])
					this.currentGroupStr = groupKey;

				this.$emit("update:collapsedGroups", groupKey);
			},
			joinUpToIndex(arr, index) {
				if (index >= arr.length) {
					index = arr.length - 1;
				}
				return arr.slice(0, index + 1).join(",");
			},
		},
	};
</script>
<style scoped>
	.invisible {
		visibility: hidden;
	}
	.collapsed {
		font-weight: bold;
	}
	.group-col {
		vertical-align: top;
	}
	.sum-col {
		text-align: end;
	}
	.group-toggle {
		padding: 0px 3px;
		border: 1px solid #ccc;
		font-weight: bold;
		cursor: pointer;
		font-size: 0.6rem;
		color: #95aac9;
	}
</style>
