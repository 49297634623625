export default {

    // data should be an array of objects
    exportCSV: (filename, data) => {
        let csvContent = "";

        let columns = Object.keys(data[0])
        columns = columns.join(",");
        csvContent += columns + "\r\n";
        
        data.forEach(function(object) {
            let values = Object.values(object)
            //handle arrays and embedded special chars like commas
            for(let i=0; i<values.length; i++) {
                if (!values[i])
                    continue;
                if (Array.isArray(values[i])) 
                    values[i] = values[i].join(",");
                if (typeof values[i] == "string" && values[i].indexOf(',') > -1)
                    values[i] = '"' + values[i].replace(/"/g, '\\"') + '"'
            }
            let row = values.join(",");
            csvContent += row + "\r\n";
        });

       
        var link = document.createElement("a");
        link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvContent));
        link.setAttribute("download", filename+".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
        link.remove();
    }

}