<template>
    <unit-test-job :jobType="jobType" :activeTab="activeTab" v-on:tabChange="tabChange" :iframeSrc="iframeSrc" :advancedProps="advancedProps" v-model="yamlContent"></unit-test-job>
</template>

<script>
import UnitTestJob from './UnitTestJob.vue';

export default {
    components: { UnitTestJob },
    data() { 
        return {
            jobType: "Mapper",
            activeTab: this.$store.state.sandboxUnitTest.mapperActiveTab,
            yamlContent: this.$store.state.sandboxUnitTest.mapperYamlList,
            advancedProps: this.$store.state.sandboxUnitTest.mapperAdvancedProps,
            iframeSrc: `${this.$store.state.docsUrl}/docs/amdp/services/data-integration-service/mapper/examples.html`      
        };
    },    
    watch: {        
        'yamlContent.jobconf': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setMapperJobConfigYaml", newVal);
            },
        },
        'yamlContent.dataChannels': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setMapperDataChannelsYaml", newVal);
            },
        },
        'yamlContent.mappings': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setMapperMappingsYaml", newVal);
            },
        },
        'yamlContent.credentails': {
            handler (newVal) {
                this.$store.dispatch("sandboxUnitTest/setMapperCredentialsYaml", newVal);
            },
        },
    },
    methods: {
        tabChange(tab) {
            this.$store.dispatch("sandboxUnitTest/setMapperActiveTab", tab);
        },
    }
};
</script>

<style scoped>
</style>