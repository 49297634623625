<template>
    <div class="main-content scrollbar-container">
        <div v-if="this.$store.state.cidLoading">
            <loader></loader>
        </div>        
        <div v-else>
            <div v-if ="this.$store.state.restrictedAccess">            
                <RestrictedAccess v-if="$store.state.restrictedAccess"/>
            </div>
            <router-view v-else :key="key" ></router-view>
        </div>
    </div>
</template>

<script>
import Loader from './Loader.vue';
import RestrictedAccess from './RestrictedAccess'
export default {
    components: { Loader , RestrictedAccess},
    name: "MainContentArea",
    data() {
        return {
            errMsg: "An Error Occured! Please try again later!"
        }
    },
    computed: {
        key() {
            if(this.$route.matched && this.$route.matched[1]) {
                return `${this.$route.matched[1]}-${this.$route.params.cid}-${this.$route.params.sandboxId}`;
            }
            return this.$route.path;
        }
    },    
    errorCaptured: function (err, vm, info) {
        console.log(err);
        const options = {
            message: this.errMsg,
            type: "error",
            config: {
                id: 'route-render',
                timeout: false,
                position: "top-center",
                closeOnClick: true,
            }
        }
        this.$store.dispatch('addToast', options);
        return false;
    }
};
</script>

<style>

</style>