const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name field is required" },
            { type: "regex", pattern: "^[a-zA-Z0-9-]+$",
              errMsg: "Entitlement name may contain only alphabets or numbers" 
            }
        ]
    },
    {
        name:"metrics_key",
        label: "Metrics key",
        type: "text",
        editable: true,
        validation: [
            { type: "regex", pattern: "^[a-zA-Z0-9-]+$",
              errMsg: "Metrics key may contain only alphabets or numbers" 
            }
        ]
    },
    {
        name: "unit",
        label: "Unit",
        type: "select",
        editable: true,
        options:[
            { label: "Each", value: "each" },
            { label: "Credits", value: "credits" },
            { label: "Units", value: "units" },
            { label: "GB", value: "GB" },
            { label: "TB", value: "TB" },
            { label: "Million", value: "million" },
            { label: "Billion", value: "billion" }
        ],
        validation: [
            { type: "required", errMsg: "Unit field is required" }
        ]
    },
    {
        name: "quantity",
        label: "Quantity",
        type: "number",
        editable: true,
        validation: [
            { type: "required", errMsg: "Quantity field is required" },
            { type: "minMax", minValue:0, maxValue:1000 }
        ]
       
    },
    {
        name: "aggregation",
        label: "Aggregation",
        type: "select",
        editable: true,
        options:[
            { label: "Average", value: "average" },
            { label: "Max", value: "max" },
            { label: "Sum", value: "sum" }
        ]
       
    },
    {
        name: "multiple_allowed",
        label: "Multiple Allowed",
        type: "select",
        editable: true,
        options:[
            { label: "Yes", value: "Y" },
            { label: "No", value: "N" },
        ]
       
    },
    {
        name: "base_entitlement_id",
        label: "Base Entitlement",
        type: "select",
        editable: true,
        options:[]
       
    },
    
    {
        name: "base_qty_consumed",
        label: "Base Quantity Consumed",
        type: "number",
        editable: true,
        validation: [               
            { type: "minMax", minValue:0, maxValue:1000 }
        ],
    },
    {
        name: "conversion_floor",
        label: "Conversion Floor",
        type: "number",
        editable: true,
        validation: [               
            { type: "minMax", minValue:0, maxValue:1000 }
        ],
       
    },
    {
        name: "qty_included_w_base",
        label: "Quantity included base",
        type: "number",
        editable: true,
        validation: [               
            { type: "minMax", minValue:0, maxValue:1000 }
        ],
    },
    {
        name: "description",
        label: "Description",
        type: "textarea",
        editable: true,
       
    },
    {
        name: "created_by",
        label: "Created By",
        type: "text",
        editable: false,
    },
    {
        name: "created_at",
        label: "Created At",
        type: "text",
        editable: false,
        format:"datetime"
    },
    {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        editable: false,
    },
    {
        name: "updated_at",
        label: "Updated At",
        type: "text",
        editable: false,
        format:"datetime"
    }
];

export default Fields;