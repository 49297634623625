<template>
    <div class="datatable-outer-container">
        <table :id="tableId" :class="['table table-hover table-responsive','card-table', compact ? 'compact' : '',]" style="width: 100%">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.index">
                        {{ column.data }}
                    </th>
                    <th style="text-align:center">
                        <span @click="onAddBtnClick()" class="btn btn-outline-primary p-2"> <span
                                class="fa fa-plus"></span></span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item, itemkey in data" :key="item.index">
                    <td class="editField" v-for="(column, key) in columns" :key="column.index">
                        <select v-if="this.fields[key]['type'] == 'select'" class="form-select"
                            @change="onSelectParm($event, itemkey)" v-model="item[column.data]">
                            <option v-for="option in this.fields[key]['options']" :key="option.index"
                                :value="option.value">
                                {{ option.label }}
                            </option>
                        </select>
                        <InputField v-else :type="this.fields[key]['type']" v-model="item[column.data]"
                            :showLabel="false" :editable="this.fields[key]['editable']" />
                    </td>
                    <td style="text-align:center">
                        <span @click="onDeleteBtnClick(itemkey)" class="btn p-2"> <span class="icon"> <i
                                    class="fa fa-regular fa-trash-can"></i></span></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import DataTable from "datatables.net";
import InputField from "../../components/InputField.vue";
import "datatables.net-bs5";
import "datatables.net-select-bs5";
import "datatables.net-buttons-bs5";

//suppress 'alert' errors
DataTable.ext.errMode = "console";
//pagination buttons to max of 5
DataTable.ext.pager.numbers_length = 5;
export default {
    name: "EditableTable",
    components: { InputField },
    props: {
        columns: Array,
        data: Array,
        fields: Array,
        tableId: {
            type: String,
            default: "datatable",
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onAddBtnClick() {
            this.$emit('addBtnClick')
        },
        onDeleteBtnClick(key) {
            this.$emit('deleteBtnClick', key)
        },
        onSelectParm(event, key) {
            this.$emit('selectBtnChange', event.target.value, key)
        }

    },
    emits: [
        'addBtnClick',
        'deleteBtnClick',
        'addBtnclick',
        'selectBtnChange'

    ],
}
</script>

<style scoped>
.editField>>>.form-group {
    margin-bottom: 0px;
}

.card-table>>>.editField {
    padding-left: 12px !important;
}
</style>

