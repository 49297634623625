<template>
    <div class="row justify-content-center mb-5">
        <div v-for="(tile, index) in tiles" :key="index" class="card col-md-4 m-2 card-tile" style="width: 12rem;" @click="tileClick($event, tile.value)" :class="[currentTile == tile.value ? 'active' : '']">
            <span v-show="currentTile == tile.value" :class="(currentTile == tile.value)?'badge':''"><i class="fa fa-check"></i></span>
            <div class="card-body">
                <div v-if="tile.iconSrc" class="card-img"><img  :src="tile.iconSrc.includes('base64')?tile.iconSrc:getURL(tile.iconSrc)"  :alt="tile.title" /></div>
                <h4 class="card-title text-center">{{tile.title}}</h4>
                <h5 class="card-subtitle mb-2 text-muted">{{tile.subTitle}}</h5>
            </div>
        </div>
    </div>

  
</template>

<script>
export default {  
    data() {
        return {
            currentTile: this.selectedTile
        }
    },  
    props: {       
        selectedTile: [String,Boolean],
        tiles: Array,
    },
    watch: {
        selectedTile(val) {
            this.currentTile = val
        }
    },

    methods: {
        tileClick(event, val) {
            let tile = event.currentTarget;
            tile.classList.toggle('active');
            if(tile.classList.contains("active")){
                this.$emit('onSelectTile', val);                               
            } else {
                this.$emit('onSelectTile', null);                 
            }      
        },
        getURL(assetName) {
            return require("../assets/"+assetName)
        }
    },
   
}
</script>

<style scoped>
    .card-tile:hover{
        box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        cursor: pointer;
        background-color: #2C7BE5;
        background-image: radial-gradient(#2e77dc, #2C7BE5);
        color: #ffff;
    }
    .card-tile.active{
        box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        cursor: pointer;
        background-color: #2C7BE5;
        background-image: radial-gradient(#2e77dc, #2C7BE5);
        color: #ffff;
    }
    .badge{
        position: absolute;
        color: #003B67;
        background-color: #ffffff;
        box-shadow: inset 0px 0px 6px 6px #E0E0E0;
        font-weight: bold;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        padding: 5px;
        font-size: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.5px solid #ffffff;
        top: -10px;
        left: -10px;
        z-index: 2;
    }

    .card-img {
        text-align: center !important; 
        padding-bottom: 1.5rem;   
    }
    .card-img img {
        height: 30px;
        width: 30px;
    }
</style>