<template>
    <BasicHeader
        pretitle="Administration"
        title="Encrypted URLs"
        buttonText="Add EncryptedUrl"
        @btnClick="showModal({ component: 'AddEncryptedURL' })"
    />
    <div class="container-fluid">
        <div class="card">
            <Datatable
                tableId="encryptedUrlsTable"
                :columns="columns"
                :data="$store.state.admin.encryptedUrlList"
                :deletable="true"
                @deleteRows="showDeleteEncryptedUrls"
                :compact="true"
                :select="true"
                :formatColumn="formatColumn"
                :orderColumn="orderColumn"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'encrypted-urls/', name:'EncryptedURL', identifier:'id'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";

export default {
    name: "EncryptedUrls",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "tag" },
                { data: "status" },
                { data: "created_at",title: "Created At" },
                { data: "created_by",title: "Created By" },
            ],
            formatColumn:[
                {
                    index: 4,
                    type: "datetime"
                }
            ],
            orderColumn: [4, "desc"]
        };
    },
    beforeMount() {
        this.$store.dispatch("admin/fetchEncryptedUrlList");
    },
    methods: {
        showDeleteEncryptedUrls(rows) {
            this.showModal({ component: "DeleteEncryptedURLs", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
};
</script>

<style>
</style>