<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddQuery()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Named Query
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body codemirror">
                <form-fields v-if="!message" :fields="fields" v-model="query" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
            </div>
            <div class="card-footer" v-if="!message">
                <div class="text-end">
                    <submit-button :fields="fields" v-model="query" :loading="loading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import { mapActions } from 'vuex'
import Fields from "../../utils/field-constants/queryFields";
import { isDuplicate } from '../../utils/commonFunction';
export default {
    name: "AddQuery",
    components: { formFields, submitButton },
    data() {
        return {
            error: "",
            message: "",
            query: {
                Name: "",
                Description: "",
                QuertString: "",
                Internal: false,
                Params: []
            },
            fields: Fields,
            roles: [1],
            loading: false
        }
    },
    methods: {
        ...mapActions('amdp', {
            createQuery: 'createQuery'
        }),
        async onAddQuery() {
            try{
                this.loading = true;
                this.error = "";
                //Validate query name already exists
                if (!isDuplicate(this.query.Name, this.$store.state.amdp.queries, "Name", null)) {
                    //Internal field will be visible to aqfer admin user
                    if (this.fields[3].visible == false && this.isDDLQuery(this.query.QueryString)) {
                        this.error = "CREATE|ALTER|DROP named querries can't be created. Please contact support."
                    } else {
                        try {
                            this.query.Owner = this.query.Internal ? 'SYSTEM' : 'USER';
                            await this.createQuery(this.query);
                            this.message = "Successfully created new Query " + this.query.Name + ".";
                        } catch (error) {
                            this.error = "Failed to create Query " + this.query.Name + ". Please contact support.";
                            return
                        }
                        this.$store.dispatch('amdp/fetchQueries')
                    }
                } else {
                    this.error = "Already '" + this.query.Name + "' Query name exits.";
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else if (error.response.data.message) 
                    this.error = error.response.data.message
                else 
                    this.error =  error.message
            }
        },
        isDDLQuery(qs) {
            qs = qs.replace(/[\n\r\s]/g, '').toLowerCase()
            if (qs.startsWith("alter") || qs.startsWith("create") || qs.startsWith("drop")) {
                return true
            }
            return false
        }
    },
    computed: {
        userRoleRank() {
            return this.$store.getters.userRoles.userRoleRank;
        }
    },
    beforeMount() {
        if (this.roles.includes(this.userRoleRank)) {
            //internal field is displayed only for aqfer admin
            this.fields[3].visible = true;
        }
    }
};
</script>

<style scoped>
.codemirror >>>.CodeMirror-line {
  padding-left: 32px;
}
.codemirror >>>.CodeMirror-gutters{
  width: 30px;
}
</style>