<template>
	<div class="row position-relative">
		<div
			v-for="(field, index) in fields"
			:key="index"
			:class="[
				field.type == 'array' || colsNumber == 2
					? 'col-12'
					: 'col-lg-6  col-xxl-4 ',
			]"
			class="my-2"
		>
			<div v-if="field.type === 'array'">
				<div class="card mb-2">
					<div class="card-header" style="height: 40px">
						<label
							><strong>{{ field.label }}</strong></label
						>
					</div>
					<div
						v-for="(arrayField, arrayIndex) in yamlObj[field.name]"
						:key="arrayIndex"
						class="sub-array-item"
					>
						<div
							class="card-body array-item-body px-3 py-0"
							v-if="arrayField"
							style="background-color: #f8f9fa"
						>
							<button
								type="button"
								class="btn btn-sm btn-secondary float-end mt-3"
								@click="deleteArrayItem(field, arrayIndex)"
								:class="{ invisible: !editMode }"
							>
								<i class="fa fa-trash"></i>
							</button>
							<!--Recursive component-->
							<recursive-input-field
								:fields="field.arrayItems"
								:modelValue="yamlObj[field.name][arrayIndex]"
								:parentObj="yamlObj"
								:parentField="field.name"
								:parentIndex="arrayIndex"
								@update:modelValue="onUpdateParsedYaml"
								:editMode="editMode"
							></recursive-input-field>
						</div>
					</div>
					<div class="card-footer">
						<button
							v-if="editMode"
							type="button"
							class="btn btn-sm btn-secondary float-end"
							@click="addArrayItem(field)"
						>
							<span v-if="field.name=='extract'">+ Add {{ removeLastS('Edge') }}</span>
							<span v-else>+ Add {{ removeLastS(field.label) }} </span>
						</button>
					</div>
				</div>
			</div>
			<div v-else>
				<div v-if="!autYaml || (autYaml && !field.autHide)">
					<div
						class="row mx-n1"
						style="border: 2px solid rgb(237, 242, 249)"
					>
						<div
							class="row mx-0"
							style="background-color: rgb(237, 242, 249)"
						>   
							<div class="col my-2 px-0 align-self-center">
								<strong>{{ field.label }}</strong>
							</div>
							<div class="col my-2 px-0 align-self-center">								
								<div
									class="btn-group float-end"
									v-if="editMode && getOptions(field).length"
								>
									<button
										type="button"
										class="btn-sm prop-btn dropdown-toggle"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<i class="fa fa-plus mx-1"></i>
									</button>
									<ul class="dropdown-menu">
										<li
											v-for="option in getOptions(field)"
											:key="option.value"
											@click="
												addProperty(
													field,
													option.value,
													index
												)
											"
											:value="option.value"
										>
											<a class="dropdown-item" href="#">{{
												option.label
											}}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="row mx-0" style="background-color: white">							
							<div
								class="col-12 my-2 px-0"
								v-for="(value, key, subIndex) in yamlObj[
									field.name
								]"
								:key="subIndex"
							>
								<div
									class="input-group"
									v-if="field.type.includes(key)"
								>
									<select
										class="w-25 form-select"
										:value="key"
										@change="
											updateSelect(
												field,
												$event.target.value
											)
										"
										:disabled="!editMode"
										style="max-width: 120px !important"
									>
										<option
											v-for="option in field.type"
											:key="option"
											:value="option"
										>
											{{ option }}
										</option>
									</select>
									<input
										v-if="key == 'input' || key == 'const'"
										type="text"
										class="form-control w-50"
										:value="
											key == 'input'
												? value.source
												: value.value
										"
										@input="
											update(
												field,
												key,
												$event.target.value
											)
										"
										:disabled="!editMode"
									/>
									<input
										v-if="key == 'list'"
										type="text"
										class="form-control w-50"
										:value="value"
										@input="
											update(
												field,
												key,
												$event.target.value
											)
										"
										:disabled="!editMode"
									/>
									<!--Recursive component-->
									<recursive-input-field
										v-if="key == 'compound'"
										:fields="compoundFields"
										:modelValue="
											yamlObj[field.name]['compound']
										"
										:parentObj="yamlObj"
										colsNumber="2"
										:parentField="field.name"
										:parentIndex="key"
										@update:modelValue="onUpdateParsedYaml"
										:editMode="editMode"
									></recursive-input-field>
									<!-- <button type="button" class="btn btn-sm prop-btn"
										@click="deleteProperty(field, key)" v-if="editMode && !mapTypeKeys.includes(key)">
										<strong>-</strong>
									</button> -->
									<!--Recursive component-->
									<button
											type="button"
											class="btn btn-sm prop-btn"
											@click="deleteProperty(field, key)"
											v-if="editMode && ((field.optional) || (autYaml && field.autOptional))"
										>
											<strong>-</strong>
										</button>
								</div>								
								<div v-else-if="key == 'invalid_values'">
									<div class="input-group">
										<label
											class="
												input-group-text
												input-group-text2
											"
											style="width: 120px"
											>Invalid Values</label
										>
										<input
											type="text"
											class="form-control w-50 code-font"
											:value="
												JSON.stringify(
													yamlObj[field.name][
														'invalid_values'
													],
													null,
													4
												)
											"
											@change="
												update(
													field,
													key,
													$event.target.value
												)
											"
											:disabled="!editMode"
										/>
										<button
											type="button"
											class="btn btn-sm prop-btn"
											@click="deleteProperty(field, key)"
											v-if="editMode"
										>
											<strong>-</strong>
										</button>
									</div>
									<div class="form-text alert-danger ps-1"  v-if="
										this.errorObj[
											`${field.name}-${parentIndex}`
										]
									">{{this.errorObj[
											`${field.name}-${parentIndex}`
										]}}
									</div>
								</div>
								<div v-else-if="key == 'map_value'">
									<div class="input-group">										
										<label
											class="
												input-group-text
												input-group-text2
											"
											style="width: 120px !important"
											>Map Value</label
										>
										<input
											type="text"
											class="form-control w-50 code-font"
											:value="
												JSON.stringify(
													yamlObj[field.name][
														'map_value'
													]
												)
											"
											@change="
												update(
													field,
													key,
													$event.target.value
												)
											"
											:disabled="!editMode"
										/>

										<button
											type="button"
											class="btn btn-sm prop-btn"
											@click="deleteProperty(field, key)"
											v-if="editMode"
										>
											<strong>-</strong>
										</button>
									</div>
									<div class="form-text alert-danger ps-1"  v-if="
										this.errorObj[
											`${field.name}-${parentIndex}`
										]
									">{{this.errorObj[
											`${field.name}-${parentIndex}`
										]}}
									</div>
								</div>
								<div
									
									v-else-if="field.type[0]=='map'"
								>
									<div class="input-group">										
										<input
											type="text"
											class="form-control w-25 code-font"
											:value="key"
											@input="
												updateMapKey(
													field,
													key,
													$event.target.value
												)
											"
											:disabled="!editMode"
										/>
										<textarea
											type="text"											
											rows="1"
											class="form-control w-50 code-font"
											:value="
												JSON.stringify(
													yamlObj[field.name][
														key
													]
												)
											"
											@change="
												update(
													field,
													key,
													$event.target.value
												)
											"
											:disabled="!editMode"
										/>

										<button
											type="button"
											class="btn btn-sm prop-btn"
											@click="deleteProperty(field, key)"
											v-if="editMode"
										>
											<strong>-</strong>
										</button>
									</div>
									<div class="form-text alert-danger ps-1"  v-if="
										this.errorObj[
											`${field.name}-${parentIndex}`
										]
									">{{this.errorObj[
											`${field.name}-${parentIndex}`
										]}}
									</div>

								</div>
							</div>
							<div
								class="col-12"
								v-if="
									this.addNewPropFlagObj[
										`${field.name}-${index}`
									]
								"
							>
								<select
									class="form-select w-50 prop-btn btn-sm"
									v-if="
										editMode &&
										this.addNewPropFlagObj[
											`${field.name}-${index}`
										]
									"
									value="default"
									@input="
										addProperty(
											field,
											$event.target.value,
											index
										)
									"
								>
									<option selected value="default">
										Select Property To Add
									</option>
									<option
										v-for="option in getOptions(field)"
										:key="option.value"
										:value="option.value"
									>
										{{ option.label }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<!-- Delete properties with autHide false if yaml is aut type Ex entity_id and neighbour_id-->
				<div v-else>{{ deleteProperty(field, null) }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Fields from "../utils/field-constants/idrEdgeCompoundFields";
	export default {
		name: "recursive-input-field",
		data() {
			return {
				compoundFields: Fields,
				addNewPropFlagObj: {},
				errorObj: {},
				mapTypeKeys: ["const", "input", "compound", "list", "map"],				
			};
		},
		computed: {
			yamlObj() {
				return this.modelValue;
			},
			autYaml() {
				if(this.parentObj)
					return this.hasNestedKey(this.parentObj,"compound");
				else
					return this.hasNestedKey(this.modelValue,"compound");
			},
			mapPropKeys() {
				return this.autYaml? ["invalid_values"]: ["invalid_values", "map_value"];
			}
		},
		props: {
			fields: Array,
			modelValue: {
				type: Object,
				default: () => {},
			},
			editMode: {
				type: Boolean,
				default: true,
			},
			parentField: {
				type: String,
				default: null,
			},
			parentObj: {
				type: Object,
				default: null,
			},
			parentIndex: {
				type: Number,
				default: null,
			},
			colsNumber: {
				type: Number,
				default: 3,
			}
		},
		watch: {
			editMode(val) {
				this.errorObj = {};
			}
		},
		methods: {
			addArrayItem(field) {
				if(!this.yamlObj[field.name])
					this.yamlObj[field.name] = [];
				
				this.yamlObj[field.name].push({});
				this.updateModelValue(this.yamlObj);
			},
			deleteArrayItem(field, index) {
				this.yamlObj[field.name].splice(index, 1);
				this.updateModelValue(this.yamlObj);
			},
			updateMapKey(field, key, newKey) {
				let mapVal = this.yamlObj[field.name][key];
				this.yamlObj[field.name][newKey] = mapVal;

				delete this.yamlObj[field.name][key];

				this.updateModelValue(this.yamlObj);

			},
			updateSelect(field, value) {
				let self = this;

				let keyValues = Object.entries(this.yamlObj[field.name]);

				if (value == "const") {
					keyValues.splice(1, 0, ["const", { value: null }]);
					this.yamlObj[field.name] = Object.fromEntries(keyValues);
				} else if (value == "input") {
					keyValues.splice(1, 0, ["input", { source: null }]);
					this.yamlObj[field.name] = Object.fromEntries(keyValues);
				} else if (value == "list") {
					this.yamlObj[field.name][value] = [];
				} else if (value == "compound") {
					this.yamlObj[field.name][value] = {};
				}else if (value == "map") {
					this.yamlObj[field.name]['key'] = {};
				}
                
				Array.isArray(field.type) && field.type.forEach((key) => {
					if (key !== value) {
						delete self.yamlObj[field.name][key];
					}
				});
				this.updateModelValue(this.yamlObj);
			},
			update(field, type, value) {
				let name = field.name;
				if (type == "const") {
					this.yamlObj[name]["const"] = { value: value };
				} else if (type == "input") {
					this.yamlObj[name]["input"] = { source: value };
				} else if (type == "invalid_values" || type == "map_value"|| field.type=='map') {
					if(this.validateJSON(name,value))
						this.yamlObj[name][type]=JSON.parse(value);
					else
						return;
				} else if(type == "list"){
					this.yamlObj[name][type] = value.split(',');
				} else {
					this.yamlObj[name][type] = value;
				}

				this.updateModelValue(this.yamlObj);
				
			},
			updateModelValue(yamlObj) {
				if (this.parentObj) {
					let parentObj = this.parentObj;
					parentObj[this.parentField][this.parentIndex] = yamlObj;
					this.$emit("update:modelValue", parentObj);
				} else {
					this.$emit("update:modelValue", yamlObj);
				}
			},
			onUpdateParsedYaml(val) {
				this.updateModelValue(val);
			},
			fetchOptions(optionsArray) {
				let options = [];
				if (optionsArray && optionsArray.length) {
					optionsArray.map((option) => {
						options.push({ label: option, value: option });
					});
				}

				return options;
			},
			addNewProperty(field, index) {
				this.addNewPropFlagObj[`${field.name}-${index}`] = true;
			},
			getOptions(field) {
			//   If the type is const, invalid_values and map_value will not be supported in enterprise case. 
			//   In case of aut, map_values not supported for all the types and if the type is const, invalid_values is only supported only when wildcard is given('*').
				let fieldProps = this.yamlObj[field.name]
					? Object.keys(this.yamlObj[field.name])
					: [];
				let mapKeys =
					fieldProps.length &&
					field.type.filter((key) => fieldProps.includes(key)||key=='map')
						.length
						? this.mapPropKeys.filter(
								(key) => !fieldProps.includes(key) && (!fieldProps.includes("const") || this.yamlObj[field.name]["const"]["value"]=="*" )//If the type is const, invalid_values and map_value will not be supported
						)
						: field.type.concat(this.mapPropKeys);
						
				return this.fetchOptions(mapKeys);
			},
			addProperty(field, value, index) {
				let name = field.name;
				//this.addNewPropFlagObj[`${name}-${index}`] = false;
				if (!this.yamlObj[name]) this.yamlObj[name] = {};

				if (field.type.includes(value)) {
					this.updateSelect(field, value);
				} else {
					if (value == "invalid_values")
						this.yamlObj[name]["invalid_values"] = [];
					else if (value == "map_value")
						this.yamlObj[name]["map_value"] = {};

					this.updateModelValue(this.yamlObj);
				}
			},

			deleteProperty(field, key) {
				if(!key || (field.type).includes(key) && field.optional) {

					// Optional properties deletion
					delete this.yamlObj[field.name]
				} else {
					delete this.yamlObj[field.name][key];
				}
				this.updateModelValue(this.yamlObj);
			},			
			removeLastS(str) {
				if (str.endsWith("s")) {
					return str.slice(0, -1);
				}
				return str;
			},
			validateJSON(name,value) {
				try {
					JSON.parse(value);									
				} catch(e) {
					this.errorObj[`${name}-${this.parentIndex}`] = "Invalid input!";
					return false;
				}
				this.errorObj[`${name}-${this.parentIndex}`] = null;
				return true;
			},			
			hasNestedKey(obj, key) {
				if (typeof obj !== 'object' || obj === null) {
					return false; 
				}

				if (key in obj) {
					return true; 
				}

				// Recursively check nested objects
				for (const nestedKey in obj) {
					if (this.hasNestedKey(obj[nestedKey], key)) {
						return true;
					}
				}

				return false; 
			}

		},
		emits: ["update:modelValue", "change"],
	};
</script>

<style scoped>
	.invisible {
		visibility: hidden;
	}

	.code-font {
		font-family: monospace;
		padding: 10px;
		white-space: pre;
		overflow: scroll;
		width: 300px;
	}

	.prop-btn {
		background-color: rgba(128, 128, 128, 0.25019607843);
		border-color: rgba(128, 128, 128, 0.25019607843);
	}

	.position-relative {
		position: relative;
	}

	.sub-array-item .btn-link {
		color: gray;
	}

	.card .sub-array-item {
		position: relative;
	}

	.card .sub-array-item.card-body :not(:first-child) {
		margin-top: 2em;
	}

	.card .sub-array-item::before {
		position: absolute;
		left: 0px;
		top: 17px;
		border-left: 2px solid gray;
		border-bottom: 2px solid gray;
		content: "";
		width: 15px;
		height: 1em;
	}

	.card .array-item-body::after {
		position: absolute;
		left: 10px;
		top: 20px;
		content: "\25B6";
		color: gray;
		width: 8px;
		height: 1em;
	}

	.card .sub-array-item::after {
		position: absolute;
		left: -0px;
		bottom: 0px;
		border-left: 2px solid gray;
		content: "";
		width: 8px;
		height: 100%;
	}

	.input-group-text {
		background-color: #f8f9fa;
	}

	.input-group-text2 {
		background-color: #fff;
	}
</style>
