<template>
    <iframe class="full-screen" id="client-app" :src="iframeSrc"></iframe>  
</template>

<script>
export default {
    name: "Client Apps",
    data() {
        return {
            iframeSrc : ""
        }
    },
    props: ["appId"],
    computed: {       
        uri() {
            let self = this;
            const clientFeatures = this.$store.state.currentClient.config.features;
            return clientFeatures.filter(obj => {
                return (obj.uri && (obj.name == self.appId))
            })[0].uri;
        }
    },
    mounted() {
        if (this.$store.state.environment == 'test'|| this.$store.state.environment == 'dev') {
            this.iframeSrc = this.uri;
        } else {
            this.iframeSrc = this.uri + "?auth=" + this.$store.state.accessToken
            + "&pail_base_url=" + this.$store.state.pailUrl;
        }
        this.messageAuthTokenToClientApp(this.uri);  
    },
    beforeMount() {
        this.forceMobileView(true);  
    },
    beforeUnmount() {
        this.forceMobileView(false);
    },
    methods: {        
        messageAuthTokenToClientApp( url) {
            let self = this;
            let iframeWin = document.getElementById('client-app').contentWindow
            let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            let eventer = window[eventMethod];
            let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
            eventer(messageEvent,function(e) {
                if (e.origin === url){
                var token = { type: "auth-token", authBearerToken: self.$store.state.accessToken }
                iframeWin.postMessage(token, url);
                return;
                }
            },false);
        },
        forceMobileView(mobileView) {
            let sidebarDiv = document.getElementById('sidebar');
            let sidebarNav = document.getElementById('sidebarCollapse');
            let navbarBrandLogo = sidebarDiv.getElementsByClassName("navbar-brand-img")[0];
            let navbarAppName = sidebarDiv.getElementsByClassName("navbar-app-name")[0]; 

            if(mobileView) {
                sidebarNav.classList.remove('show');
                sidebarDiv.classList.remove('navbar-vertical');
                sidebarDiv.classList.remove('navbar-expand-md');
                sidebarDiv.classList.add('force-mobile-view');
                navbarBrandLogo.classList.add('d-none');
                navbarAppName.classList.remove('d-none');
            } else {
                sidebarDiv.classList.add('navbar-vertical');
                sidebarDiv.classList.add('navbar-expand-md');
                sidebarDiv.classList.remove('force-mobile-view');  
                navbarBrandLogo.classList.remove('d-none');   
                navbarAppName.classList.add('d-none');           
            }

        }   
    }
}
</script>

<style>
iframe {
    width:100%;
    height: 100vh;
}

.force-mobile-view .navbar-user.d-md-none {
    display: flex !important;

}
</style>