<template>
    <div class="row">
        <div class="col-3">
            <div class="card">
                <div class="card-body p-0">
                    <Datatable 
                    tableId="requests"
                    :columns="columns"
                    uniqueField="id"
                    :data="$store.state.aio.handlerRequests"
                    :selectedItem="$store.state.aio.currentRequest"
                    :lengthChange="false" :info="false"
                    :showLoading="false"
                    v-on:selected="handleSelect"
                    :compact="true"
                    />  
                </div>
                <div class="card-footer">
                    <span class="float-end">
                            <button type="button" @click="showModal({component:'AddHandlerRequest'})" class="btn btn-primary" ><i class='fa fa-plus' data-bs-toggle="tooltip" data-bs-placement="left"/>
                            </button>&nbsp;
                    </span>
                </div>
            </div>

        </div>
        <div class="col-9" v-if="currentRequest">
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group mb-3">
                        <select class="form-control form-select" style="max-width:100px;" v-model="currentRequest.method"><option value="GET">GET</option><option value="POST">POST</option><option value="PUT">PUT</option><option value="DELETE">DELETE</option></select>
                        <input class="form-control w-50" v-model="currentRequest.domain" type="text" placeholder="" disabled>
                        <input class="form-control w-25" v-model="currentRequest.path" type="text" placeholder="" @input="onChangePath">
                    </div>
                </div>
                <div class="col">
                    <div class="btn-group w-100" role="group" aria-label="Basic example">
                        <button
                        class="btn btn-primary d-block mb-4 w-100"
                        :disabled="btnDisable"
                        @click="sendRequest"
                    >
                        <div v-if="sending" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            Test
                        </div>
                    </button>
                    <button
                        class="btn btn-success d-block mb-4 w-100"
                        @click="onSaveClick"
                        :disabled="btnDisable"
                    >
                        <div v-if="saving" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            Save
                        </div>
                    </button>
                    <button
                        class="btn btn-danger d-block mb-4 w-100"
                        @click="onDeleteClick"
                    >
                        Delete 
                    </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 codeMirrorHeight" style="height: 200px">
                    <h4>Request Headers</h4>
                    <codemirror-editor
                        editorId="headers"
                        mode="javascript"
                        v-model="currentRequest.headers"
                        :readOnly="false"
                        :refreshOn="true"
                    />
                </div>
                <div class="col-md-6 codeMirrorHeight">
                    <h4>Payload</h4>
                    <codemirror-editor
                        editorId="payload"
                        mode="javascript"
                        v-model="currentRequest.payload"
                        :readOnly="false"
                        :refreshOn="true"
                    />
                </div>
            </div>
            <p><small><b>Note: </b>Appropriate 'Authorization' and 'X-Lambda-Id' headers will be sent automatically unless overwritten above.</small></p>
            <hr />

            <div class="row">
                <div v-if="sending" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="(response && (response.body || response.log)) || responseHeaders" class="col">
                    <Tabs :tabs="tabItems">
                        <template v-slot:response> 
                            <div v-if="response.body" class="col-12 codeMirrorHeight">
                                <pre><code class="wrap">{{ response.body }}</code></pre>
                            </div>
                        </template>
                        <template v-slot:header> 
                            <div v-if="responseHeaders" class="col-12 codeMirrorHeight">
                                <pre><code class="wrap">{{ responseHeaders }}</code></pre>
                            </div>
                        </template> 
                        <template v-slot:log>
                            <div v-if="response.log" class="col-12 codeMirrorHeight">
                                <pre><code class="wrap">{{ response.log }}</code></pre>
                            </div>
                        </template>
                    </Tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import CodemirrorEditor from "../../../components/CodemirrorEditor.vue";
import Datatable from '../../../components/Datatable.vue';
import Tabs from "../../../components/Tabs.vue";
export default {
    components: { CodemirrorEditor, Datatable, Tabs },

    data() {
        return {
            columns: [
                {
                    data: "name",
                    title: "Request Name",
                },
            ],
            sending: false, 
            saving: false,
            methodOptions: [
                { label: "GET", value: "GET" },
                { label: "POST", value: "post" },
                { label: "PUT", value: "put" },
            ],
            response: {},
            responseHeaders: "",
            btnDisable: false,
            tabItems: []
        };
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
            updateHandlerRequest: "aio/updateHandlerRequest",
            deleteHandlerRequest: "aio/deleteHandlerRequest",
            createHandlerRequest: 'aio/createHandlerRequest'
        }),
        handleSelect(data) {
            this.$store.dispatch("aio/setCurrentRequest", data);
        },
        async onSaveClick() {
            this.saving = true;
            const response = await this.updateHandlerRequest(this.currentRequest);
            if (response.status) {
                this.$store.dispatch("addToast", {
                    message: "Changes saved successfully.",
                    type: "success",
                });
            }
            this.saving = false;
        },
        onDeleteClick() {
            this.showModal({ component: "deleteHandlerRequests", data: [this.currentRequest] });
        },
        sendRequest() {
            this.sending = true;
            this.response = {};
            this.responseHeaders = "";
            this.tabItems=[];
            const options = {
                method: this.currentRequest.method,
                headers: JSON.parse(this.currentRequest.headers),
                url: this.currentRequest.domain+(this.currentRequest.path?this.currentRequest.path:""),
                data: JSON.parse(this.currentRequest.payload),
            };
            if (options.headers) { 
                if (!options.headers.Authorization) {
                    options.headers.Authorization = `Bearer ${this.$store.state.accessToken}`
                }
                if (!options.headers['x-lambda-id']){
                    const lambdaName = this.$store.state.aio.currentHandlerJob.name.toLowerCase();
                    options.headers['x-lambda-id'] = `${this.$store.state.currentClient.cid}-${lambdaName}`
                }
                if (!options.headers['X-Instance'])
                    options.headers["X-Instance"]="sandbox"
            }
            const promise = axios(options)
                .then((res) => {
                    if(res.headers){
                        if(res.headers["x-handler-logs"])
                            this.response.log ="\n"+atob(res.headers["x-handler-logs"])
                        this.responseHeaders = JSON.stringify(
                            res.headers,
                            4,
                            2
                        );
                    }  
                    if (res.data)
                        this.response.body = "\n"+JSON.stringify(res.data, 4, 2);              
                })
                .catch((error) => {
                    self.error = error.message;
                    this.response.log = "\nERROR: " + error.message;
                    if(error.response && error.response.headers){
                        if(error.response.headers["x-handler-logs"])
                            this.response.log +="\n"+atob(error.response.headers["x-handler-logs"])
                        this.responseHeaders = JSON.stringify(
                            error.response.headers,
                            4,
                            2
                        );
                    }
                    if (error.response && error.response.data)
                        this.response.body = "\n"+JSON.stringify(error.response.data, 4, 2);
                })
                .finally(() => {
                    this.sending = false;
                    if(this.response && this.response.body){
                        this.tabItems.push({
                            name: "Response",
                            id: "id1",
                            slot: "response"
                        })
                    }
                    if(this.responseHeaders){
                        this.tabItems.push({
                            name: "Response Headers",
                            id: "id2",
                            slot: "header"
                        })
                    }
                    if(this.response && this.response.log){
                        this.tabItems.push({
                            name: "Log",
                            id: "id3",
                            slot: "log"
                        })
                    }
                });
        },
        onChangePath(e){
            this.btnDisable = false;
            let value = e.target.value;
            if(value && value!=""){
                let regex=new RegExp('^/[a-zA-Z0-9]')
                if(!regex.test(value)){
                    this.btnDisable = true;
                }
            }
        }
    },
    computed:{
        myrequests() {
            return this.$store.state.aio.handlerRequests;
        },
        currentRequest() {
            return this.$store.state.aio.currentRequest;
        },
    },
};
</script>

<style scoped>
.codeMirrorHeight >>> .CodeMirror {
    height: 175px;
}
.wrap{
    text-wrap: wrap;
}
</style>