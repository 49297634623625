<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddTag()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Tag Template
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        
        <div class="card-body">
                <h5>
                    <a href="#" class="card-link link-primary" @click="openTemplateWizard()">Need more help? Check out our Tag Template Wizard!</a>
                </h5>
                <form-fields v-if="!message" :fields="fields" v-model="tagTemplate" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
        </div>
        <div class="card-footer" v-if="!message">
            <div class="text-end">
                <submit-button :fields="fields" v-model="tagTemplate" :loading="loading" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/tagFields";
import { tag_event_types } from '../../utils/constOptionValues';
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            message: "",
            tagTemplate: {
                owner: this.$store.state.currentUser.email,
                cid: this.$store.state.currentClient.cid,
            },            
            fields: Fields,
            subheader:"",
            loading:false,
            sub_events1:[]
        }
    },
    name: "AddTag",
    watch: {
        "tagTemplate.event_type": {
            handler: function(newVal) {
               let event_index= tag_event_types.findIndex((event)=> event.value==newVal)
               if(event_index!=-1){
                    this.sub_events1=tag_event_types[event_index].sub_events;
                    let index =this.fields.findIndex((field)=>field.name =="sub_event_type_1")
                    if(index!=-1){
                        this.fields.splice(index,1)
                    }
                    index =this.fields.findIndex((field)=>field.name =="sub_event_type_2")
                    if(index!=-1){
                        this.fields.splice(index,1)
                    }
                    this.tagTemplate.sub_event_type_1=''
                    this.tagTemplate.sub_event_type_2=''
                    if(this.sub_events1.length>0){
                        let subevent1={
                                name: "sub_event_type_1",
                                label: "Sub Event Type 1 ",
                                type: "select",
                                editable: true,
                                visible: true,
                                options: this.sub_events1,
                        };
                    this.fields.splice(2,0,subevent1)
                    }
               }

            }
        },
        "tagTemplate.sub_event_type_1": {
            handler: function(newVal) {
                if(this.sub_events1.length>0){
                    let event_index= this.sub_events1.findIndex((event)=> event.value==newVal)
                    if(event_index!=-1){
                        let sub_events2=this.sub_events1[event_index].sub_events
                        if(sub_events2 && sub_events2.length>0){
                            let subevent={
                                name: "sub_event_type_2",
                                label: "Sub Event Type 2",
                                type: "select",
                                editable: true,
                                visible: true,
                                options: sub_events2,
                            };
                        this.fields.splice(3,0,subevent)
                        }
                    }

                }
            }
        }
    },
    methods: {
        ...mapActions('aut', {
            createTagTemplate: 'createTagTemplate',
        }),
        ...mapActions({
            showModal:"showModal"
        }),
       
        async onAddTag() {
            this.loading = true;
            let toastoptions;
            try {
                this.tagTemplate.tag= this.tagTemplate.tag ? this.tagTemplate.tag.toString(): "";
                await this.createTagTemplate(this.tagTemplate)
                toastoptions = {
                    message: "Tag added successfully.",
                    type: "success"
                }
                
            } catch (error) {                
                // if (error.response.data.error) 
                //     this.error = error.response.data.error
                // else 
                //     this.error = error.message

                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
                return
            }

            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
            this.$store.dispatch('hideModal')
            this.$store.dispatch('aut/fetchTagTemplates')
        },
        openTemplateWizard(){
            this.showModal({ component: 'addTagTemplateWizard' })
        }
    },
    beforeMount(){
        this.$store.dispatch("aut/fetchInitiators");
        //Note: Setting the list in fields for duplicate validation
        this.fields = JSON.parse(JSON.stringify(Fields))
        this.fields.forEach((value)=>{
            if(value.name == "name"){
                value.validation.map((rule)=>{
                    if(rule.type =="duplicate"){
                        rule.list = this.$store.state.aut.tagTemplates;
                    }
                })
            }
            else if(value.name == "event_type"){
                value.options= tag_event_types
            }else if(value.name == "initiator"){
                value.options= this.$store.state.aut.initiators.map((initiator)=>{
                    return { label: initiator.name, value:initiator.value }
                })
            }
        })
    }
};
</script>

<style>
</style>