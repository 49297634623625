<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddClientSubscription()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Product Subscription
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">

                <InputField label="Client" type="text" v-model="$route.params.id" :editable="false" />

                <InputField label="Product" type="select" v-model="product" :editable="true" :options="productOptions"
                    :validate="[{ type: 'required', errMsg: 'select product' }]" />

                <InputField label="Quantity" type="number" v-model="quantity" :editable="true"
                    :validate="[{ type: 'required', errMsg: 'quantity is required' }]" />

                <InputField label="Effective Date" type="calendar" v-model="effectiveDate" :editable="true"
                    :inputConfig="eff_config" :validate="[{ type: 'required', errMsg: 'date is required' }]" />

                <InputField label="End Date" type="calendar" v-model="endDate" :editable="true" :inputConfig="end_config"
                    :validate="[{ type: 'required', errMsg: 'end date is required' }]" />
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="btnDisable" type="submit">Add</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputField from "../InputField.vue";
import { mapActions } from 'vuex';
import dayjs from "dayjs";
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { InputField },
    name: "AddClientSubscription",
    data() {
        return {
            effectiveDate: dayjs().format("YYYY-MM-DD"),
            endDate: dayjs().add(365, 'day').format("YYYY-MM-DD"),
            quantity: 1,
            product: null,
            productOptions: [],
            eff_config: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            end_config: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        productList() {
            return this.$store.state.admin.products
        },
        btnDisable() {
            return !(this.product && this.quantity > 0 && this.effectiveDate && this.endDate)
        }
    },
    beforeMount() {
        if (!this.productList.length) {
            this.$store.dispatch("admin/fetchProducts");
        }
        this.productOptions = this.productList.map((item) => {
            return { label: item.sku, value: item.product_id }
        })
    },
    watch: {
        productList(newVal) {
            this.productOptions = newVal.map((item) => {
                return { label: item.sku, value: item.product_id }
            })
        },
        effectiveDate(newVal){
            this.end_config.minDate = newVal?newVal.toString():null
        },
        endDate(newVal){
            this.eff_config.maxDate = newVal?newVal.toString():null
        }
    },
    methods: {
        ...mapActions('admin', {
            addClientSubscription: 'addClientSubscription'
        }),
        async onAddClientSubscription() {
            let valid = false;
            let toastoptions;
            let data = {
                cid: this.$route.params.id,
                product_id: this.product,
                quantity: this.quantity,
                effective_date: this.effectiveDate,
                end_date: this.endDate
            }
            try {
                //checking whether any active subscription for this product exits
               let activeSubscriptions = this.$store.state.admin.clientProducts.filter((sub)=>Date(sub.end_date)>Date())
               valid = !isDuplicate(this.product, activeSubscriptions, "product_id", null)
                if (valid) {
                    await this.addClientSubscription(data)
                    toastoptions = {
                        message: "Client Subscription added successfully.",
                        type: "success",
                    };
                } else {
                    if (!valid) {
                        toastoptions = {
                            message: "Already Product is added to client, try update option'",
                            type: "error",
                        };
                    }
                }
            } catch (error) {
                toastoptions = {
                    message: error.response.data.error.toString(),
                    type: "error"
                }
            }
            this.$store.dispatch('addToast', toastoptions);
            if (valid) {
                this.$store.dispatch('hideModal');
                this.$store.dispatch("admin/fetchClientProducts",this.$route.params.id);
            }
        }
    }
};
</script>

<style></style>