<template>
	<div class="card">
		<div class="row justify-content-end">                                    
			<div  class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end" >
				<div class="input-group input-group-sm">
					<label class="input-group-text">Test History Limit</label>
					<input type="number" class="form-control" aria-label="limit" v-model="$store.state.sandbox.testHistoryListLimit">
					<button :disabled="promotionExecutionList===null" class="btn btn-outline-secondary" type="button" @click="readPromotionExecutionList"><i class="fa fa-refresh" aria-hidden="true"></i></button>                    
				</div>
			</div>           
		</div>
		<datatable
			:tableId="`${promotion.id}-promotionList`"
			:columns="columns"
			:formatColumn="formatColumn"
			:data="promotionExecutionList"
			:showLoading="promotionExecutionList===null"
			:compact="true"
			:orderColumn="orderColumn"
			:detailColumn="{
				renderBaseUrl: (data) => {
					return `${promotion.dst_job_name}/executions/${data.execution_id}`;
				},
			}"
		/>
	</div>
</template>

<script>
	import { secondsTohms } from '../../../utils/commonFunction';
	import Datatable from "../../../components/Datatable.vue";
	import { mapActions, mapState } from "vuex";
	export default {
		components: { Datatable },
		data() {
			return {
				columns: [
					{ data: "id"},
					{ data: "status" },   
					{ data: "logs" },          
					{ data: "submitted_at", title: "submitted At"},
					{ 
						data: "run_time", 
						title:"run time",
						render: function (data, type, row) {
							return secondsTohms(data)
						},
					},
					{ data: "tag" },
				],
				orderColumn: [4, "desc"],
				formatColumn:[
					{
						index:4,
						type:"datetime"
					}
					
				],
				viewModalColumn: 3 //view icon column number
			};
		},
		props: ["promotion"],
		computed: {
			promotionExecutionList() {
				return this.addStatusFlag(this.$store.state.sandbox.promotionExecutions)
			}
		},
		methods: {
			readPromotionExecutionList() {
				this.$store.dispatch("sandbox/fetchPromotionExecutions", {promotionId: this.promotion.id, limit: this.$store.state.sandbox.testHistoryListLimit}); 
			},
			addStatusFlag(list) {
				let modifiedList = [];
				list.map((job)=>{
						let status ="";
						if(job.status.toLowerCase()==="succeeded"){
							status = "<span class='text-success'>●</span> ";
						} else if(job.status.toLowerCase()==="failed"){
							status = "<span class='text-danger'>●</span> ";
						} else if(job.status.toLowerCase()==="running"){
							status = "<span class='text-primary'>●</span> ";
						} else if(job.status.toLowerCase()==="submitted"){
							status = "<span class='text-warning'>●</span> ";
						} else if(job.status.toLowerCase()==="cancelled"){
							status = "<span class='text-secondary'>●</span> ";
						}
						job["status"] = status + job.status;
						modifiedList.push(job);
					});
				return modifiedList;
			},
		},
		mounted() {
			this.$emit('hideTestBtn', true);
		},
		emits: ['hideTestBtn']
	};
</script>

<style></style>
