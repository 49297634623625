<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete InitiatorFields</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the following InitiatorFields?</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData" :key="item.index" class="list-group-item">
                        {{item.name}}
                    </li>
                </ul>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Delete</div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            deleteInProgress: false
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteInitiatorFields",
    methods: {
        ...mapActions("aut", {
            deleteInitiatorField: "deleteInitiatorField",
        }),
        async onFormSubmit() {
            this.deleteInProgress = true;
            try {
                for (const tag of this.modalData) {
                    await this.deleteInitiatorField(tag)
                }
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            } finally {
                this.deleteInProgress = false;
            }

            this.$store.dispatch("hideModal");
            this.$store.dispatch("aut/fetchInitiatorFields");
        },
    },
};
</script>

<style>
</style>