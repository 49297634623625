<template>
    <div class="card">
        <div class="card-body" v-if="formattedMetricData">
            <div class="mb-5" v-for="(metricDataObj,key) in formattedMetricData" :key="key">  
                <div v-if="metricDataObj.data" class="row justify-content-end">
                    <!-- <div class="col-4 mt-4 d-flex position-absolute justify-content-start" >                        
                        <h3 v-if = "metricDataObj.data" class="card-header-title">{{ metricDataObj.Name }}</h3>
                    </div>    -->
                    <div class="col-8 mt-4 d-flex flex-row  position-absolute justify-content-between">
                        <h3 v-if = "key" class="ms-5 card-header-title">{{ key.replace(/-/g, ' ').replace(/amdp/gi, 'aMDP') }}</h3>
                        <div class="custom-control form-check form-switch ">
                            {{ showDataInUnits[key] }}
                            <input class="form-check-input" type="checkbox" role="switch"  id="customSwitch2"  :checked="showDataInUnits[key]"  @change="updateShowDataInUnits(key, $event.target.value)" />                            
                            <label class="form-check-label" for="customSwitch2">Show Abbreviated Data</label>
                        </div>
                    </div>  
                </div>            
                
                <Datatable 
                    v-if = "metricDataObj.data"
                    :tableId="key"                    
                    :showLoading="metricDataObj===null" 
                    :compact="true"
                    :columns="metricDataObj.headers"
                    :data = "metricDataObj.data"  
                    :paging = "metricDataObj.data.length>10"
                ></Datatable>               
            </div>

        </div>
        <div v-else>
            <h4>No metric data found!</h4>
        </div>
        

    </div>
 
</template>

<script>
import Datatable from "../Datatable.vue";
import { formatBytes, DateTimeFormat } from '../../utils/commonFunction';
export default {
  components: { Datatable },
    data() {
        return {
            metricColumns:[],
            showDataInUnits:{},
            formattedMetricData: null
        }
    },    
    methods: {
        formatData() {
            this.formattedMetricData = Object.fromEntries(
                    Object.entries(this.metricData).map(([title, value]) => {
                        if (typeof value === 'object' && value.data && value.headers) {
                            return [
                                title,
                                {
                                    ...value,
                                    headers: value.headers.map(key => {
                                        let self = this;
                                        const tempKey = key;
                                        let obj = {};
                                        if(key == "byte_count" || key.includes("memory")) {                                        
                                            obj = {
                                                data: key,
                                                title: tempKey.replace(/-/g, ''),
                                                render: function (data, type, row) {
                                                    if (!data) return "";
                                                    if (!self.showDataInUnits[title]) return data;
                                                    return  formatBytes(data)
                                                },
                                            }
                                        } else if(key == "time") {                                        
                                            obj = {
                                                data: key,
                                                title: tempKey.replace(/-/g, ''),
                                                render: function (data, type, row) {
                                                    if (!data) return "";
                                                    return  DateTimeFormat(data);
                                                },
                                            
                                            } 
                                        } else if(key == "record_count") {
                                            obj = {
                                                data: key,
                                                title: tempKey.replace(/-/g, ''),
                                                render: function (data, type, row) {
                                                    if (!data) return "";
                                                    if (!self.showDataInUnits[title]) return data;
                                                    return Intl.NumberFormat("en", {notation: "compact", roundingMode:"floor",  maximumFractionDigits: 2}).format(data)
                                                },
                                            }
                                        } else {
                                            obj = {
                                            data: key,
                                            title: tempKey.replace(/-/g, '')
                                            }
                                        }

                                        return obj;
                                    }),
                                    data: value.data.map(row => {
                                        const obj = {};
                                        row.forEach((rowValue, index) => {
                                            obj[value.headers[index]] = rowValue;
                                        });
                                        return obj;
                                    })
                                }
                            ];
                        }
                        return [title, value];
                    })
                );
               

        },
        updateShowDataInUnits(key) {            
            this.showDataInUnits[key] = !(this.showDataInUnits[key]);
            this.formatData();
        }
    },
    
    beforeMount(){
        // Initialize showDataInUnits with true for each key in metricData
        Object.keys(this.metricData).forEach(key => {
            this.showDataInUnits[key] = true;
        });
        this.formatData();
    },
    props:["metricData"],
    
    

}
</script>

<style>

</style>