<template>
    <div class="container-fluid">
        <form class="tab-content py-6" id="wizardSteps" @submit.prevent="onAddTag()">
            <!-- Event Type Step -->
            <div class="tab-pane fade show active" id="wizardStepOne" role="tabpanel" aria-labelledby="wizardTabOne">
                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                        <!-- Pretitle -->
                        <h6 class="mb-4 text-uppercase text-muted" >
                            Step 1 of 5
                        </h6>
                        <!-- Title -->
                        <h1 class="mb-3">                                                                                                                        
                            <!-- Let’s start with template creation. -->
                            Begin by selecting an Event Type to build your Tag Template.
                        </h1>
                        <!-- Subtitle -->
                        <p class="mb-5 text-muted">
                            Event Type selection will incorporate both Paid and Owned media events. This will distinguish the type of data that is collected with your tag. 
                        </p>
                    </div>
                </div> <!-- / .row -->
                <div class="row justify-content-center">
                    <div class="col-md-10">                  
                        <Tiles :tiles="eventTypeTiles" @onSelectTile="onSelectEventType" :selectedTile="tagTemplate.event_type"></Tiles>
                    </div>
                </div>            

                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">
                        <!-- Button -->
                        <button class="btn btn-lg btn-white " type="reset" data-bs-dismiss="modal">Cancel</button>
                    </div>
                    <div class="col text-center">
                        <!-- Step -->
                        <h6 class="text-uppercase text-muted mb-0">Step 1 of 5</h6>
                    </div>
                    <div class="col-auto">
                        <!-- Button -->
                        <a  v-show="tagTemplate.event_type !== null" class="btn btn-lg btn-primary" data-toggle="wizard" href="#wizardStepTwo">Continue</a>
                    </div>
                </div>
            </div>

            <!-- Platform Step -->
            <div class="tab-pane fade" id="wizardStepTwo" role="tabpanel" aria-labelledby="wizardTabTwo">

                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                        <!-- Pretitle -->
                        <h6 class="mb-4 text-uppercase text-muted">
                            Step 2 of 5
                        </h6>
                        <!-- Title -->
                        <h1 class="mb-3">
                            Select the service you would like to utilize to initiate data (optional)
                        </h1>
                        <!-- Subtitle -->
                        <p class="mb-5 text-muted">
                            Platform selection will  enable data syncing with the relevant DSP or Campaign Manager we support for tag generation.
                        </p>
                    </div>
                </div> <!-- / .row -->
                <div class="row justify-content-center">                   
                    <div class="col-auto">                   
                        <Tiles :tiles="initiatorTiles" @onSelectTile="onSelectPlatform" :selectedTile="tagTemplate.initiator"></Tiles>
                    </div>
                </div>    

                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">
                        <!-- Button -->
                        <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepOne">Back</a>
                    </div>
                    <div class="col text-center">
                        <!-- Step -->
                        <h6 class="text-uppercase text-muted mb-0">Step 2 of 5</h6>
                    </div>
                    <div class="col-auto">
                        <!-- Button -->
                        <a class="btn btn-lg" :class="tagTemplate.initiator?'btn-primary':'btn-white'" data-toggle="wizard" href="#wizardStepThree">
                            <span v-if="tagTemplate.initiator">Continue</span>
                            <span v-else >Skip</span>
                        </a>
                    </div>
                </div>

            </div>

            <!-- Tag Type-->
            <div class="tab-pane fade" id="wizardStepThree" role="tabpanel" aria-labelledby="wizardTabThree">

                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                        <!-- Pretitle -->
                        <h6 class="mb-4 text-uppercase text-muted" >
                            Step 3 of 5
                        </h6>
                        <!-- Title -->
                        <h1 class="mb-3">                                                                                                                        
                            <!-- Let’s start with template creation. -->
                            Select the Tag Type that will be generated. 
                        </h1>
                        <!-- Subtitle -->
                        <p class="mb-5 text-muted">
                            Tag Type selection will distinguish the type of tag that is generated, relative to your event type. 
                        </p>
                    </div>
                </div> <!-- / .row -->
                <div class="row justify-content-center">                    
                    <div class="col-auto">
                        <!-- Title -->
                        <h3 class="mb-3"> 
                            Would you like to create an Atomic OR a Container tag?
                        </h3>
                    </div>
                    <div class="col-auto">                  
                        <Tiles :tiles="tagTypeTiles" @onSelectTile="onSelectTagType" :selectedTile="tagType"></Tiles>
                    </div>
                </div>  

                <div v-show="tagType=='atomic'">
                    <div class="row justify-content-center" >
                        <div class="col-auto">
                        <!-- Title -->
                            <h3 class="mb-3"> 
                                Would you like to generate image or script tag? 
                            </h3>
                        </div>
                        
                        <div class="col-auto">                  
                            <Tiles :tiles="atomicTagTypeTiles" @onSelectTile="onSelectAtomicTagType" :selectedTile="atomicTagType"></Tiles>
                        </div>                    
                    </div>
                    <div v-show="atomicTagType=='img'"  class="row justify-content-center">
                        <div class="col-auto">
                        <!-- Title -->
                            <h3 class="mb-3">
                                You've chosen an Image Atomic Tag Type, what format would you like to generate? 
                            </h3>
                        </div>
                        <div class="col-auto">                  
                            <Tiles :tiles="atomicImageTagFormatTiles" @onSelectTile="onSelectTagFormat" :selectedTile="tagTemplate.tag_type"></Tiles>
                        </div>
                    </div>
                    <div v-show="atomicTagType=='js'"  class="row justify-content-center">
                        <div class="col-md-10">
                            <!-- Title -->
                            <h3 class="mb-3">
                                    You've chosen an Script Atomic Tag Type, what format would you like to generate? 
                                </h3>
                        </div>
                        <div class="col-md-10 ">                  
                            <Tiles :tiles="atomicScriptTagFormatTiles" @onSelectTile="onSelectTagFormat" :selectedTile="tagTemplate.tag_type"></Tiles>
                        </div>
                    </div>

                </div>
                
                <div v-show="tagType=='container'" class="row justify-content-center">
                    <div class="col-md-10">
                    <!-- Title -->
                        <h3 class="mb-3">
                            You've chosen an Container Tag Type, what format would you like to generate? 
                        </h3>
                    </div>
                    <div class="col-md-10 text-center">                  
                        <Tiles :tiles="containerTagFormatTiles" @onSelectTile="onSelectTagFormat" :selectedTile="tagTemplate.tag_type"></Tiles>
                    </div>
                </div>
                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">                  
                        <!-- Button -->
                        <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepTwo">Back</a>
                    </div>
                    <div class="col text-center">
                        <!-- Step -->
                        <h6 class="text-uppercase text-muted mb-0">Step 3 of 5</h6>
                    </div>
                    <div class="col-auto">
                        <!-- Button -->
                        <a v-show="tagTemplate.tag_type" class="btn btn-lg btn-primary" data-toggle="wizard" href="#wizardStepFour">Continue</a>
                    </div>
                </div>
            </div>

            <!-- Logging Mode -->
            <div class="tab-pane fade" id="wizardStepFour" role="tabpanel" aria-labelledby="wizardTabFour">
                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <!-- Pretitle -->
                    <h6 class="mb-4 text-uppercase text-muted">
                        Step 4 of 5
                    </h6>
                    <!-- Title -->
                    <h1 class="mb-3">
                        Would you like  to enable logging Mode?
                    </h1>                 

                    </div>
                </div> <!-- / .row -->
                <div class="row justify-content-center text-center">                    
                    <div class="col-auto">  
                        <Tiles :tiles="loginModeTiles" @onSelectTile="onLogginModeEnable" :selectedTile="enableLogginMode"></Tiles>
                    </div>
                </div>
                <div v-if="enableLogginMode">
                    <div class="row justify-content-center text-center">
                        <div class="col-md-10">
                        <!-- Title -->
                            <h3 class="mb-3">
                                Choose a Log Delivery 
                            </h3>
                        </div>
                        <div class="col-md-10 text-center">                  
                            <Tiles :tiles="logDeliveryStreaming?loginDeliveryTilesWithStreaming:loginDeliveryTiles" @onSelectTile="onSelectLogginDelivery" :selectedTile="logDelivery"></Tiles>
                        </div>
                    </div>
                    <div class="row justify-content-center text-center">
                        <div class="col-md-10">
                        <!-- Title -->
                            <h3 class="mb-3">
                                Choose a Log Size
                            </h3>
                        </div>
                        <div class="col-md-10 text-center">                  
                            <Tiles :tiles="loginSizeTiles" @onSelectTile="onSelectLogginSize" :selectedTile="logSize"></Tiles>
                        </div>
                    </div>
                    <div class="row justify-content-center text-center">
                        <div class="col-md-10">
                        <!-- Title -->
                            <h3 class="mb-3">
                                Choose a Log IP Type
                            </h3>
                        </div>
                        <div class="col-md-10 text-center">                  
                            <Tiles :tiles="loginIPTypeTiles" @onSelectTile="onSelectLogginIPType" :selectedTile="logIPType"></Tiles>
                        </div>
                    </div>
                </div>  
                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">
                        <!-- Button -->
                        <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepThree">Back</a>
                    </div>
                    <div class="col text-center">
                        <!-- Step -->
                        <h6 class="text-uppercase text-muted mb-0">Step 4 of 5</h6>
                    </div>
                    <div class="col-auto">
                        <!-- Button -->
                        <a class="btn btn-lg btn-primary" data-toggle="wizard" href="#wizardStepFive">Continue</a>
                    </div>
                </div>

            </div>

            <!-- Tag Name -->
            <div class="tab-pane fade" id="wizardStepFive" role="tabpanel" aria-labelledby="wizardTabFive">
                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    <!-- Pretitle -->
                    <h6 class="mb-4 text-uppercase text-muted">
                        Step 5 of 5
                    </h6>
                    <!-- Title -->
                    <h1 class="mb-3">
                        Name your Tag Template
                    </h1>
                    <!-- Subtitle -->
                    <p class="mb-5 text-muted">
                    <!-- Select the type of tag you would like to generate -->
                    </p>
                    </div>
                </div> <!-- / .row -->
                
                <div class="row  justify-content-center">
                    <div class="col-md-10">
                        <div class="mb-3">
                            <h3 for="exampleFormControlInput1" class="form-label">Name</h3>
                            <input  class="form-control" id="exampleFormControlInput1" v-model="tagTemplate.name">
                        </div>
                        <div class="mb-3">
                            <h3 for="exampleFormControlInput1" class="form-label">Category Tags</h3>
                            <InputField
                                v-model="tagTemplate.tag"
                                :options="tagTemplate.tag"
                                :showLabel="false"
                                type="multiselect"
                            />
                        </div>
                        <div v-if="this.$store.getters.userRoles.isAqferAdmin" class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="tagTemplate.public" id="flexCheckDefault">
                            <h3 class="form-check-label" for="flexCheckDefault">
                                Would you like to make this tag template public?  
                            </h3>
                        </div>
                    </div>
                </div>  
                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">
                        <!-- Button -->
                        <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepFour">Back</a>
                    </div>
                    <div class="col text-center">
                        <!-- Step -->
                        <h6 class="text-uppercase text-muted mb-0">Step 5 of 5</h6>
                    </div>
                    <div v-show="tagTemplate.name" class="col-auto">
                        <!-- Button -->
                        <button class="btn btn-lg btn-primary" type="submit">
                            <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div v-else>
                                Create
                            </div>       
                        </button>                         
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import { Tab } from 'bootstrap';
import { mapActions } from 'vuex'
import  Tiles  from '../Tiles.vue';
import { isDuplicate } from '../../utils/commonFunction';
import InputField from "../InputField.vue";
const logKeys = {
                'b':'bbh',
                'c':'bbi',
                'd':'beh',
                'e':'bei',
                'f':'sbh',
                'g':'sbi',
                'h':'seh',
                'i':'sei',
                'j':'bbn',
                'k':'ben',
                'l':'sbn',
                'm':'sen',
            }

export default {
    components: {Tiles, InputField},
    data() {
        return {
            tagTemplate: {
                owner: this.$store.state.currentUser.email,
                cid: this.$store.state.currentClient.cid,
                name: null,
                event_type: null,
                initiator: null,
                tag_type: null,
                cookie: null,
                public: false,
                logging_mode: null
            }, 

            eventTypeTiles: [
                {title:"Impression", subTitle:"An impression (also known as a view-through) is when a user sees an advertisement.", value:"imp"},
                {title:"Click", subTitle:"To register that a click has occured by the user.", value: "clk"},
                {title:"Conversion", subTitle:"The point at which a recipient of a marketing message performs a desired action.", value:"conv"},
                {title:"Engagement", subTitle:"When the user engages with particular content.", value: "eng"},
                {title:"Page View", subTitle:"A single instance of a user visiting a particular page on a website", value: "pv"},
                {title:"Other", subTitle:"Choose a specific event type later.", value: ""}
            ],

            initiatorTiles: [],
                    
            
            atomicTagTypeTiles: [
                    {title:"Javascript", subTitle:"Allows for extra collection of data on the page.", value:"js"},
                    {title:"Image", subTitle:"Raw logging pixel with query parameters.", value: "img"},
                    {title:"Redirect", subTitle:"URL to collect and then redirect to a certain URL.", value: "r"},
                ],
            
            atomicTagType: null,            
            tagTypeTiles: [
                    {title:"Atomic", subTitle:"A pixel or endpoint with the purpose of firing once.", value:"atomic"},
                    {title:"Container", subTitle:"Allows the ability to fire additional tags.", value: "container"},
                ],           
            tagType: null,            

            loginModeTiles: [
                {title:"Yes", subTitle:"Store logs for development or error checking purposes", value:true},
                {title:"No", subTitle:"Do not store additional logs.", value: false},
            ],
            loginDeliveryTiles: [
                { title: "Batch", value: "b" }
            ],
            loginDeliveryTilesWithStreaming: [
                { title: "Batch", value: "b" },
                { title: "Streaming", value: "s" }
            ],
            loginSizeTiles: [
                { title: "Base", value: "b" },
                { title: "Extended", value: "e" },
            ],
            loginIPTypeTiles: [
                { title: "Hashed IP", value: "h" },
                { title: "Plain IP", value: "i" },
                { title: "No IP", value: "n" },
            ],                  
            atomicImageTagFormatTiles: [
                { title:"Cookie  (Recommended)", subTitle:"Sets unique cookie if it is not present.", value:"a.gif"},
                { title:"NoCookie", subTitle:"No additional logic.", value: "c.gif"},
            ],           
            atomicTagFormat: null,
            atomicScriptTagFormatTiles: [
                { title:"Cookie  (Recommended)", subTitle:"Sets unique cookie if it is not present.", value:"c.js"},
                { title:"Statistical", subTitle:"Additional Statistical ID logic to determine unique users.", value: "s.js"},
                { title:"Both", subTitle:"Cookie and Statistical ID logic combined.", value: "cs.js"},
            ],
            containerTagFormatTiles: [
                { title:"Cookie", subTitle:"Sets unique cookie if it is not present.", value:"tc.dhj"},
                { title:"Statistical", subTitle:"Additional Statistical ID logic to determine unique users.", value: "ts.dhj"},
                { title:"Both", subTitle:"Cookie and Statistical ID logic combined.", value: "tcs.dhj"},
                { title:"None", subTitle:"No additional logic.", value: "t.dhj"},
            ],
            loading: false,
            logDelivery: null,
            logSize: "",
            logIPType: "",
            editMode: true,
            enableLogginMode:null,
            tag: null
        }
    },

    computed: {        
        loggingMode() {
            let logMode = this.logDelivery+this.logSize+this.logIPType;
            for (const key in logKeys) {
                if(logKeys[key] == logMode)
                    return key
            }
            return 'a';
        },
        logDeliveryStreaming() {
            return (this.$store.state.currentClient.config && this.$store.state.currentClient.config.logDeliveryStreaming)?this.$store.state.currentClient.config.logDeliveryStreaming: false;
        }      
    },

    watch: {        
        loggingMode(value, oldValue) {
            this.tagTemplate.logging_mode = value;
        }
    },

    mounted() {
        const toggles = document.querySelectorAll('[data-toggle="wizard"]');
        toggles.forEach(toggle => {
            toggle.addEventListener('click', function(e) {
                e.preventDefault();
                // Toggle new tab
                const tab = new Tab(toggle);
                tab.show();
                // Remove active state
                toggle.classList.remove('active');
            });
        });
    },
    beforeMount(){
        let self=this;
         this.$store.dispatch("aut/fetchInitiators").then(()=>{
            self.initiatorTiles= self.$store.state.aut.initiators.map((initiator)=>{
                return { title:initiator.name, 
                         subTitle:initiator.description,
                         value:initiator.value, 
                         iconSrc:initiator.logo
                        } 
            })
         })
        },
    methods: {
        ...mapActions('aut', {
            createTagTemplate: 'createTagTemplate',
            fetchTagTemplates: 'fetchTagTemplates'
        }),
        async onAddTag() {
            this.loading = true;
            let toastoptions;
            let response;
            let valid = false;
            try {
                valid = !isDuplicate(this.tagTemplate.name,this.$store.state.aut.tagTemplates, "name", null)
                if(valid){
                    this.tagTemplate.tag= this.tagTemplate.tag ?this.tagTemplate.tag.toString():"";
                    response = await this.createTagTemplate(this.tagTemplate)
                    toastoptions = {
                        message: "Tag added successfully.",
                        type: "success"
                    }
                }else{
                    toastoptions = {
                        message: "Already '"+this.tagTemplate.name+"' name exits.'",
                        type: "error",
                    };
                }
            } catch (error) {                
                // if (error.response.data.error) 
                //     this.error = error.response.data.error
                // else 
                //     this.error = error.message

                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
                return
            }
            //Dont hide and redirect page when name is duplicate
            if (valid){
                this.$store.dispatch('hideModal')
                if(response.data.id)
                    this.$router.push({ name: 'Tag Template', params: {id: response.data.id}} );
                else
                    this.$store.dispatch('aut/fetchTagTemplates')
            }
            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
        },
        
        onSelectEventType(val) {
            this.tagTemplate.event_type = val;
            if(val == "clk") {
                this.onSelectTagType("atomic");
                this.onSelectAtomicTagType("r");
            }
        },
        onSelectPlatform(val) {
            this.tagTemplate.initiator = val;
        },
        onSelectTagType(val) {
            this.tagType = val;
        },
        onSelectTagFormat(val) {
            this.tagTemplate.tag_type = val;
        },
        
        onSelectAtomicTagType(val) {
           this.atomicTagType = val;
           if(this.atomicTagType == "r") {
                this.tagTemplate.tag_type = "r";
           }
        },
        onSelectAtomicTagFormat(val) {
            this.atomicTagFormat = val;
        },       
        onLogginModeEnable(val) {
            this.enableLogginMode = val;
            if(!this.enableLogginMode){
                this.tagTemplate.logging_mode = "a";
                this.logDelivery = null;
                this.logSize = null;
                this.logIPType = null;
            }
            else{
                this.logDelivery = "b";
                this.logSize = "e";
                this.logIPType = "h";
            }
            
        },
        onSelectLogginDelivery(val) {
            this.logDelivery = val;

        },
        onSelectLogginSize(val) {
            this.logSize = val;

        },
        onSelectLogginIPType(val) {
            this.logIPType = val;

        }
    }

}
</script>

<style>

</style>