<template>
  <BasicHeader pretitle="aqfer Marketing Data Platform" title="Named Queries" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body p-0">
            <Datatable 
              tableId="namedQueries"
              :columns="columns"
              uniqueField="Name"
              :data="$store.state.amdp.queries"
              :selectedItem="$store.state.amdp.currentQuery"
              :lengthChange="false" :info="false"
              v-on:selected="handleSelect"
              :compact="true"
              />  
            </div>
            <div class="card-footer">
              <span class="float-end">
                    <button type="button" :disabled ="userRoleRank < 4 ? false:true" @click="showModal({component:'AddQuery'})" class="btn btn-primary" ><i class='fa fa-plus' data-bs-toggle="tooltip" data-bs-placement="left" :title="userRoleRank >= 4 ? `User do not have permission to add named query.Please contact support` :`Add Named Query`"/>
                    </button>&nbsp;
                    <button type="button" :disabled ="userRoleRank < 4 ? false:true" @click="showModal({component:'ImportNamedQuery'})" class="btn btn-primary" ><i class='fa fa-download' data-bs-toggle="tooltip" data-bs-placement="left" :title="userRoleRank >= 4 ? `User do not have permission to add named query.Please contact support` :`Import Named Query`"/>
                    </button>&nbsp;
              </span>
            </div>
        </div>
      </div>
      <div  v-if="query" class="col-md-9">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title text-capitalize">{{ query.Name }}</h4>
            <span class="float-end">
              <button v-if="isAdmin" type="button" @click="onAdminView" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Switch to admin view"><i class='fa fa-user' /></button>&nbsp;
              <button type="button" @click="onEditClick" class="btn btn-primary" :disabled ="userRoleRank < 4 ? false:true"><i class='fa fa-pen-to-square' data-bs-toggle="tooltip" data-bs-placement="left" :title="userRoleRank >= 4 ? `User do not have permission to edit named query.Please contact support` :``"/></button>&nbsp;
              <button type="button" @click="onCopyQuery" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy query as JSON"><i class='fa fa-copy' /></button>&nbsp;
              <button type="button" @click="onDeleteClick" class="btn btn-primary" :disabled ="userRoleRank < 4 ? false:true" ><i class='fa fa-trash-can' data-bs-toggle="tooltip" data-bs-placement="top" :title="userRoleRank >= 4 ? `User do not have permission to delete named query.Please contact support` :`Delete Named Query `"/></button>
            </span>
          </div>
          <div class="card-body p-0">
            <CodemirrorEditor 
              class="codeMirrorHeight"
              :key="query.Name"
              :editorId="query.Name"
              mode="sql"
              :refreshOn="refreshOn"
              v-model="query.QueryString"
              :readOnly="true">
            </CodemirrorEditor>
          </div>
        </div>
        <div v-if="paramError" class="alert alert-danger" role="alert">
        {{ paramError }}
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="query.Params.length > 0">
              <FormFields
                :fields="queryParams"
                v-model="param"
                :refreshOn=refreshOn
                :inlineField=true />
            </div>
            <div v-else class="text-center">
              No Params
            </div>
            <div class="text-center mt-4">
              <span v-if="!runQuery">
                  <span class="custom-control form-switch me-3" style="display: inline-block">
                      <input type="checkbox" class="form-check-input" v-model="cache" id="cacheSwitch">
                          <label style="padding-left:5px" class="form-check-label" for="cacheSwitch">Cache</label>
                    </span>&nbsp;
                    <button type="button" @click="onRunQuery"
                          class="btn btn-primary"><i class='fa fa-play' />&nbsp;Run Query
                    </button>
                  </span>
                  <span v-else>
                      <button @click="onStopClick" class="btn btn-danger"> Stop </button>
               </span>
            </div>
          </div>
         
        </div>
        <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
        </div>
        <div v-if="showOutput" class="card">
          <div class="card-header">
            <h4 class="card-header-title">Output</h4>
            <span class="float-end">
              <button type="button" @click="exportCSV()" class="btn btn-primary">Export</button> &nbsp;
              <button type="button" v-if="isPartialResult" @click="exportFullData()" class="btn btn-primary">Export Full Result</button>
            </span>
          </div>
          <Datatable
              tableId="outputTable"
              :key = "outputLoading"
              :columns="outputColumns"
              :data="outputData"
              :compact="true"
              :ignoreCache="true"
              :showLoading="outputLoading" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BasicHeader from '../../components/BasicHeader.vue';
import Datatable from "../../components/Datatable.vue";
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
import FormFields from "../../components/FormFields.vue";
import {mapActions} from 'vuex';
import axios from 'axios';
import csv from "../../utils/tools/csv"
export default {
  components: { BasicHeader, Datatable, CodemirrorEditor, FormFields },
  name: "Queries",
  data() {
    return {
      columns: [
        {
          data: "Name",
          title: "Query Name",
        },
      ],
      param: {},
      error: null,
      refreshOn: false,
      runQuery: false,
      outputColumns: [""],
      outputData: [],
      showOutput: false,
      outputLoading: false,
      cache:true,
      paramError:null,
      isPartialResult:null,
      query_id:null
    };
  },
  methods: {
    ...mapActions({
      showModal: "showModal",
      deleteQuery: "amdp/deleteQuery",
      fetchFullQueryFile: "amdp/fetchFullQueryFile",
      stopQueryExecutions: "amdp/stopQueryExecutions"
    }),
    handleSelect(data) {
      this.$store.dispatch("amdp/setCurrentQuery", data);
      this.showOutput = false;
      this.param={};
      this.error = null;
      this.paramError = null;
      this.isPartialResult = null;
      this.query_id = null;
    },
    onEditClick() {
      this.$router.push("queries/"+this.query.Name);
    },
    async onRunQuery() {
      this.outputLoading=true;
      this.runQuery = true;
      let paramsString = [];
      this.error = null;
      this.paramError=null;
      this.isPartialResult = null;
      this.query_id = null;
      if (!this.cache) {
          paramsString.push("cache=false")
      }
      let runFlag = true;
      this.showOutput=true;
      this.outputColumns=[""];
      if (this.query.Params.length > 0) {
        this.query.Params.map((item) => {
          switch (item.rule) {
            case "required":
              if (Object.prototype.hasOwnProperty.call(this.param, item.name)
                && this.param[item.name]) {
                paramsString.push(item.name + "=" + this.param[item.name])
              } else {
                this.param[item.name] = ''
                runFlag = false;
              }
              break;
            case "optional":
              if (Object.prototype.hasOwnProperty.call(this.param, item.name)) {
                paramsString.push(item.name + "=" + this.param[item.name])
              } else {
                paramsString.push(item.name + "=" + item.value)
              }
              break;
            case "fixed":
              if (Object.prototype.hasOwnProperty.call(this.param, item.name)) {
                this.param[item.name] = ''
              } else {
                this.param[item.name] = ''
              }
              paramsString.push(item.name + "=" + item.value)
              break;
          }
        })
      }
      this.refreshOn = true
      var self = this;
      if (runFlag) {
        paramsString = paramsString.join('&')
        axios.post(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/named_queries/${this.query.Name}/execute?`
          + encodeURI(paramsString), null, {
          headers: {
            Authorization: `Bearer ${self.$store.state.accessToken}`,
          },
        }).then((response) => {
          if (response.data == null) {
            self.error= response.message
          } else {
            var query_id = response.data.query_execution_id
            if (self.$store.state.environment == "test") {
              query_id = "adReport"
            }
            if (query_id != "" && query_id != undefined) {
              self.query_id = query_id;
              self.sleep(2000).then(function () {
                self.getQueryData(query_id, 120);
              });
            } else {
              this.error= "Failed to submit query. Please retry or contact support."
              this.outputLoading = false;
              this.runQuery = false;
            }
          }
        }).catch(({response})=>{
            self.outputLoading = false;
            self.runQuery = false;
            if (response.data.error) 
              self.error = response.data.error;
            else if (response.data.message) 
              self.error =  response.data.message;
            else 
              self.error =  response.message;
        })
      }else{
            self.outputLoading = false;
            self.runQuery = false;
            self.showOutput = false;
            self.paramError="Enter mandatory params!"
      }
    },
    async getQueryData(query_id, counter) {
      var self = this;
      return axios.get(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/query_status/${query_id}`, {
        headers: {
          Authorization: `Bearer ${self.$store.state.accessToken}`,
        },
      }).then(function (response) {
        if (response.data.State && response.data.State == "FAILED" ){
            self.error=response.data.StateChangeReason
        }else if (response.data.State && (response.data.State == "QUEUED" || response.data.State == "RUNNING") && counter > 0) {
          counter--;
          self.sleep(5000).then(function () {
            self.getQueryData(query_id, counter, self.cid);
          });
        } else {
          return axios.get(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/query_executions/${query_id}`,
            {
              headers: {
                Authorization: `Bearer ${self.$store.state.accessToken}`,
              },
            }).then(function (response) {
              if (Object.prototype.hasOwnProperty.call(response.data, "message")) {
                self.error= response.data.message
              } else {
                //Show result data
                self.setOutputColumns(response.data.column_info)
                self.outputData=self.convertToTableJson(response.data);
                //If partial data is returned, then get show export option to download full data
                if (response.data.partial_result) {
                  self.isPartialResult = true;
                  self.query_id = query_id
                }
            }
            });
        }
      }).catch(({response})=>{
            self.outputLoading = false;
            self.runQuery = false;
            if (response.data.error) 
              self.error = response.data.error;
            else if (response.data.message) 
              self.error =  response.data.message;
            else 
              self.error =  response.message;
      });
    },

    convertToTableJson(obj) {
      var c = [];
      for (var i = 0; i < obj.data.length; i++) {
          var temp = {}
          //huge data getting from query_result_file - data are not seperated so seperating it by ,
          if(obj.data[i].length !== obj.column_info.length){
            obj.data[i] = obj.data[i].split(",")
          }
          for (var j = 0; j < obj.column_info.length; j++) {
            temp[obj.column_info[j].name] = obj.data[i][j]
          }
          c.push(temp);
      }
      this.outputLoading = false;
      this.runQuery = false;
      return c;
    },
    sleep(time) {
      return new Promise(function (resolve) {
        setTimeout(resolve, time);
      });
    },
    setOutputColumns(column_info){
      let outputColumns= column_info.map((column)=>{
        return { "data": column.name}
      })
      this.outputColumns=[...outputColumns]
    },
    onCopyQuery(){
       //Copy Query has json logic
        var copyArea = document.createElement("textarea");
        document.body.appendChild(copyArea);
        copyArea.value = JSON.stringify(this.$store.state.amdp.currentQuery);
        copyArea.select();
        document.execCommand("copy");
        document.body.removeChild(copyArea);
    },
    onDeleteClick(){
      const self = this;
      this.showModal({
        component: "confirm",
        data: {
          title: "Delete Named Query",
          message: `Are you sure you want to delete ${this.query.Name} ?`,
          async confirm() {
            try {
              const response = await self.deleteQuery(self.query);
              self.$store.dispatch("amdp/fetchQueries");
              self.$store.dispatch("amdp/setCurrentQuery", self.$store.state.amdp.queries[0]);
              return response;
            } catch (error) {
              if (error.response.data.error)
                self.error = error.response.data.error;
              else if (error.response.data.message) 
                self.error = error.response.data.message
              else self.error = error.message;
              return;
            }
          },
        },
      });
    },
    exportCSV() {    
        let header=[]
        if(this.outputData.length>0){
          Object.keys(this.outputData[0]).map((value)=>header[value]=value)
          csv.exportCSV(this.query.Name, [...header,... this.outputData])
        }else{
          this.error="No data to export"
        }      
        
    },
    onAdminView(){
      this.$router.push(`/${this.$store.state.currentClient.cid}/amdp/admin-queries`);
    },
    onStopClick(){
            this.outputLoading = false;
            this.runQuery = false;
            this.error = null;
            this.outputData=[];
            this.isPartialResult = null;
            try{
              if(this.query_id)
                this.stopQueryExecutions(this.query_id);
            }catch (error) {
              if (error.response.data.error) 
                this.error = error.response.data.error;
              else if (error.response.data.message) 
                this.error =  error.response.data.message;
              else 
                this.error =  error.message;
            }
            this.query_id = null;
    },
    exportFullData(){
          var self=this;
          this.fetchFullQueryFile(this.query_id).then(function (resp) {
                if(resp.https){
                  var url = resp.https;
                  var a = document.createElement("a");
                  a.href = url;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove(); 
                }else{
                  self.error = "Unable to download file, Try again !"
                }
            }).catch(({response})=>{
                if (response.data.error) 
                  self.error = response.data.error;
                else if (response.data.message) 
                  self.error=response.data.message;
                else 
                  self.error = response.message;
            });    
    }
  },
  computed: {
    query() {
      return this.$store.state.amdp.currentQuery;
    },
    queryParams() {
      if (this.query) {
        return this.query.Params.map((param) => {
          return {
            name: param.name,
            label: param.name,
            placeholder:param.value,
            type: param.datatype === "string" ? "text" : "number",
            validation: param.rule === "required" ? [{ type: "required", errMsg: param.name +" is required"}] : [],
            editable: true
          }
        })
      }
      return []
    },
    isAdmin() {
          return this.$store.getters.userRoles.isAqferAdmin;
    },
    userRoleRank() {
        return this.$store.getters.userRoles.userRoleRank;
    },
  },
  beforeMount() {
    this.$store.dispatch("amdp/fetchQueries")

  },
}
</script>

<style scoped>
.codeMirrorHeight>>>.CodeMirror {
  height: 410px;
}

.form-check-input:checked {
  background-color: #2C7BE5 !important;
  border-color: #2C7BE5;
}

.form-check-input {
  background-color: #6E84A3;
}
</style>