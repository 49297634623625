const fields = [
    {
        name: "cls",
        label: "Name",
        type: "text",
        editable: true,
        visible: true,
        validation: [
            { type: "required" },
            { type: "regex", pattern:"^[a-z0-9]+$",
            errMsg:"Class names should be lowercase."}
        ]
    },
    {
        name: "description",
        label: "Description",
        type: "text",
        editable: true,
        visible: true,
    },
    {
        name: "initLoad",
        label: "Initial Load",
        type: "number",
        editable: true,
        visible: true,
        tooltip: 'Maximum number of pixels to fire when the page loads for the first time',
        validation: [
            { type: "required" }
        ],
        defaultValue: 0,
    },
    {
        name: "subsLoad",
        label: "Subsequent Loads",
        type: "number",
        editable: true,
        visible: true,
        tooltip: 'Maximum number of pixels to fire when the page loads for subsequent times.',
        validation: [
            { type: "required" }
        ],
        defaultValue: 0,
    },
    {
        name: 'uaParseCountryList', 
        label: 'uaParsed Countries', 
        type: 'array',
        tooltip: 'List of countries for which the logs should parse user-agent field.' * ' can be used instead'
    },
    {
        name: 'skipFileTypes',
        label: 'skip File Types',
        defaultValue: ["t.dhj","tc.dhj","ts.dhj","tcs.dhj"],
        type: 'array' 
    },
    {
        name: "log",
        label: "Log Config",
        type: "object",
        tooltip:"Log mode of logging beacon.Delivery: Batch/Streaming, Level:Base/Extended.If extend,the logs will also include geo information, Ip Format: No IP/Raw/Hashed Ip",
        inputType: "json",
        editable: true,
        visible: true,
        defaultValue: JSON.stringify({"delivery":"BATCH","level":"EXTENDED","ipFormat":"HASH_4" }),
    },
    {
        name: "rotations",
        label: "Rotation Configs",
        type: "object",
        tooltip:"List of configurations to set rotation cookie",
        inputType: "json",
        editable: true,
        visible: true,
        defaultValue: JSON.stringify({"default":{"period":0,"periodUnit":"SESSION"}}), 
    }
]

export default fields;