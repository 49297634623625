<template>
    <Datatable 
        :tableId="tableId" 
        :columns="columns"
        :data="data"
        :showLoading="loading"
        uniqueField = "name"
        :orderColumn="orderColumn"
        :formatColumn="formatColumn"
        :selectCheckboxes="true"
        :deletable="true"
        @deleteRows="showDeleteFile"
        :compact="true" @rowCallback="findFileorFolder"
        :detailColumn="{ baseUrl: baseUrl, name: name, identifier: identifier, icon: icon, breadcrumbs:true }" 
    />
</template>
<script>
import Datatable from "./Datatable.vue";
import { mapActions } from "vuex";
export default{
    name: 'Folder',
    components: { Datatable },
    data(){
        return{
            columns: [
                {
                    data: "name",
                    title: "name",
                },
                {
                    data: "size",
                    title: "Size",
                },
                {
                    data: "time",
                    title: "time",
                },
            ],
            formatColumn: [
                {
                    index: 3,
                    type: 'bytes'
                },
                {
                    index: 4,
                    type: "datetime"
                }
            ],
            orderColumn: [4, "desc"],
        }
    },
    props: {
        data: {
            type: Array,
            default: function(){
                return []
            }
        },
        baseUrl:{
            type: String,
            default:""
        },
        name:{
            type: String,
            default: ""
        },
        identifier:{
            type: String,
            default: ""
        },
        icon: String,
        loading : {
            type: Boolean,
            default : false,
        },
        tableId:{
            type: String,
            default :"fileBrowser"
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        findFileorFolder(row, rowData, type, index) {
            if (rowData) {
                if (rowData.is_dir=="false") {
                    // for files show file icon
                    row.childNodes[1].childNodes[0].childNodes[0].className="detail-icon fa fa-regular fa-file"
                }else{
                    // Hide select row for folder
                    row.children[0].disabled = true;
                    row.children[0].classList.remove('select-row', 'fa', 'fa-regular', 'select-checkbox-fa');
                        
                }
            }
        },
        showDeleteFile(rows) {
            let path =this.$store.state.amdp.folderPath;
            this.showModal({ component: "DeleteFiles", data: {rows: rows, path: path} });
        },
    }
}
</script>
<style>

</style>