<template>
    <detail-header pretitle="Initiator" :title="snapshot ? snapshot.name : ''" :editable="true" :editMode="editMode"
        @editClick="onEditClick" @deleteClick="onDeleteClick" @saveClick="onSaveClick" @cancelClick="onCancelClick"
        @closeClick="$router.push(`/${$store.state.currentClient.cid}/aut/initiators`)" />
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="initiator">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <FormFields :fields="fields" v-model="initiator" :editMode="editMode" :inlineField="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import DetailHeader from "../../components/DetailHeader.vue";
import { mapActions } from "vuex";
import FormFields from "../../components/FormFields.vue";
import Fields from "../../utils/field-constants/initiatorCreateFields";
import { isDuplicate } from "../../utils/commonFunction";
export default {
    name: "Initiator",
    components: { DetailHeader, FormFields },
    data() {
        return {
            editMode: false,
            initiator: null,
            snapshot: null,
            error: null,
            fields: Fields,
            logo: null
        }
    },
    beforeMount() {
        this.fetchInitiator();
    },
    watch: {
        'initiator.logo': {
            handler: function (newval) {
                if (newval)
                    this.convertImgToBase64(newval)
                else this.logo = null
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            readInitiator: "aut/readInitiator",
            updateInitiator: "aut/updateInitiator",
            deleteInitiator: "aut/deleteInitiator",
            fetchInitiators: "aut/fetchInitiators",
            showModal: "showModal"
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.error = null;
        },
        async fetchInitiator() {
            let response = {}
            if (!this.$route.params.data) {
                try {
                    response = await this.readInitiator(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return
                }
            } else {
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.initiator = await response.data;
            this.snapshot = JSON.parse(JSON.stringify(this.initiator))
        },
        onCancelClick() {
            this.setEditMode(false);
            this.initiator = JSON.parse(JSON.stringify(this.snapshot));
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Initiator",
                    message: `Are you sure you want to delete ${this.initiator.name} ?`,
                    async confirm() {
                        try {
                            const response = await self.deleteInitiator(self.initiator);
                            self.$router.push(`/${self.$store.state.currentClient.cid}/aut/initiators`);
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return
                        }
                    }
                }
            })
        },
        async onSaveClick() {
            this.initiator.logo = this.logo;
            let valid = true;
            let validValue = true;
            let toastoptions;
            try {
                //Note: If Reload happen in view tag template then list of tag template wont available
                if (!this.$store.state.aut.initiators.length) {
                    await this.fetchInitiators().then(() => {
                        valid = !isDuplicate(this.initiator.name, this.$store.state.aut.initiators, "name", this.snapshot.name)
                        validValue = !isDuplicate(this.initiator.name, this.$store.state.aut.initiators, "value", this.snapshot.value)
                    })
                } else {
                    valid = !isDuplicate(this.initiator.name, this.$store.state.aut.initiators, "name", this.snapshot.name)
                    validValue = !isDuplicate(this.initiator.value, this.$store.state.aut.initiators, "value", this.snapshot.value)
                }
                if (valid && validValue) {
                    const response = await this.updateInitiator(this.initiator);
                    this.setEditMode(false);
                    this.snapshot = JSON.parse(JSON.stringify(this.initiator));
                    toastoptions = {
                        message: "Inititator '" + this.initiator.name + "' updated successfully.",
                        type: "success",
                    };
                } else {
                    if (!valid) {
                        toastoptions = {
                            message: "Already '" + this.initiator.name + "' name exits.'",
                            type: "error",
                        };
                    } else {
                        toastoptions = {
                            message: "Already '" + this.initiator.value + "' value exits.'",
                            type: "error",
                        };
                    }
                }
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
            this.$store.dispatch("addToast", toastoptions);
        },
        async convertImgToBase64(img) {
            if (typeof img !== "string") {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    this.logo = e.target.result;
                }
                reader.readAsDataURL(img);
            } else {
                this.logo = img;
            }
        }
    }
}
</script>
<style></style>