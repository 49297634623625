const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" },
            {type: "regex", pattern:"^[a-zA-Z0-9_]+$",
            errMsg:"Param name may contain only alphabets or numbers"}
        ]
    },
    {
        name: "value",
        label: "Value",
        type: "text",
        editable: true,
        
    },{
        name: "datatype",
        label: "Datatype",
        type: "select",
        editable: true,
        validation: [
            { type: "required", errMsg: "Datatype Field is required" },
        ],
        options:[
            { label: "string", value: "string" },
            { label: "int", value: "int" },
            { label: "bool", value: "bool" }
        ]  
    },
    {
        name: "rule",
        label: "Rule",
        type: "select",
        editable: true,
        options:[
            { label: "required", value: "required" },
            { label: "optional", value: "optional" },
            { label: "fixed", value: "fixed" }
        ],
        visible:true
    }
];
 
export  default Fields;