<template>
    <detail-header
        pretitle="Entitlements"
        :title="entitlement ? entitlement.name : '&nbsp;'"
        :editable="true"
        :editMode="editMode"
        :fields="fields"
        v-model="entitlement"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push('/admin/entitlements')"
    >
    </detail-header>
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
        </div>
        <div v-if="entitlement">
            <div class="row">
                <div class="col">
                    <div v-if="entitlement" class="card mb-5">
                        <div class="card-body">
                            <div class="row">
                                <form-fields
                                    :fields="fields"
                                    v-model="entitlement"
                                    :editMode="editMode"
                                    :inlineField="true"
                                    colField="col-6"
                                    />
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                               Entitlement mapped to {{entitlement.name}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <Datatable
                            :tableId="`${entitlement.name}Table`"
                            :columns="columns"
                            uniqueField ="entitlement_id"
                            :data="subEntitlementList"
                            :deletable="true"
                            :compact="true"
                            :selectCheckboxes="true"
                            :detailColumn="{baseUrl:'entitlements/', name: 'Entitlement', identifier:'entitlement_id'}"
                        />
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    </div>
</template>
<script>
import DetailHeader from '../../components/DetailHeader.vue';
import Datatable from '../../components/Datatable.vue';
import Fields from "../../utils/field-constants/entitlementFields";
import { mapActions } from 'vuex';
import FormFields from '../../components/FormFields.vue';
export default{
    name: "Entitlement",
    components: { DetailHeader, FormFields,Datatable },
    data(){
        return{
            editMode: false,
            entitlement: null,
            snapshot: null,
            error: null,
            successMessage:null,
            fields: Fields,
            columns:[
                { data: "name"},
                { data: "unit"},
                { data: "quantity"}

            ],
            subEntitlementList:[],
            loading:false
        }
    },
    computed:{
        entitlementList(){
            return this.$store.state.admin.entitlements
        }
    },
    watch:{
        "entitlement.entitlement_id":{
            handler: function(newVal){
                if(newVal){
                    this.fields.forEach((field)=>{
                        if(field.name=="base_entitlement_id"){
                            let options=[{label: "None", value: null}] 
                            this.entitlementList.forEach((item)=>{
                                if(item.entitlement_id !== newVal)
                                    options.push({label: item.name, value: item.entitlement_id})
                            })
                            field.options=options 
                        }
                    })
                }
            }
        }
    },
    beforeMount(){
        this.initialize();
    },
    methods:{
        ...mapActions({
            readEntitlement:"admin/readEntitlement",
            updateEntitlement:"admin/updateEntitlement",
            deleteEntitlement:"admin/deleteEntitlement",
            showModal: "showModal",
            getSubEntitlementList: "admin/getSubEntitlementList"
        }),
        setEditMode(mode){
            this.editMode =mode;
            this.successMessage= null;
            this.error= null;
        },
        async fetchEntitlement(reload=false){
            let response={};
            //force reload when to view base entitlement in detail page else load from cache if available
            if(!this.$route.params.data || reload){
                try{
                    response = await this.readEntitlement(this.$route.params.id);
                } catch (error){
                    if(error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.entitlement = await response.data;
            this.snapshot = JSON.parse(JSON.stringify(this.entitlement));
        },
        async fetchSubEntitlementList(entitlement_id){
            let response;
            try{
                response = await this.getSubEntitlementList(entitlement_id);
            } catch (error){
                if(error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }
            this.subEntitlementList = await (response? response.data :[]);
        },
        onCancelClick(){
            this.setEditMode(false);
            this.entitlement = JSON.parse(JSON.stringify(this.snapshot));
            this.error =null;
        },
        onEditClick(){
            this.setEditMode(true);
        },
        onDeleteClick(){
            const self= this;
            this.showModal({
                component: "confirm",
                data:{
                    title: "Delete Entitlement",
                    message: `Are you sure you want to delete ${this.entitlement.name}`,
                    async confirm(){
                        try{
                            const response = await self.deleteEntitlement(self.entitlement.entitlement_id);
                            self.$router.push("/admin/entitlements");
                            return response;
                        } catch( error){
                            if (error.response.data.error)
                            self.error= error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    }
                }
            })
        },
        async onSaveClick() {
            let self = this;
            let entitlementObj = this.entitlement;            
            try {
                const response = await this.updateEntitlement(entitlementObj);
                this.setEditMode(false);
                this.snapshot = JSON.parse(JSON.stringify(entitlementObj));
                this.successMessage = "Entitlement updated successfully.";
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
        },
        async initialize(reload=false){
            this.fetchEntitlement(reload);
            this.fetchSubEntitlementList(this.$route.params.id);
            if(!this.entitlementList.length){
                this.$store.dispatch("admin/fetchEntitlements");
            }
        },
    },
    async beforeRouteUpdate (to, from, next) {  
            if (to.params.id !== from.params.id) {
                this.loading=true;
                this.$route.params.id=to.params.id
                this.initialize(true);
                this.loading=false;    
            }
            next();       
        },
}
</script>
<style></style>