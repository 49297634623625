<template>
    <BasicHeader pretitle="aqfer Marketing Data Platform" title="Production Jobs" />
    <div class="container-fluid tab-container"  v-if="!loading && selectedSandbox">
        <input-field 
            v-if="sandBoxListOptions.length>1"
            label="Choose a Job Environment"
            type="select"
            :options="sandBoxListOptions"
            v-model="selectedSandbox"
        />
        <nav-header
            :navItems="[
            {
                    name: 'Jobs',
                    link: {
                        name: 'Jobs',
                        params: {
                            sandboxId: `${selectedSandbox}`
                        },
                    },
                },            
                {
                    name: 'Pipelines',
                    link: {
                        name: 'Pipelines',
                        params: {
                            sandboxId: `${selectedSandbox}`
                        },
                    },
                },
                {
                    name: `${$route.params.pipelineName}`,
                    link: {
                        name: 'Pipeline Details',
                        params: {
                            sandboxId: `${selectedSandbox}`,
                            pipelineName: ` `
                        },
                    },
                    closeTab: true,
                    disable: true,
                },]"             
            :breadcrumbTitle="false"
            @nav-close-clicked="closeNav"
        >             
        </nav-header>
        <div class="">
            <router-view
                :selectedSandbox="selectedSandbox"
            ></router-view>
        </div>
    </div>
    <div v-else-if="!loading && !sandBoxListOptions.length" class="container-fluid" >
        <div class="alert alert-secondary" role="alert">
            No Sandboxes available!
        </div>        
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
     
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue';
import InputField from "../../components/InputField.vue";
import NavHeader from "../../components/NavHeader.vue";
export default {
    components: { NavHeader, BasicHeader ,InputField },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        selectedSandbox: {
            get() {
                return this.$store.state.sandbox.currentSandbox;   
            },
            set(value) { 
                this.$store.dispatch('sandbox/setCurrentSandbox',value); 
                if(this.$route.name == "Pipelines") {
                    this.$router.push({
                        name: "Pipelines",
                        params: {
                            sandboxId: `${value}`
                        },
                    });  
                } else {
                    this.$router.push({
                        name: "Jobs",
                        params: {
                            sandboxId: `${value}`
                        },
                    }); 

                }
                     
            }
        },
        sandBoxListOptions() {
            let prodAndProdv2SandboxList = this.$store.state.sandbox.prodSandboxes.concat(this.$store.state.sandbox.prodV2Sandboxes);
            return prodAndProdv2SandboxList? prodAndProdv2SandboxList.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
        }             
    },
    methods: {       
        setCurrentSandbox(sandbox){
            this.$store.dispatch('sandbox/setCurrentSandbox',sandbox)
        },
        closeNav() {
            this.$router.push({
                name: "Pipelines",
                params: {
                    sandboxId: `${this.selectedSandbox}`
                },
            });   
		},
    },     
    beforeMount() {
        if(this.$route.path.indexOf('/items') == -1 && this.$route.path.indexOf('/pipelines') == -1) {
            this.$router.push({
                        name: "Jobs",
                        params: {
                            sandboxId: `${this.$store.state.sandbox.currentSandbox}`
                        },
                    });     

        }
        
    },
    
}
</script>

<style>

</style>