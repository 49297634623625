<template>
	<div
		style="height: 100vh"
		class="
			d-flex
			align-items-center
			bg-auth
			border-top border-top-2 border-primary
		"
	>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col my-5">
					<div class="text-center">
						<!-- Preheading -->
						<h6 class="text-uppercase text-body-secondary mb-4">
							<i
								class="
									fa fa-lock
									mx-auto
									d-block
									mb-5
									text-warning
								"
								style="font-size: 5em"
							></i>
						</h6>

						<!-- Heading -->
						<h1 class="display-4 mb-3">
							You dont have permission to view this page.
						</h1>

						<!-- Subheading -->
						<h3 class="text-body-secondary mb-4">
							Please contact support.
						</h3>
					</div>
				</div>
			</div>
			<!-- / .row -->
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style></style>
