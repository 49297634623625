const Fields = [
    {
        name: "user",
        label: "User Name",
        type: "text",
        editable: false,
        visible:false
    },
    {
        name: "email",
        label: "Email",
        type: "text",
        editable: false,
        validation: [
            { type: "email"}, 
            { type: "required" }
        ]
    }, {
        name: "cids",
        label: "CIDs",
        type: "multiselect",
        editable: true,
        validation: [
            { type: "required" }
        ]

    },{
        name: "roles",
        label: "Roles",
        type: "multiselect",
        editable: true,
        validation: [
            { type: "required" }
        ],
        options:["access_token_validator","aqfer_admin","aqfer_user","client_admin","client_user"]
    },
    {
        name: "cc",
        label: "Client Config",
        type: "text",
        editable: true
    },
    {
        name: "purpose",
        label: "Purpose",
        type: "text",
        editable: false,
        visible:false
    },
    {
        name: "valid_from",
        label: "Valid From",
        type: "text",
        editable: false,
        visible:false,
        format:"datetime"
    },
    {
        name: "valid_until",
        label: "Valid Until",
        type: "text",
        editable: false,
        visible:false,
        format:"datetime"
    },
    {
        name: "revoked_at",
        label: "Revoked at",
        type: "text",
        editable: false,
        visible:false,
        format:"datetime"
    },

];

export default Fields;

