<template>
    <div class="card">
      <Datatable
        tableId="templateGroupsTable"
        uniqueField="id"
        :columns="columns"
        :data="templateGroups"
        :compact="true"
        :selectCheckboxes="true"
        :orderColumn="orderColumn"
        :formatColumn="formatColumn"
        :deletable="true"
        @deleteRows="showDeletePage"
        :detailColumn="{
          baseUrl: 'job-template-groups/',
          identifier: 'id',
          name: 'Job Template Group',
        }"
      />
    </div>
  </template>
  
  <script>
  import Datatable from "../../components/Datatable.vue";
  import { mapActions } from "vuex";
  
  export default {
    name: "JobTemplateGroups",
    components: { Datatable },
    data() {
      return {
        columns: [
          { data: "name" },
          { data: "type" },
          { data: "created_by", title: "Created by" },
          { data: "created_at", title: "Created at" },
        ],
        orderColumn: [5, "desc"],
        //Note: index - table column index which need to formatted
        formatColumn: [
          {
            index: 5,
            type: "datetime",
          },
        ],
        loading: false,
      };
    },
    computed: {
      templateGroups() {
        return this.$store.state.admin.jobTemplateGroups;
      },
    },
    beforeMount() {
      this.$store.dispatch("admin/fetchJobTemplateGroups");
    },
    methods: {
      ...mapActions({
        showModal: "showModal",
      }),
      showDeletePage(rows) {
        this.showModal({ component: "DeleteJobTemplateGroup", data: rows });
      },
    },
  };
  </script>
  
  <style scoped></style>
  