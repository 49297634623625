<template>    
    <div v-if="executorsDetail && !loading">
        <pre class="mb-0 executor-logs"><code>{{ executorsDetail.Logs}}</code></pre>
    </div>
    <div v-else-if="loading" class="text-center"> 
        <div class="spinner-border text-center" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    data() {
        return {
            executorsDetail: null,
            loading: false
        };
    },
    props: ["sandboxName", "jobName", "executionId", "executorsId", "jobStatus"],
    beforeMount() {
        this.getExecutorLogs();
    },
    watch: {
        executorsId() {
            this.executorsDetail = null;
            this.getExecutorLogs();
        },
    },
    computed: { 
        ...mapState({
            executorLogs: (state) => state.sandbox.executorLogs
        })       
    },
    methods: {
        async getExecutorLogs() {            
                let response = {};
                this.loading = true;
                
                if(this.jobStatus !== "running" && this.executorLogs[this.executorsId] ) {
                    response.data = this.executorLogs[this.executorsId];
                } else {
                    response = await this.getExecutorLogDetails(this.executorsId); 
                    this.$store.dispatch('sandbox/setExecutorLogs',{executorId: this.executorsId, executorLogObj: response.data});  
                } 
                this.executorsDetail = response.data;
                this.loading=false;                      
        },

        getExecutorLogDetails(executorId) {
            return axios
                .get(
                    `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.sandboxName}/jobs/${this.jobName}/executions/${this.executionId}/logs?executor_id=${executorId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        },
        addStatusIcon(status) {
            let statusStr = "";
            if (status.toLowerCase() === "complete") {
                statusStr = "<span class='text-success'>●</span> ";
            } else if (status.toLowerCase() === "failed") {
                statusStr = "<span class='text-danger'>●</span> ";
            } else if (status.toLowerCase() === "in_progress") {
                statusStr = "<span class='text-warning'>●</span> ";
            } else if (status.toLowerCase() === "running") {
                statusStr = "<span class='text-primary'>●</span> ";
            } else if (status.toLowerCase() === "cancelled") {
                statusStr = "<span class='text-secondary'>●</span> ";
            }

            return statusStr + status;
        },
    },
};
</script>

<style scoped>
.executor-logs {
    max-height: 50vh;
    border: 1px;
}

button.acc-btn {
    /* create a grid */
    display: grid;
    /* create colums. 1fr means use available space */
    grid-template-columns: 1fr max-content max-content;
    grid-gap: 10px;
}
</style>
