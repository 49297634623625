<template>
    <div class="datatable-outer-container">
        <table
            :id="tableId"
            :class="[
                'table table-hover table-responsive',
                'card-table',
                compact ? 'compact' : '',
            ]"
            style="width: 100%"
        >
            <thead>
                <tr>
                    <th v-if="selectCheckboxes" style="width: 30px">
                        <a style="cursor: pointer" @click="selectAllClicked"
                            ><i :class="selectAllIconClass"></i
                        ></a>
                    </th>
                    <th v-if="detailColumn" style="width: 50px"></th>
                    <th v-for="column in columns" :key="column.index" :class="captializeHeader? '':'no-captialize'">
                        {{ column.data }}
                    </th>
                    <th v-if="rowReorder">Order</th>
                    <th v-if="rowDelete">Delete</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
    </div>
</template>

<script>
import jQuery from "jquery"
import DataTable from "datatables.net";
import { DateTimeFormat, formatBytes} from '../utils/commonFunction'
import dayjs from "dayjs";
import "datatables.net-bs5";
import "datatables.net-select-bs5";
import "datatables.net-buttons-bs5";
jQuery.fn.dataTable.Api.register( 'processing()', function ( show ) {
    return this.iterator( 'table', function ( ctx ) {
        ctx.oApi._fnProcessingDisplay( ctx, show );
    } );
} );

//suppress 'alert' errors
DataTable.ext.errMode = "console";
//pagination buttons to max of 5
DataTable.ext.pager.numbers_length = 5;

const zeroRecordsString = '<div>No records found...</div>';
const processingString = '<div class="spinner-border spinner-border-sm text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>';

export default {
    name: "Datatable",
    data() {
        const self = this;
        let buttons = [];
        if (this.deletable) {
            buttons.push({
                extend: "selected",
                text: this.deleteTxt,
                action: function (e, dt, node, config) {
                    self.$emit("deleteRows", self.getSelectedRows());
                },
            });
        }
        if (this.enableCSVExport) {
            buttons.push({
                extend: "selectAll",                
                text: "Export CSV",
                action: function (e, dt, node, config) {
                    self.$emit("exportCSV", self.getFilteredRows());
                },
            });
        }

        let order = [];
        let orderColumn = 0;
        let searchValue ="";
        let pageStart = 0;
        let pageLength =10;
        let tableColumns = [...this.columns];
        let cacheData =null;
        let select = this.select;

        if (this.detailColumn) {
            orderColumn++;
            order = [[orderColumn, "asc"]];
            tableColumns.unshift({
                data: null,
                orderable: false,
                className: "edit-row",
                render: function (data, type, full, meta) {
                    let url = self.detailColumn.baseUrl
                            ? self.detailColumn.baseUrl
                            : ((self.detailColumn.renderBaseUrl)?self.detailColumn.renderBaseUrl(data):"");
                    url += self.detailColumn.identifier?data[self.detailColumn.identifier]:""; 
                    return `<a class="detail-btn btn btn-mini btn-light" href=${url}><i class="detail-icon fa fa-regular fa-${self.detailColumn.icon? self.detailColumn.icon: 'window-restore' }"></i></a>`;
                },
            });
        }
 
        if (this.selectCheckboxes) {
            orderColumn++;
            order = [[orderColumn, "asc"]];
            select = {
                style: "multi+shift",
                selector: "td:first-child",
            };
            tableColumns.unshift({
                orderable: false,
                className: "select-row fa fa-regular select-checkbox-fa",
                targets: 0,
            });
        }
        if(this.viewModalColumn){
                tableColumns[this.viewModalColumn]={
                data: null,
                defaultContent: `<span class="btn viewModal"><i class="detail-icon fa-solid fa-circle-info"></i></span>`,
            }  
        } 
        if(this.multiselectColumn && this.multiselectColumn.length){
           this.multiselectColumn.forEach(col=>{      
                tableColumns[col]["render"]=function ( data ,type ) {
                    let tags =data? data.split(","):[]
                    let tag = tags.map((item)=> `<span :key="${item}" class="badge rounded-pill" style= "background-color:#d5e5fa;color:#2c7be5">${item}</span>`)
                    return tag.join(" ");
                }
            })
        } 
        if(this.orderColumn && this.orderColumn.length) {
            order = [this.orderColumn];
        }
        if(this.formatColumn && this.formatColumn.length) {
            let self=this;
            this.formatColumn.map((col)=>{
            if(tableColumns[col.index]) {
                tableColumns[col.index]["render"]=function ( data ,type ) {
                    //If table column is need to sorting then pass original data then only sorting works
                    return type === 'sort' ? data : self.formatData(data, col.type)
                }
            }
            
           })   
        }
        if(this.$store.state.datatableCache[this.tableId]){
            let cache=this.$store.state.datatableCache[this.tableId]
            let sortList=[];
            cache["sorting"].map((val)=>{
                sortList.push(Object.values(val))
            })
            order=sortList;
            searchValue=cache["searchValue"];
            pageStart=cache["pageDetail"].page;
            pageLength=cache["pageDetail"].length;
            if (cache["tableData"]){
                    cacheData = cache["tableData"]
            }
        } 
        if (this.rowReorder) {
            tableColumns.push({
                data: null,
                orderable: false,
                render: function (data, type, full, meta) {
                    return `<button class="btn moveUp"><i class="fa fa-chevron-up moveUp"></i></button>&nbsp;<button class="btn moveDown"><i class="fa fa-chevron-down moveDown"></i></button>`;
                },
            });
        }
        if (this.rowDelete) {
            tableColumns.push({
                data: null,
                orderable: false,
                render: function (data, type, full, meta) {
                    return `<button class="btn rowDelete"><i class="fa fa-trash rowDelete"></i></button>`;
                },
            });
        }
        return {
            selected: null,
            selectAllIconClass: "fa fa-regular fa-square",
            tableColumns: tableColumns,
            tableSettings: {
                processing: cacheData ? false : ( this.showLoading !== null ? this.showLoading : true ),
                dom:
                    "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6'B>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'li><'col-sm-12 col-md-7'p>>", //order of displayed components, length, filter, reprocessing, table, info, pagination, Buttons
                searching: this.searching,
                paging: this.paging,
                info: this.info,
                data: cacheData ? cacheData: this.data,
                lengthChange: this.lengthChange,
                language: {
                    paginate: {
                        previous: "<i class='fa fa-chevron-left' />",
                        next: "<i class='fa fa-chevron-right' />",
                    },
                    zeroRecords: zeroRecordsString,
                    processing: processingString,
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },
                select: select,
                buttons: buttons,
                columns: tableColumns,
                order: order,
                scrollX: this.scrollX,
                "rowCallback": function(e, dt, type, indexes){
                       self.$emit("rowCallback", e, dt, type, indexes);     
                },
                "fnDrawCallback": function( oSettings ) {
                    if(self.table){
                        self.setDatatableCache(oSettings)
                    }
                },
                "createdRow": function( row, data, dataIndex ) {
                    //Checking which data need to be highlighted based on cache unique field value
                   if( self.$store.state.datatableCache[self.tableId] && self.$store.state.datatableCache[self.tableId].data && data[self.uniqueField] === self.$store.state.datatableCache[self.tableId].data[self.uniqueField]){
                        jQuery(row).addClass('highlight')
                    } 
                },
                search:{
                    search:searchValue
                },
                pageLength: pageLength,
            },
            pageStart: pageStart
        };
    },
    props: {
        data: {
            type: Array,
            default: null
        },
        columns: Array,
        orderColumn: Array,
        formatColumn: Array,
        selectedItem: Object,
        tableId: {
            type: String,
            default: "datatable",
        },
        searching: {
            type: Boolean,
            default: true,
        },
        info: {
            type: Boolean,
            default: false,
        },
        paging: {
            type: Boolean,
            default: true,
        },
        detailColumn: {
            type: Object,
            default: null,
        },
        selectCheckboxes: {
            type: Boolean,
            default: false,
        },
        select: {
            type: [Boolean, Object],
            default: true,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        enableCSVExport: {
            type: Boolean,
            default: false,
        },
        lengthChange: {
            type: Boolean,
            default: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        scrollX: {
            type: Boolean,
            default: false,
        },
        showLoading: {
            type: Boolean,
            default: null
        },
        deleteTxt: {
            type: String,
            default: "Delete",
        },
        dropDownFilterColumn:{
            type: Array,
            default: function(){
                return []
            }
        },
        ignoreCache: {
            type: Boolean,
            default: false,
        },
        uniqueField: {
            type: String,
            default: "id"
        },
        rowClick:{
            type: Function,
            default: null
        },
        captializeHeader:{
            type: Boolean,
            default: true,
        },
        viewModalColumn: {
            type: Number,
            default: null
        },
        rowReorder: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        multiselectColumn: {
            type: Array,
            default: function(){
                return []
            }
        },
        rowDelete: {
            type: Boolean,
            default: false
        },
    },
    emits: ["selected", "deleteRows", "rowCallback", "viewModal", "exportCSV", "onMoveUp", "onMoveDown","onRowDelete"],
    methods: {
        selectAllClicked() {
            var all = this.table.rows({ search: "applied" }).count(); // get total count of rows
            var selectedRows = this.table
                .rows({ selected: true, search: "applied" })
                .count(); // get total count of selected rows

            if (selectedRows < all) {
                //Added search applied in case user wants the search items will be selected
                this.table.rows({ search: "applied" }).deselect();
                this.table.rows({ search: "applied" }).select();
                this.selectAllIconClass = "fa fa-regular fa-square-check";
            } else {
                this.table.rows({ search: "applied" }).deselect();
                this.selectAllIconClass = "fa fa-regular fa-square";
            }
        },
        initTable() {
            const self = this;
            let settings = this.tableSettings;
            this.table = new DataTable("#" + this.tableId, settings);
            this.table.processing(true)
            
            //setup listener to emit a row that is selected
            if (this.select) {
                this.table.on("select", function (e, dt, type, indexes) {
                    if (type === "row") {
                        var data = self.table
                            .rows({ selected: true })
                            .data()[0];
                        self.$emit("selected", data);
                        self.selected = data;
                    }
                });
                this.table.on("deselect", function (e, dt, type, indexes) {
                    self.selected = null;
                });
            }
            //select row if parent view is asking for one to be selected initially
            if (this.selectedItem) {
                this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
                    const item = this.data();
                    if (item === self.selectedItem) {
                        this.select();
                    }
                });
            }
            //setup listeners for selectall
            if (this.selectCheckboxes) {
                this.table.on("select deselect", function () {
                    var all = self.table.rows({ search: "applied" }).count(); // get total count of rows
                    var selectedRows = self.table
                        .rows({ selected: true, search: "applied" })
                        .count(); // get total count of selected rows

                    if (selectedRows < all) {
                        self.selectAllIconClass = "fa fa-regular fa-square-minus";
                    } else if (selectedRows == all) {
                        self.selectAllIconClass = "fa fa-regular fa-square-check";
                    } else {
                        self.selectAllIconClass = "fa fa-regular fa-square-check";
                    }
                });
            }
            //setup listener to trigger the router if editable cell is clicked
            if (this.detailColumn|| this.viewModalColumn) {
                const openDetail = (data) => {
                    //Storing the selected data in local cache
                    self.storeSelectedData(data,"data")
                    //on detail page click if you want to open modal
                    if(self.detailColumn.openModal){
                        self.$emit("viewModal",data);
                        return;
                    }
                    let url = self.detailColumn.baseUrl
                        ? self.detailColumn.baseUrl
                        : ((self.detailColumn.renderBaseUrl)?self.detailColumn.renderBaseUrl(data):"");
                    //Identifer name is used to set url param value eg: id, name, hostName which should be set on url path
                        url += self.detailColumn.identifier?data[self.detailColumn.identifier]:"";    
                        let path={ 
                                path: url, name: self.detailColumn.name,  
                                params: { 
                                    [self.detailColumn.identifierName ?self.detailColumn.identifierName :"id"]:(data[self.detailColumn.identifier]!=""?data[self.detailColumn.identifier]:"slash"), //to handle / folder name
                                    data: JSON.stringify(data) 
                                    }
                            }
                        //For breadcrumbs - adding the route in store
                        if(self.detailColumn.breadcrumbs){
                            self.$store.dispatch("setBreadcrumbs",[...this.$store.state.breadcrumbs,{
                                name:  self.detailColumn.identifier? (data[self.detailColumn.identifier]!=""?data[self.detailColumn.identifier]:"/"): data.name, //to handle / folder name
                                route:  path
                            }])
                        }
                        self.$router.push(path);
                };
                this.table.on("click", function (e, dt, type, indexes) {
                    e.preventDefault();
                    if (e.target.className.indexOf("detail-icon") !== -1) {
                        e.target = e.target.parentNode
                    }
                    if (e.target.className.indexOf("detail-btn") !== -1) {  
                        var data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        openDetail(data);
                    }else if(e.target.className.indexOf("viewModal") !== -1){
                        let data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        self.$emit("viewModal",data)
                    }
                });
                this.table.on("dblclick", function (e, dt, type, indexes) {
                    var data = self.table
                            .row(e.target.parentNode)
                            .data();
                    openDetail(data)
                });
            } 
            if(this.rowClick){
                this.table.on("click", function (e, dt, type, indexes) {
                    if(self.table.row(e.target.closest('tr')).index()>=0)
                        self.rowClick(self.table.row(e.target.closest('tr')).index())
                });
            }
            if(this.rowReorder){
                this.table.on("click", function (e, dt, type, indexes) {
                   if(e.target.className.indexOf("moveUp") !== -1){
                        let data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        self.$emit("onMoveUp",data)
                    }else if(e.target.className.indexOf("moveDown") !== -1){
                        let data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        self.$emit("onMoveDown",data)
                    }
                });
            }
            if(this.rowDelete){
                this.table.on("click", function (e, dt, type, indexes) {
                   if(e.target.className.indexOf("rowDelete") !== -1){
                        let data = self.table
                            .row(e.target.parentNode.parentNode)
                            .data();
                        self.$emit("onRowDelete",data)
                    }
                });
            }
        },
        getFilteredRows(){
            let filteredRows = this.table.rows({search: 'applied'}).data();
            var rows = [];
            for (var i = 0; i < filteredRows.length; i++) rows.push(filteredRows[i]);
            return rows;

        },
        getSelectedRows() {
            var selected = this.table.rows({ selected: true }).data();
            var rows = [];
            for (var i = 0; i < selected.length; i++) rows.push(selected[i]);
            return rows;
        },
        formatData(data,type){
            //Note: In future can add more Data Format
            switch(type){
                case 'datetime':
                    return DateTimeFormat(data)
                case 'date':
                    return  dayjs(data).format("YYYY-MM-DD")
                case 'bytes' :
                    return formatBytes(data)
            }
        },
        //Setting cache value in store
        setDatatableCache(settings) {
            if(this.ignoreCache)
                return;
            let cache = this.$store.state.datatableCache;
            if (!cache[this.tableId]) {
                cache[this.tableId] = {}
            }
            cache[this.tableId]["searchValue"] = this.table.search()
            cache[this.tableId]["pageDetail"] = this.table.page.info()
            if (settings)
                cache[this.tableId]["sorting"] = settings.aaSorting
            else
                cache[this.tableId]["sorting"] = []
            if(this.data){
                cache[this.tableId]["tableData"]=this.data 
            }
            this.$store.dispatch('setDatatableCache', cache)
        },
        //function for storing selected data and drop downfilter value
        storeSelectedData(data, type) {
            if(this.ignoreCache)
                return;
            let cache = this.$store.state.datatableCache;
            if (!cache[this.tableId]) {
                cache[this.tableId] = {
                    sorting: this.orderColumn ? [this.orderColumn] : [],
                    searchValue: this.table.search(),
                    pageDetail: this.table.page.info()
                }
            }
            if (type == "data")
                cache[this.tableId]["data"] = data
            else if (type == "filter") {
                if (!cache[this.tableId]["filter"]) {
                    cache[this.tableId]["filter"] = {}
                }
                cache[this.tableId]["filter"][data.title] = data
            }
            this.$store.dispatch('setDatatableCache', cache)
        },
        setEditable(val){
            jQuery( ".moveUp" ).prop( "disabled", !val );
            jQuery( ".moveDown" ).prop( "disabled", !val );
        }
    },
    watch: {
        showLoading(newVal) {
            this.table.processing(newVal)
        },
        // whenever data changes, this will run
        data(newData, oldData) {
            if (!this.table)
                return;
            //refresh all rows
            //this.table.clear()
            this.table.clear()
            if (Array.isArray(newData)) {
                if (newData.length) {
                    this.table.rows.add(newData);
                }
            }
            this.table.draw();
            if (this.showLoading)
                this.table.processing(this.showLoading);
            else 
                this.table.processing(false);
            if(this.pageStart){
                this.table.page(this.pageStart).draw(false);
            }
            //Based on Column number Dropdown filter will be created 
           if(this.dropDownFilterColumn.length>0){
                let givenColumns=this.tableColumns;
                let self= this;
                let cache= self.$store.state.datatableCache[self.tableId];
                this.table.columns(this.dropDownFilterColumn).every(function (index) {
                    let column = this;
                    const filterExists = jQuery('.dataTables_filter').find('.form-select').length?true:false;
                    let select = jQuery('.dataTables_filter.form-select');
                    if(!filterExists) {
                         //Creating Dynamic classname for dropdown filter
                        jQuery('<label class="dropDownLabel'+index+' p-2" value="job"></label')
                                        .appendTo(jQuery('.dataTables_filter'))
                                        //Appending the select drop down to dynamic label
                        select = jQuery('<select class="form-select form-select-sm" ><option value="">Select '+givenColumns[index].title+'</option></select>')
                                    .appendTo(jQuery('.dropDownLabel'+index))
                                    .on('change', function () {
                                        var val = jQuery.fn.dataTable.util.escapeRegex(jQuery(this).val());
                                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                                        self.storeSelectedData({index:index,search:val,option:jQuery(this).val(),title:givenColumns[index].title},"filter")
                                });

                    }
                   
                    
                    
                    //Getting unique options from column value
                    column.data( index,'sort')
                          .unique()
                          .sort()
                          .each(function (data) {
                            let option='<option value="' + data + '">' + data + '</option>'
                            if(cache && cache.filter){
                                if (cache.filter[givenColumns[index].title] && cache.filter[givenColumns[index].title].option == data){
                                    //Set option has selected and search that value
                                     option='<option selected value="' + data + '">' + data + '</option>';
                                     column.search(cache.filter[givenColumns[index].title].search ? '^' + cache.filter[givenColumns[index].title].search + '$' : '', true, false).draw();
                                }
                            }
                                select.append(option);
                           });
                        if(!filterExists) {                            
                            select.appendTo('</label>')  
                        }       
                });
            }

            if (this.selectedItem) {
                var self = this
                this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
                    const item = this.data();
                    if (item === self.selectedItem) {
                        this.select();
                    }
                });
            }
            if(this.rowReorder){
                this.setEditable(this.editable)
            }
            // //re-initialize table
            // if (this.table) {
            //     //remove our event listeners
            //     //IMPORTANT otherwise events will attach to new table instance
            //     this.table.off("select deselect click");
            //     //destroy the table instance
            //     this.table.destroy();
            // }
            // this.tableSettings.oLanguage = null;
            // // if null is passed in as the data, the loader will show
            // if (newData === null) {
            //     this.tableSettings.language.zeroRecords = zeroRecordsString;
            //     newData = []
            // } else if (!newData.length)
            //     this.tableSettings.language.zeroRecords = "<div></div>";
            
            // this.tableSettings.data = newData;
            // this.initTable();
            
        },
        editable(newVal){
            this.setEditable(newVal)
        }
    },
    beforeUnmount() {
        if (this.table) {
            this.table.clear()
            this.table.destroy();
        }
    },
    mounted() {
        this.initTable();
        if(this.rowReorder){
            this.setEditable(false)
        }
    },
};
</script>

<style lang="scss">
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "~datatables.net-select-bs5/css/select.bootstrap5.min.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
    background-color: #2c7be5;
}
.dataTables_filter .form-select-sm{
    line-height: 1.75!important;
}
.highlight{
    background-color: #d6e0ef;
}

.no-captialize{
    text-transform:none!important;
}
.viewModal{
    color:#7e97b7;
}
.rowDelete{
    color:#95aac9;
}
</style>