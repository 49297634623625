<template>
    <basic-header pretitle="Administration" title="Tokens" buttonText="Add Token"
        @btnClick="showModal({ component: 'GenerateToken', data: {}  })" />
    <div class="container-fluid">
        <Tabs :tabs="tabItems">
            <template v-slot:clienttokens>
                <div class="row" v-if="!loading">
                    <div class="col-md-12">
                        <div class="row" id="cls-dtl-div">
                            <div class="col-md-12">
                                <div class="card">
                                        <Datatable 
                                            tableId="clientTokensTable" 
                                            :columns="columns"
                                            :data="clientTokens" :compact="true" 
                                            :selectCheckboxes="true"
                                            :orderColumn="orderColumn"  
                                            :formatColumn="formatColumn"
                                            :deletable="true"
                                            @deleteRows="showRevokeTokens"
                                            deleteTxt="Revoke"
                                            :detailColumn="{ baseUrl: 'tokens/', name: 'Token', identifier: 'id' }" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-md-12">
                        <div class="text-center mt-5">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:alltokens>
                <div class="row" v-if="!loading">
                    <div class="col-md-12">
                        <div class="row" id="cls-dtl-div">
                            <div class="col-md-12">
                                <div class="card">
                                        <Datatable 
                                            tableId="tokensTable" 
                                            :columns="columns"
                                            :data="allTokens" :compact="true" 
                                            :selectCheckboxes="true"
                                            :orderColumn="orderColumn"  
                                            :formatColumn="formatColumn"
                                            :deletable="true"
                                            @deleteRows="showRevokeTokens"
                                            deleteTxt="Revoke"
                                            :detailColumn="{ baseUrl: 'tokens/', name: 'Token', identifier: 'id' }" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-md-12">
                        <div class="text-center mt-5">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>   
        </Tabs>
    </div>
</template>

<script>
import Tabs from "../../components/Tabs.vue";
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";

export default {
    name: "Tokens",
    components: { BasicHeader, Datatable, Tabs },
    data() {
        return {
            tabItems: [],
            columns: [
                { data: "user" },
                { data: "email" },
                { data: "valid_from", title: " Valid From" },
                { data: "valid_until", title: " Valid Until" },
                { data: "status"}
            ],
            orderColumn: [4, "desc"],
            //Note: index - table column index which need to formatted
            formatColumn:[
                {
                    index:4,
                    type:"datetime"
                },
                {
                    index:5,
                    type:"datetime"
                }
            ],
            loading: false,
            selectedItem:[]
        };
    },
    computed:{
        allTokens(){
            let tokendata=[]
            if (this.$store.state.admin.tokens){
                this.$store.state.admin.tokens.map((token)=>{
                    let status= "<span class='text-success'>●</span> " + "Active"
                    if(token.revoked_at!==null){
                        status = "<span class='text-warning'>●</span> "+"Revoked";
                    }else if(new Date()>new Date(token.valid_until)){
                        status = "<span class='text-danger'>●</span> "+"Expired";
                    }
                    token["status"] = status
                    tokendata.push(token);
                })
                return tokendata;
            }
            return []
        },
        clientTokens(){
            let tokendata=[]
            if (this.$store.state.admin.clientTokens){
                this.$store.state.admin.clientTokens.map((token)=>{
                    let status= "<span class='text-success'>●</span> " + "Active"
                    if(token.revoked_at!==null){
                        status = "<span class='text-warning'>●</span> "+"Revoked";
                    }else if(new Date()>new Date(token.valid_until)){
                        status = "<span class='text-danger'>●</span> "+"Expired";
                    }
                    token["status"] = status
                    tokendata.push(token);
                })
                return tokendata;
            }
            return []
        }
    },
    beforeMount() {
        //For client_admin and aqfer_admin client token is first tab
        this.$store.dispatch("admin/fetchClientsAllRefreshTokens");
        this.tabItems.push({
                    name: this.$store.state.currentClient.cid+" Tokens",
                    id: "id1",
                    slot: "clienttokens"
        })
        //only for aqfer_admin all token will be visible
        if(this.$store.getters.userRoles.isAqferAdmin){
            this.tabItems.push({
                    name: "All Tokens",
                    id: "id2",
                    slot: "alltokens"
                })
             this.$store.dispatch("admin/fetchAllRefreshTokens");
        }  
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        showRevokeTokens(rows) {
            //Removing Revoked and Expired tokens
            rows= rows.filter((row)=>{
                return row.status.includes("Active")
            })
            if(rows.length){
                this.showModal({ component: "RevokeTokens", data: rows });
            }else{
                let toastoptions = {
                        message: "Only Active token can be revoked!",
                        type: "error"
                }
                this.$store.dispatch('addToast', toastoptions);
            }
        },
    },
};
</script>

<style scoped>

</style>