<template>
    <div  class="container-fluid">
        <nav-header
            :navItems="[
                { name: 'Sign Ins', link: '/admin/reports/sign-ins' },
            ]"
            pretitle="Administration"
            title="Reports"
        />
        <div>
            <router-view></router-view>
        </div>
    </div>    
</template>

<script>
import NavHeader from "../../../components/NavHeader.vue";
export default {
    components: { NavHeader },
};
</script>

<style>
</style>