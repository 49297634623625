<template>
  <ul v-if="!hideTabs && Object.keys(yamlContent).length > 0" class="nav nav-tabs">
    <li v-for="(val, key) in yamlContent" :key="key" class="nav-item">
      <button
        @click="tabChange(`${key}`)"
        :class="[activeYamlTab == `${key}` ? 'active' : '', 'nav-link']"
      >
        {{ key }}
      </button>
    </li>
  </ul>
  <div class="tab-content" :id="jobType+'TabContent'">
    <div
      v-for="(val, key) in yamlContent"
      :key="key"
      :class="[activeYamlTab == `${key}` ? 'show active' : '', 'tab-pane fade']"
    >
    <div v-if="key == 'edge_extract.yaml'">
      <div class="card">
        <div class="card-body p-0">
          <div class="btn-group float-end pt-2 view-toggle-btn" role="group" aria-label="Basic radio toggle button group">
            <button  class="btn " :class="isEditorView?'btn-primary':'btn-default btn-light'" @click="toggleView">
                <span class="fa fa-code"></span>
              </button>
              <button  class="btn " :class="!isEditorView?'btn-primary':'btn-default btn-light'" @click="toggleView">
                <span class="fa fa-rectangle-list"></span>
              </button>
            </div>
          <div v-if="isEditorView" class="editor-view">
              <codemirror-editor
              v-on:lintFailure="onLintFailure"
              :editorId="key"
              :performLint="key !== 'vars.yaml'"
              :mode="key.split('.').pop()"
              v-model="yamlContent[key]"
              :readOnly="!editMode"
              :customLint="lint" :customLintErrors="lintErrors"
              :advancedProps= "advancedProps&&advancedProps[key]?advancedProps[key]:[]"
              :refreshOn="activeYamlTab == `${key}`"
            />
        </div>
          <div v-else>
            <easy-mode
              v-model="yamlContent[key]"
              :editMode="editMode"
            >
            </easy-mode>
          </div>
              
        </div>
      </div>
    </div>
    <div v-else>
      <codemirror-editor
            v-on:lintFailure="onLintFailure"
            :editorId="key"
            :performLint="key !== 'vars.yaml'"
            :mode="key.split('.').pop()"
            v-model="yamlContent[key]"
            :readOnly="!editMode"
            :customLint="lint" :customLintErrors="lintErrors"
            :advancedProps= "advancedProps&&advancedProps[key]?advancedProps[key]:[]"
            :refreshOn="activeYamlTab == `${key}`"
          />
    </div>
    
      
    </div>
  </div>
</template>

<script>
import CodemirrorEditor from "../CodemirrorEditor.vue";
import EasyMode from "../EasyMode.vue"
export default {
  components: { CodemirrorEditor , EasyMode},
  data() {
    return {
      isEditorView: true,
      activeYamlTab: this.activeTab,
      lint:this.customLint,
      lintErrors: this.customLintErrors
    };
  },
  watch: {
    activeTab() {
      this.activeYamlTab = this.activeTab
    },
    customLint: function(newVal, oldVal) { // watch it
          this.lint= newVal
    },
    customLintErrors: function(newVal, oldVal) { // watch it
          this.lintErrors=newVal
    }
  },
  computed: {
    yamlContent: {
      get() {
        return this.modelValue;
      },
    },
  },
  props: {
    iframeSrc: String,
    yamlList: Array,
    modelValue: Object,
    activeTab: String,
    jobType: String,
    advancedProps: {
        type: Object,
        default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    hideTabs: {
      type: Boolean,
      default: false
    },
    customLint:{
            type: Boolean,
            default: false
    },
    customLintErrors: null
  },
  methods: {
    onLintFailure(isError) {
      this.$emit("onLintFailure", isError);
    },
    tabChange(tab) {
      this.activeYamlTab = tab;
      this.$emit("tabChange", tab);
    },
    toggleView() {
      this.isEditorView = !this.isEditorView;
    }
  },
  emits: ['onLintFailure', 'tabChange']
};
</script>

<style scoped>
.view-toggle-btn {
  position: absolute;
  right:10px;
  z-index: 2;
}
.editor-view {
  position: relative;
  z-index: 1;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link {
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.nav-link {
  border-color: #b1c2d9;
}
.nav-tabs .nav-item {
  margin-left: 0rem;
  margin-right: 0rem;
}
</style>