<template>
  <div class="row job-config left-nav-collapsible" v-show="!loading && jobTemplate.created_at">
    <div v-if="detailPageOpen" id="detail-page-open">
      <button
        class="btn details-open-btn"
        type="button"
        @click="onDetailPageOpenClose(false)"
      >
        <span> <i class="fa fa-caret-right"></i></span>
      </button>
    </div>
    <TransitionGroup :name="jobTemplate.created_at ? 'slide' : ''" key="detail">
      <div v-if="showCloseBtn && !detailPageOpen" id="detail-page-close" key="close">
        <button
          class="btn text-start details-open-btn"
          type="button"
          @click="onDetailPageOpenClose(true)"
        >
          <span> <i class="fa fa-caret-left"></i></span>
        </button>
      </div>
      <div
        v-if="!detailPageOpen"
        id="details-col"
        @mouseover="onDetailPageHover(true)"
        key="details-col"
      >
        <div class="card">
          <div class="card-header">
            <h5 class="card-header-title">
              System Files<span
                class="fa fa-circle-info text-muted"
                title="use [[UPPERCASE_WITH_UNDERSCORES]] for dynamic template field, eg: [[OUTPUT_PATH]]"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
              ></span>
            </h5>
            <button
              type="button"
              data-bs-toggle="collapse"
              href="#collapse-files-div"
              aria-expanded="true"
              aria-controls="collapse-files-div"
              id="collapse-btn"
              class="btn btn-sm"
            >
              <i class="fa fa-chevron-up float-end"></i>
            </button>
          </div>
          <div
            id="collapse-files-div"
            class="collapse show"
            aria-labelledby="collapse-btn-1"
          >
            <div class="card-body py-3">
              <p v-if="!jobTemplate.system_files || jobTemplate.system_files.length == 0">
                No configuration system files found.
              </p>
              <list-group
                :title="'File List'"
                :editMode="editMode"
                :list="jobTemplate.system_files"
                :activeListItem="activeTab"
                listItemKey="relative_path"
                @deleteBtnClick="(e) => onFileDelete(e, 'system')"
                @selectBtnclick="showFile"
              ></list-group>
              <div v-if="editMode" class="mb-4">
                <button
                  v-if="!addingSystemFile"
                  @click="showAddFile('System')"
                  class="btn btn-sm btn-primary float-end"
                  type="button"
                >
                  Add File
                </button>
                <div v-else>
                  <form @submit.prevent="addFile('system')">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="example.yaml"
                        v-model="newsystemFileName"
                        autofocus
                      />
                      <button
                        @click="addFile('system')"
                        class="btn btn-outline-secondary"
                        type="submit"
                      >
                        OK
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <h5 class="card-header-title">
              User Files
              <span
                class="fa fa-circle-info text-muted"
                title="use [[UPPERCASE_WITH_UNDERSCORES]] for dynamic template field, eg: [[OUTPUT_PATH]]"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
              ></span>
            </h5>
            <button
              type="button"
              data-bs-toggle="collapse"
              href="#collapse-files-div"
              aria-expanded="true"
              aria-controls="collapse-files-div"
              id="collapse-btn"
              class="btn btn-sm"
            >
              <i class="fa fa-chevron-up float-end"></i>
            </button>
          </div>
          <div
            id="collapse-files-div"
            class="collapse show"
            aria-labelledby="collapse-btn-1"
          >
            <div class="card-body py-3">
              <p v-if="!jobTemplate.user_files || jobTemplate.user_files.length == 0">
                No configuration user files found.
              </p>
              <list-group
                :editMode="editMode"
                :title="'File List'"
                :list="jobTemplate.user_files"
                :activeListItem="activeTab"
                listItemKey="relative_path"
                @deleteBtnClick="(e) => onFileDelete(e, 'user')"
                @selectBtnclick="showFile"
              ></list-group>
              <div v-if="editMode" class="mb-4">
                <button
                  v-if="!addingUserFile"
                  @click="showAddFile('User')"
                  class="btn btn-sm btn-primary float-end"
                  type="button"
                >
                  Add File
                </button>
                <div v-else>
                  <form @submit.prevent="addFile('user')">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="example.yaml"
                        v-model="newuserFileName"
                        autofocus
                      />
                      <button
                        @click="addFile('user')"
                        class="btn btn-outline-secondary"
                        type="submit"
                      >
                        OK
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="card-header">
              <h5 class="card-header-title">Options</h5>
              <button
                type="button"
                data-bs-toggle="collapse"
                href="#collapse-env-params-div"
                aria-expanded="true"
                aria-controls="collapse-env-params-div"
                id="collapse-btn-1"
                class="btn btn-sm"
              >
                <i class="fa fa-chevron-up float-end"></i>
              </button>
            </div>
            <div
              id="collapse-env-params-div"
              class="collapse show"
              aria-labelledby="collapse-btn-1"
            >
              <div class="card-body pt-2">
                <div class="">
                  <div class="form-group">
                    <label><h5>Tag</h5></label>
                    <InputField
                      v-model="tag"
                      :options="jobTemplate.tag"
                      :editable="editMode"
                      :showLabel="false"
                      type="multiselect"
                    />
                  </div>
                  <div class="form-group">
                    <label><h5>Type</h5></label>
                    <InputField
                      :editable="editMode"
                      :showLabel="false"
                      :options="typeOptions"
                      type="select"
                      v-model="jobTemplate.type"
                    />
                  </div>
                  <div class="form-group">
                    <InputField
                      label="Is Public"
                      :editable="editMode"
                      :showLabel="false"
                      type="checkbox"
                      v-model="jobTemplate.is_public"
                    />
                  </div>
                  <div class="form-group">
                    <label><h5>Description</h5></label>
                    <InputField
                      :editable="editMode"
                      :showLabel="false"
                      type="text"
                      v-model="jobTemplate.description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="card-header">
              <h5 class="card-header-title">Details</h5>
              <button
                type="button"
                data-bs-toggle="collapse"
                href="#collapse-props-div"
                aria-expanded="false"
                aria-controls="collapse-props-div"
                id="collapse-btn-2"
                class="btn btn-sm collapsed"
              >
                <i class="fa fa-chevron-up float-end"></i>
              </button>
            </div>
            <div
              id="collapse-props-div"
              class="collapse"
              aria-labelledby="collapse-btn-2"
            >
              <div class="card-body pt-0">
                <div class="list-group list-group-flush">
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="mb-0">Author</h5>
                      </div>
                      <div class="col-auto">
                        <small class="text-muted">{{ jobTemplate.created_by }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="mb-0">Created</h5>
                      </div>
                      <div class="col-auto">
                        <small class="text-muted">{{
                          DateTimeFormat(jobTemplate.created_at)
                        }}</small>
                        <span class="badge badge-secondary">{{
                          convertToRelativeTime(jobTemplate.created_at)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="mb-0">Updated</h5>
                      </div>
                      <div class="col-auto">
                        <small class="text-muted">{{
                          DateTimeFormat(jobTemplate.updated_at)
                        }}</small
                        ><span class="badge badge-secondary">{{
                          convertToRelativeTime(jobTemplate.updated_at)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col" id="main-col" @mouseover="onDetailPageHover(false)" key="main-col">
        <job-editor
          :editMode="editMode"
          :jobType="jobTemplate.type"
          :activeTab="activeTab"
          v-on:tabChange="showTab"
          v-on:onLintFailure="onLintFailure"
          v-model="yamlContent"
        ></job-editor>
      </div>
    </TransitionGroup>
  </div>
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
  <div v-show="!error && (loading || !jobTemplate.created_at)" class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import { DateTimeFormat, relativeTime } from "../../utils/commonFunction";
import ListGroup from "../../components/ListGroup.vue";
import JobEditor from "../../components/sandbox/JobEditor.vue";
import InputField from "../../components/InputField.vue";

export default {
  name: "JobTemplate",
  components: { ListGroup, JobEditor, InputField },
  data() {
    return {
      tag: null,
      jobTemplate: {},
      activeTab: "",
      yamlContent: {},
      response: "",
      error: "",
      loading: false,
      addingUserFile: false,
      addingSystemFile: false,
      newuserFileName: "",
      newsystemFileName: "",
      name: "tagName",
      detailPageOpen: false,
      showCloseBtn: false,
    };
  },
  props: ["editMode", "saveJobTrigger", "cancelClick"],
  beforeMount() {
    this.fetchJobTemplate();
    if (!this.jobKinds.length) {
      this.fetchJobKinds();
    }
    this.$emit("hideEdit", false);
  },
  computed: {
    ...mapState({
      jobKinds: (state) => state.amdp.jobKinds,
    }),
    typeOptions() {
      return this.jobKinds.map((type) => {
        return {
          label: type.name,
          value: type.name,
        };
      });
    },
  },
  watch: {
    editMode(val) {
      if (val) {
        this.error = "";
        this.response = "";
      }
      this.addingUserFile = false;
      this.addingSystemFile = false;
      this.initateYamlContent();
    },
    saveJobTrigger() {
      this.saveJob();
    },
    cancelClick() {
      this.fetchJobTemplate();
    },
  },
  methods: {
    ...mapActions({
      readJobTemplate: "admin/readJobTemplate",
      updateJobTemplate: "admin/updateJobTemplate",
      fetchJobKinds: "amdp/fetchJobKinds",
      showModal: "showModal",
    }),
    DateTimeFormat,
    initateYamlContent() {
      this.jobObj = this.job;

      let yamlContent = {};

      if (this.jobTemplate.system_files) {
        for (const i of this.jobTemplate.system_files) {
          yamlContent[i.relative_path] = i.content;
        }
      }
      if (this.jobTemplate.user_files) {
        for (const i of this.jobTemplate.user_files) {
          yamlContent[i.relative_path] = i.content;
        }
      }
      //Initially activeTab value is empty or undefined otherwise no need to reset to first value
      if (!this.activeTab || this.activeTab == "")
        this.activeTab = Object.keys(yamlContent)[0];
      this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
    },
    async saveJob() {
      this.loading = true;
      let toastoptions;
      try {
        let systemFileKeys = [];
        let userFileKeys = [];

        for (const i of Object.keys(this.yamlContent)) {
          let isSystemFile = false;
          if (this.jobTemplate.system_files) {
            for (const j of this.jobTemplate.system_files) {
              if (j.relative_path == i) isSystemFile = true;
            }
          }
          if (i.toLowerCase() == "vars.yaml") {
            isSystemFile = true;
          }

          if (isSystemFile) systemFileKeys.push(i);
          else userFileKeys.push(i);
        }

        this.jobTemplate.system_files = systemFileKeys.map((key) => {
          return {
            content:
              typeof this.yamlContent[key] === "string"
                ? this.yamlContent[key]
                : JSON.stringify(this.yamlContent[key]),
            relative_path: key,
          };
        });

        this.jobTemplate.user_files = userFileKeys.map((key) => {
          return {
            content:
              typeof this.yamlContent[key] === "string"
                ? this.yamlContent[key]
                : JSON.stringify(this.yamlContent[key]),
            relative_path: key,
          };
        });
        this.jobTemplate.tag = this.tag;
        let template = JSON.parse(JSON.stringify(this.jobTemplate));
        template.system_files = JSON.stringify(template.system_files);
        template.user_files = JSON.stringify(template.user_files);
        template.tag = template.tag ? template.tag.toString() : "";
        let template_fields = this.getTemplateFields([
          ...this.jobTemplate.system_files,
          ...this.jobTemplate.user_files,
        ]);
        template.template_fields = JSON.stringify(template_fields);
        const response = await this.updateJobTemplate(template);
        this.$emit("setJobTemplate", response.data);
        toastoptions = {
          message: "Job Template updated successfully.",
          type: "success",
        };
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error)
          this.error = error.response.data.error;
        else this.error = error.message;

        toastoptions = {
          message:
            this.error ||
            "Something went wrong when updating Job Template. Please contact support.",
          type: "error",
        };
      } finally {
        this.loading = false;
        this.$emit("triggerEditMode", false);
        this.$store.dispatch("addToast", toastoptions);
      }
      this.loading = false;
    },
    async fetchJobTemplate() {
      let response = {};
      if (!this.$route.params.data) {
        try {
          response = await this.readJobTemplate(this.$route.params.id);
        } catch (error) {
          if (error.response.data.error) this.error = error.response.data.error;
          else this.error = error.message;
          return;
        }
      } else {
        response["data"] = JSON.parse(this.$route.params.data);
      }
      this.jobTemplate = await response.data;
      this.jobTemplate.system_files = this.parseFileContent(
        this.jobTemplate.system_files
      );
      this.jobTemplate.user_files = this.parseFileContent(this.jobTemplate.user_files);
      this.jobTemplate.tag = this.jobTemplate.tag ? this.jobTemplate.tag.split(",") : [];
      this.tag = this.jobTemplate.tag;
      this.$emit("setJobTemplate", this.jobTemplate);
      this.initateYamlContent();
    },
    parseFileContent(content) {
      return content ? JSON.parse(content) : [];
    },
    getTemplateFields(files) {
      let templateFields = [];
      files.map((file) => {
        let fields = [];
        let temp_fields = [];
        if (file.content) fields = file.content.match(/\[\[(.\S*?)\]\]/g);
        if (fields) {
          fields.forEach((x) => {
            let field = x.replace(/\[\[/g, "").replace(/\]\]/g, "");
            const isUpperCase = (string) => /^[A-Z0-9_]*$/.test(string);
            if (isUpperCase(field)) temp_fields.push(field.toLowerCase());
          });
        }
        templateFields.push({
          relative_path: file.relative_path,
          fields: temp_fields,
        });
      });
      return templateFields;
    },
    showTab(tab) {
      this.activeTab = tab;
    },
    showFile(file) {
      this.activeTab = file.relative_path;
    },
    showAddFile(type) {
      this.newuserFileName = "";
      this.newsystemFileName = "";
      this["adding" + type + "File"] = true;
    },
    addFile(type) {
      this.addingUserFile = false;
      this.addingSystemFile = false;
      let fileType = type + "_files";
      let fileName = ["new" + type + "FileName"];
      if (!this.jobTemplate[fileType]) this.jobTemplate[fileType] = [];
      this.jobTemplate[fileType].push({
        relative_path: this[fileName],
      });

      this.yamlContent[this[fileName]] = "";
      this.activeTab = this[fileName];
    },
    onFileDelete(file, type) {
      const self = this;
      this.showModal({
        component: "confirm",
        data: {
          title: "Delete job config file",
          message: `Are you sure you want to delete file ${file.relative_path}?`,
          async confirm() {
            self.jobTemplate[type + "_files"] = self.jobTemplate[type + "_files"].filter(
              (obj) => obj.relative_path != file.relative_path
            );
            delete self.yamlContent[file.relative_path];
            if (self.activeTab === file.relative_path)
              self.activeTab = Object.keys(self.yamlContent)[0];
            return;
          },
        },
      });
    },
    onLintFailure(isError) {
      this.$emit("onLintFailure", isError);
    },
    convertToRelativeTime(timestamp) {
      return relativeTime(Date.now(), dayjs(timestamp));
    },
    onDetailPageOpenClose(value) {
      this.showCloseBtn = false;
      this.detailPageOpen = value;
    },
    onDetailPageHover(value) {
      this.showCloseBtn = value;
    },
  },
  emits: [
    "onLintFailure",
    "triggerEditMode",
    "hideEdit",
    "setJobTemplate",
    "setSaveConfigBtn",
  ],
};
</script>
<style scoped>
.job-config .card-header {
  background-color: #f9fbfd;
  text-transform: uppercase;
  color: #95aac9;
  height: 40px;
}
.job-config .collapsed .fa {
  transform: rotate(180deg);
}

</style>
