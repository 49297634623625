<template>
    <div class="modal-card card">
        <form @submit.prevent="onSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Params Definition
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <FormFields :fields="fields" v-model="param" :refreshOn="refereshOn" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <SubmitButton :fields="fields" v-model="param" btnText="Add" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import FormFields from '../FormFields.vue';
import SubmitButton from '../SubmitButton.vue'
import { mapActions } from 'vuex'
import Fields from "../../utils/field-constants/paramFields"
export default {
    components: { FormFields, SubmitButton },
    data() {
        return {
            error: "",
            param: {},
            fields: Fields,
            refereshOn: true ,
            loading: false
        }
    },
    props: {
        modalData: {
            type: Object,
            }
    },
    name: "AddParam",
    methods: {
        ...mapActions('amdp', {
            createParam: 'createParam'
        }),
        async onSubmit() {
            this.loading= true;
            try {
                await this.createParam(this.param)
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else if (error.response.data.message) 
                    this.error =  error.response.data.message;
                else
                    this.error = error.message

                return
            }
            this.$store.dispatch('hideModal');
            //Calling Param def to reset select options
            this.modalData.resetParamDef();
            this.loading=false;
        }
    },
    beforeMount(){
        //In Add param rule field should be invisible
        Fields[3].visible=false;
    }

};
</script>
<style>
</style>