//Ref for event types: https://help.aqfer.net/en_US/new-aqfer-aut-developers-guide/1665972-aut-parameters
//Ref for sub event types https://help.aqfer.net/en_US/new-aqfer-aut-developers-guide/aut-event-classification
const tag_event_types = [
        {
            label: "Impression",
            value: "imp",
            sub_events: [{
                label: "Measurable",
                value: "measurable",
                sub_events: [{
                    label: "Viewable",
                    value: "viewable",
                },{
                    label: "Not Viewable",
                    value: "notViewable",
                },{
                    label: "View Undetermined",
                    value: "ViewUndetermined",
                }]
            },{
                label: "Not Measurable",
                value: "notMeasurable",
                sub_events: [{
                    label: "Viewable",
                    value: "viewable",
                },{
                    label: "Not Viewable",
                    value: "notViewable",
                },{
                    label: "View Undetermined",
                    value: "ViewUndetermined",
                }]
            }]
        },
        {
            label: "Click",
            value: "clk",
            sub_events: [
                {
                    label:"Click Through",
                    value:"clickThrough"
                },{
                    label:"Click Tracking",
                    value:"clickTracking"
                }
            ]
        },
        {
            label: "Engagement",
            value: "eng",
            sub_events: [
                {
                    label:"Video View",
                    value:"videoView",
                    sub_events:[{
                        label:"Start",
                        value:"start"
                    },{
                        label:"First Quartile",
                        value:"firstQuartile"
                    },{
                        label:"Midpoint",
                        value:"midpoint"
                    },{
                        label:"Third Quartile",
                        value:"thirdQuartile"
                    },{
                        label:"Complete",
                        value:"complete"
                    }]
                },{
                    label:"Video Interact",
                    value:"videoInteract",
                    sub_events:[{
                        label:"Mute",
                        value:"mute"
                    },{
                        label:"Unmute",
                        value:"unmute"
                    },{
                        label:"Pause",
                        value:"pause"
                    },{
                        label:"Resume",
                        value:"resume"
                    },{
                        label:"Custom Click",
                        value:"customClick"
                    },{
                        label:"Other Ad Interaction",
                        value:"otherAdInteraction"
                    }]
                },{
                    label:"Display Interact",
                    value:"displayInteract",
                    sub_events:[{
                        label:"Select",
                        value:"select"
                    },{
                        label:"Expand",
                        value:"expand"
                    },{
                        label:"Submit",
                        value:"submit"
                    },{
                        label:"Hover",
                        value:"hover"
                    },{
                        label:"Custom Click",
                        value:"customClick"
                    },{
                        label:"Other Ad Interaction",
                        value:"otherAdInteraction"
                    }]
                }
            ]
        },        
        {
            label: "Conversion",  
            value: "conv", 
            sub_events: [
                {
                    label: "Purchase", 
                    value: "purchase", 
                    sub_events: [{
                        label: "Online", 
                        value: "online", 
                    },{
                        label: "In-Store", 
                        value: "in-store", 
                    }]
                },{
                    label: "Rfi", 
                    value: "rfi", 
                    sub_events: [{
                        label: "Online", 
                        value: "online", 
                    },{
                        label: "Offline", 
                        value: "offline", 
                    }]
                },
                {
                    label: "Register", 
                    value: "register",
                    sub_events: []                      
                },
                {
                    label: "Call", 
                    value: "call", 
                    sub_events: [{
                        label: "Online", 
                        value: "online", 
                    },{
                        label: "Direct", 
                        value: "direct", 
                    }]
                }
            ]
        },        
        {
            label: "View", 
            value: "pv",
            sub_events: [
                {
                    label: "Email View",
                    value: "emailOpen",
                    sub_events:[{
                        label:"Html",
                        value:"html"
                    },{
                        label:"Text",
                        value:"text"
                    }]
                },{
                        label:"Message View",
                        value:"messageView",
                        sub_events:[{
                            label:"SMS",
                            value:"sms"
                        },{
                            label:"MMS",
                            value:"mms"
                        },{
                            label:"RCS",
                            value:"rcs"
                        }
                    ]
                },{
                    label:"Notification View",
                    value:"notificationView",
                    sub_events:[{
                        label:"Mobile",
                        value:"mobile"
                    },{
                        label:"Ctv",
                        value:"ctv"
                    },{
                        label:"Desktop",
                        value:"desktop"
                    },{
                        label:"Tablet",
                        value:"tablet"
                    },{
                        label:"iOT",
                        value:"iOT"
                    }
                ]
            },
            {
                label:"Page View",
                value:"pageView",
                sub_events:[{
                    label:"Desktop",
                    value:"desktop"
                },{
                    label:"Mobile",
                    value:"mobile"
                }
            ]
        },{
            label:"Screen View",
            value:"screenView",
            sub_events:[{
                label:"Mobile",
                value:"mobile"
            },{
                label:"Ctv",
                value:"ctv"
            },{
                label:"Desktop",
                value:"desktop"
            },{
                label:"Tablet",
                value:"tablet"
            },{
                label:"iOT",
                value:"iOT"
            }
        ]
        }]
        },        
        { 
            label: "Interaction",  
            value: "int", 
            sub_events: [{
                label: "Email Click",
                value: "emailClick",
                sub_events:[{
                    label:"Html",
                    value:"html"
                },{
                    label:"Text",
                    value:"text"
                }]
            },{
                label: "Message Click", 
                value: "meesageClick", 
                sub_events:[{
                    label:"SMS",
                    value:"sms"
                },{
                    label:"MMS",
                    value:"mms"
                },{
                    label:"RCS",
                    value:"rcs"
                }] 
            },{
                label: "Notification Click", 
                value: "notificationClick", 
                sub_events:[{
                    label:"Mobile",
                    value:"mobile"
                },{
                    label:"Ctv",
                    value:"ctv"
                },{
                    label:"Desktop",
                    value:"desktop"
                },{
                    label:"Tablet",
                    value:"tablet"
                },{
                    label:"iOT",
                    value:"iOT"
                }]
            },{
                label: "Page Click", 
                value: "pageClick", 
                sub_events:[{
                    label:"Desktop",
                    value:"desktop"
                },{
                    label:"Mobile",
                    value:"mobile"
                }]
            },{
                label: "Page Hover", 
                value: "pageHover", 
                sub_events: [{
                        label: "seconds", 
                        value: 0, 
                        type: Number
                    }] 
            },{
                label: "Screen Scroll",
                value: "screenScroll",
            }] 
        },{ 
            label: "Geo Event",  
            value: "geoev", 
            sub_events: [] 
        },{ 
            label: "Other Event", 
            value: "othev", 
            sub_events: [] 
        },{ 
            label: "POI Visit",  
            value: "poiv", 
            sub_events: [] 
        },{ 
            label: "Search Click",  
            value: "sclk", 
            sub_events: [
                {
                    label:"Click Through",
                    value:"clickThrough"
                }
            ]
        }
]
export { tag_event_types }