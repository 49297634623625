<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">{{ modalData.title }}</h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <p v-html="modalData.message"></p>
        </div>
        <div class="card-footer text-end">
            <button @click="cancelClick" class="btn btn-secondary" :disabled="okClicked">
                Cancel</button
            >&nbsp;
            <button @click="okClick" class="btn btn-primary ok-btn" :disabled="okClicked">
                <div v-if="okClicked" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    OK
                </div>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Confirm",
    data() {
        return {
            okClicked: false,
        }
    },
    props: {
        modalData: {
            type: Object,
            default() {
                return {
                    title: 'Confirm',
                    message: 'Are you sure?'
                }
            }
        },
    },
    methods: {
        cancelClick() {
            if (typeof this.modalData.cancel == "function") {
                this.modalData.cancel();
            }
            this.$store.dispatch("hideModal");
        },
        async okClick() {
            const self = this
            this.okClicked = true;
            if (typeof this.modalData.confirm == "function") {
                const response = await this.modalData.confirm();
                //check if promise 
                if(response && typeof response.then == "function") {
                    response.finally(()=>{
                        self.$store.dispatch("hideModal");
                    });
                } else {
                    this.$store.dispatch("hideModal");
                }
            } else {
                this.$store.dispatch("hideModal");
            }
        },
    },
};
</script>

<style scoped>
    .ok-btn {
        width:50px;
    }
</style>