<template>
	<YamlInputField
		:fields="fields"
		:modelValue="parsedYaml"
		@update:modelValue="onUpdateParsedYaml"
		:editMode="editMode"
	></YamlInputField>
</template>

<script>
	import YamlInputField from "./YamlInputField.vue";
	export default {
		components: { YamlInputField },
		data() {
			return  {
				defaultYaml : {
						tenant_id: {},
						extract:[{
							entity_type:{},
							entity_domain:{},
							entity_id:{},
							operation:{},
							neighbors:[],
							attributes:[]
							
						}]
				}
			}

		},
		computed: {
			parsedYaml() {
				return this.modelValue || this.defaultYaml;
			},
		},
		methods: {
			onUpdateParsedYaml(val) {
				this.$emit("update:modelValue", val);
			},
		},
		props: {
			fields: Array,
			modelValue: [Object, String],
			editMode: {
				type: Boolean,
				default: true,
			},
		},
		emits: ["update:modelValue", "change"],
	};
</script>

<style></style>
