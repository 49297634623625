<template>
    <div class="modal-card card">
        <form @submit.prevent="onUpload()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Upload File
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <FileUploader :onFileChange="onFileChange" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-lg btn-primary" type="submit" :disabled="!uploadButton">
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            <i class="fa fa-upload" />&nbsp; Upload
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import FileUploader from '../FileUploader.vue'
export default {
    components: { FileUploader },
    props: {
        modalShown: Boolean
    },
    data() {
        return {
            error: "",
            loading: false,
            file: {},
            uploadButton: false
        }
    },
    name: "UploadFile",
    computed: {
        folderPath() {
            //removing ending / from path
            return this.$store.state.amdp.folderPath
        }
    },
    methods: {
        ...mapActions({
            getPresignURL: "amdp/getPresignURL",
            uploadPresignURLFile: "amdp/uploadPresignURLFile"
        }),
        onFileChange(file) {
            if (file.length > 0) {
                this.uploadButton = true;
                this.file = file[0]

            } else {
                this.uploadButton = false;
            }
        },
        async onUpload() {
            this.loading = true
            let path = this.folderPath + this.file.name
            try {
                let self = this;
                await this.getPresignURL({ filePath: path, operation: "upload", sandboxName: this.$route.params.sandboxId }).then((response) => {
                    if (response.data && response.data.url) {
                        this.uploadPresignURLFile(
                            {
                                signedUrl: response.data.url,
                                file: self.file
                            })
                    }
                })
                this.loading = false;
                this.$store.dispatch('hideModal');
                let params = {
                    id: this.file.name,
                    data: JSON.stringify({ is_dir: 'false' })
                }
                let toastoptions = {
                    message: "File uploaded successfully.",
                    type: "success"
                }
                this.$store.dispatch('addToast', toastoptions);

                this.$store.dispatch("setBreadcrumbs", [...this.$store.state.breadcrumbs, {
                    name: this.file.name,
                    route: {
                        path: this.file.name, name: this.file.name,
                        params: params
                    }
                }])
                this.$router.push({
                    name: 'File Browser View',
                    path: this.file.name,
                    params: params

                });
            } catch (error) {
                this.loading = false
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }



        }
    }

};
</script>

<style></style>