<template>
  <div
    style="height: 100vh"
    class="
      d-flex
      align-items-center
      bg-auth
      border-top border-top-2 border-primary
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <!-- Heading -->
          <h3 class="display-4 text-center mb-5">Signing Out...</h3>
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'Logout',
    mounted() {
      this.$store.dispatch('logout')
    }
};
</script>

<style>
</style>