function getSandboxFields() { 
    return [
        {
            key: true,
            name: "name",
            label: "Name",
            type: "text",
            editable: true,
            validation: [
                { type: "required", errMsg: "Name field is required" }
            ]
        },
        {            
            name: "description",
            label: "Description",
            type: "textarea",
            editable: true,
            validation: [
                { type: "required", errMsg: "Description field is required" }
            ]
        },
        {
            name: "env",
            label: "Env",
            type: "select",
            editable: true,
            options:[
                { label: "Dev", value: "DEV" },
                { label: "Prod", value: "PROD" }
            ]    
        },
        {
            name: "cluster_name",
            label: "Cluster Name",
            type: "text",
            editable: true,
            validation: [
                { type: "required", errMsg: "Cluster Name Field is required" }
            ]
        },
        {
            name: "enabled_job_kinds",
            label: "Enabled Job Kinds",
            type: "checkbox",
            editable: true,
            options:[
                { label: "Connector", value: "CONNECTOR" },
                { label: "Mapper-v2", value: "MAPPER-V2" },
                { label: "Collator", value: "COLLATOR" },
                { label: "Transform", value: "TRANSFORM" },
            ] 
        },{
            name: "k8s_namespace",
            label: "k8s Namespace",
            type: "text",
            editable: true,
            validation: [
                { type: "required", errMsg: "k8s_namespace field is required" }
            ]
        },
        {
            name: "config_storage",
            label: "Config Storage",
            type: "text",
            editable: true,
            validation: [
                { type: "required", errMsg: "Config Storage field is required" }
            ]
        }, {
            name: "data_storage",
            label: "Data Storage",
            type: "text",
            editable: true
        }
    ];
}

export default getSandboxFields;

