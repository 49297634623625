<template>
    <div :class="seperatePage?'card m-3': ''">
        <div v-if="seperatePage" class="card-header">
            <h4 class="card-header-title">
                    File Preview
            </h4>
        </div>
        <div :class="seperatePage?'card-body':''">
        <Tabs :tabs="tabItems">
            <template v-slot:parsedTable>
                <div v-if="loading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <div class="row justify-content-end">
                        <div class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end">
                            <div class="input-group input-group-sm w-50">
                                <label class="input-group-text">Limit</label>
                                <select name="limit" class="form-select form-select-sm" v-model="limit">
                                    <option v-for="option in this.limitOptions" :key="option.index" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <Datatable tableId="outputParserTable" :columns="columns" :data="data" :compact="true"
                        :showLoading="loading" :rowClick="showRowDetail" :captializeHeader="false" />
                </div>
            </template>
            <template v-slot:parsedRow>
                <ObjectVisualizer :data="rowData" :expandOnCreatedAndUpdated="expandOnCreatedAndUpdated" :rootName="rootName" />
            </template>
        </Tabs>
    </div>
    <div v-if="error" class="text-danger text-center">
        {{ error }}
    </div>
</div>
</template>
<script>
import Datatable from './Datatable.vue';
import Tabs from "./Tabs.vue";
import { ObjectVisualizer } from 'object-visualizer';
import "object-visualizer/dist/index.min.css";
import { mapActions } from 'vuex';
export default {
    components: { Datatable, Tabs, ObjectVisualizer },
    name: "OutputParser",
    data() {
        return {
            error: null,
            loading: true,
            path: "",
            limit: 5,
            limitOptions: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "100", value: 100 },
            ],
            outputData: [],
            tabItems: [],
            data: [],
            columns: [],
            rowData: [],
            seperatePage:false,
            expandOnCreatedAndUpdated: function (path) {
                return true;
            },
            rootName: "$",
        }
    },
    props: {
        compData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    watch: {
        path(newVal) {
            if (newVal != "")
                this.getOutputPreview()
        },
        outputData(newVal) {
            let columns = [];
            if (newVal.length) {
                for (const colname of Object.keys(newVal[0])) {
                    columns.push({
                        data: colname,
                    });
                }
            }
            let newData = JSON.parse(JSON.stringify(this.outputData));
            //For array and object display like as [ ARRAY ] [OBJECT]
            if (newData.length) {
                newData.forEach((item) => {
                    columns.forEach(column => {
                        item[column.data] = (Array.isArray(item[column.data]) ? "[ ARRAY ]" :
                            ((typeof item[column.data] === 'object' && item[column.data] !== null)) ? "[ OBJECT ]" : item[column.data])
                    });
                })
            }
            this.columns = columns;
            this.data = newData;
        },
        limit() {
            this.getOutputPreview()
        }
    },
    methods: {
        ...mapActions({
            fetchOutputParser: "amdp/fetchOutputParser"
        }),
        async getOutputPreview() {
            this.error = null;
            let payload = {
                path: this.path,
                limit: this.limit,
                sandboxName: this.$route.params.sandboxId 
            }
            this.loading = true;
            await this.fetchOutputParser(payload,).then((response) => {
                if (response.data) {
                    this.outputData = response.data
                }
            }).catch(({ response }) => {
                this.error = response.data.error;
                this.output = []
            }).finally(() => {
                this.loading = false;
            })
        },
        showRowDetail(index) {
            let rowindex = this.tabItems.findIndex((tabs) => tabs.slot == "parsedRow")
            //Remove Previously parsed row exits
            if (rowindex !== -1) {
                this.tabItems.pop()
                this.tabItems[0].active = false
            }
            this.$store.dispatch('setActiveTab', "Row Details")
            this.tabItems.push({
                name: "Row Details",
                id: "id2",
                slot: "parsedRow"

            })
            this.rowData = this.outputData[index]
        }
    },
    beforeMount() {
        let filename = "";
       if (Object.keys(this.compData).length !== 0) {
            this.path = this.compData.path;
            this.limit = this.compData.limit;
            filename = this.compData.filename
            //For viewing output parser using route
        } else if (this.$route.query.filepath) {
            this.seperatePage = true;
            this.path = this.$route.query.filepath;
            this.limit = this.$route.query.limit?this.$route.query.limit:5;
        }
        this.tabItems.push({
            name: filename,
            id: "id1",
            slot: "parsedTable"
        })
    }

}
</script>

<style scoped>
.tableDiv {
    overflow: auto;
}

.object-visualizer {
    margin: 0;
    padding: 10px;
    font-size: 1rem;
    line-height: 1.4;
    cursor: default;
    overflow: auto;
}

</style>