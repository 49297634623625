export default {

    process: (content, template, replacements) => {
        Object.keys(replacements).forEach(key => {
            const pattern = new RegExp(template.replace('xx', key), 'g');
            content = content.replace(pattern, replacements[key]); 
        });
        return content;
    },

    replaceDynamicFields:(fileArray, dynamicFields) => {
        return fileArray.map((file) => {
            if(Object.entries(dynamicFields).length) {
                let content = JSON.stringify(file.content);
                for (const [key, value] of Object.entries(dynamicFields)) {
                    // Use template literals to create the regex pattern
                    
                    const re = new RegExp("\\[\\[" + key + "\\]\\]", "g");
                    content = content.replace(re, value);
                }
                file.content = JSON.parse(content)
            }
            return file;
        });
    },

}