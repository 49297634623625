<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Config Updated
            </h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="card-body">
            <div>
                <p class="mb-1">Updated by:&nbsp;<span class="text-muted">{{ details.updated_by }}</span> </p>
                <p>Updated at:&nbsp;<span class="text-muted">{{ DateTimeFormat(details.updated_at) }}</span></p>
            </div>
            <div class="row mb-1">
                <div class="col-6 config">
                    Local Copy
                </div>
                <div class="col-6 config">
                    Remote Copy
                </div>
            </div>
            <div v-for="config in diff" :key="config.id">
                <p class="config">{{ config.prev.relative_path }}</p>
                <Diff mode="split" theme="light" language="plaintext" :prev="config.prev.content"
                    :current="config.current.content" />
            </div>
            <div v-if="error" class="text-danger">
                {{ error }}
            </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <button class="btn btn-primary" @click="onReload"> Reload changes</button> &nbsp;
                <button class="btn btn-primary" @click="onOverWrite"> Overwrite Current Changes</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTimeFormat } from '../../utils/commonFunction';
export default {
    data() {
        return {
            error: "",
            loading: false
        }
    },
    props: {
        modalData: {
            type: Object,
        }
    },
    computed: {
        details() {
            return this.modalData.details ? this.modalData.details : ''
        },
        diff() {
            return this.modalData.diff ? this.modalData.diff : []
        }
    },
    name: "ConfigChanges",
    methods: {
        DateTimeFormat,
        onReload() {
            this.modalData.onReload();
        },
        onOverWrite() {
            this.modalData.onOverWrite();
        }
    }
}
</script>

<style scoped>
.config {
    color: #2C7BE5;
}
</style>