<template>
  <basic-header pretitle="aqfer IO" title="aIO Dashboards" :compact="true" />
  <div class="container-fluid">
    <dashboard-tabs inFeature="AIO" />
  </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import DashboardTabs from '../../components/DashboardTabs.vue'
export default {
  components: { BasicHeader, DashboardTabs },

}
</script>

<style></style>