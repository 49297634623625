<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Copy Template to My library</h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <div class="mb-3">
                        <h3 for="exampleFormControlInput1" class="form-label">What would you like to name this template?</h3>
                        <input  placeholder="placeholder" class="form-control" id="exampleFormControlInput1" v-model="tagTemplateName">
                    </div>                    
                </div>
            </div>  
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button
            >&nbsp;
            <button class="btn btn-primary" id="myButton" @click="copyTag()">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    Copy
                </div>
            </button>&nbsp;            
        </div>
    </div>  
</template>

<script>
import {mapActions} from 'vuex';
import { isDuplicate  } from '../../utils/commonFunction';
export default {  
    data() {
        return {
            tagTemplate: this.modalData,
            tagTemplateName: `${this.modalData.name}_`,
            loading: false
        }
    },    
    props: {
        modalData: {
            type: Object
        },
    },   
    methods: {
        ...mapActions('aut', {
            createTagTemplate: 'createTagTemplate',
            fetchTagTemplates: 'fetchTagTemplates'
        }),
        async copyTag() {
            let valid= false;
            this.loading = true;
            let toastoptions;
            delete this.tagTemplate.id;
            let tagTemplate =JSON.parse(JSON.stringify(this.tagTemplate))
            tagTemplate.name = this.tagTemplateName;
            tagTemplate.owner = this.$store.state.currentUser.email;
            tagTemplate.cid = this.$store.state.currentClient.cid;
            tagTemplate.public= false;
            tagTemplate.recommended= false;
            tagTemplate.tag= tagTemplate.tag ? tagTemplate.tag.toString(): "";
            let response = null;
            try {
                 //Note: If Reload happen in view tag template then list of tag template wont available
                 if(!this.$store.state.aut.tagTemplates.length){   
                    await this.fetchTagTemplates().then(()=>{
                        valid = !isDuplicate(this.tagTemplateName, this.$store.state.aut.tagTemplates, "name", null)
                    });
                }else{
                    valid = !isDuplicate(this.tagTemplateName,this.$store.state.aut.tagTemplates, "name", null)
                }
                if(valid){
                    response = await this.createTagTemplate(tagTemplate)
                    toastoptions = {
                        message: "Tag Copied successfully.",
                        type: "success"
                    }
                }else{
                    toastoptions = {
                        message: "Already '"+this.tagTemplateName+"' name exits.'",
                        type: "error",
                    };
                }
            } catch (error) { 
                toastoptions = {
                    message: "Something went wrong when copying the tag. Please contact support.",
                    type: "error"
                }
                return
            }
            //Dont hide modal if name is duplicate
            if (valid){
                this.$store.dispatch('hideModal')
                if(response && response.data && response.data.id)
                    this.$router.push({ name: 'Tag Template', params: {id: response.data.id}} );
            }
            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
        }
    }
    

}
</script>

<style>

</style>