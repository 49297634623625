function getPixelFields() { 
    return[
        {   
            name: "beaconId",
            label: "Beacon Id",
            type: "text",            
            editable: false,
            visible: true,
            placeholder: "*Auto Generated*"
        },  
        {           
            name: "description",
            label: "Description",
            type: "text",
            editable: true,
            visible: true,
        },
        {           
            name: "type",
            label: "HTML Tag",
            type: "radio",
            toolTip: "Should the beacon be fired directly as an img tag, or should it loaded as an iframe?",
            editable: true,
            visible: true,
            options:[
                { label: "img", value: "DIR" },
                { label: "iframe", value: "FRM" },
                { label: "script", value: "SCRIPT" }
            ]  
        },
        {           
            name: "script",
            label: "Script",
            type: "script",
            toolTip: 'Script tag to insert',
            editable: true,
            visible: true,
            suppressIfField: 'type', suppressIfValueDoesNotContains: ["SCRIPT"]  
        },
        {           
            name: "should_host_script",
            label: "Host the script",
            type: "checkbox",
            toolTip: 'Should the given script be hosted by aqfer?',
            editable: true,
            visible: true,           
            suppressIfField: 'type', suppressIfValueDoesNotContains: ["SCRIPT"]  
        },
        {       
            name: "url",
            label: "URL",
            type: "text",
            editable: true,
            visible: true,
        },
        {     
            name: "req",
            label: "Fire On Every Page",
            type: "checkbox",
            toolTip: 'Should this pixel fire all the times? If false, there can be frequency cap on this pixel',
            editable: true,
            visible: true,
        },
        {     
            name: "cap",
            label: "Frequency Cap",
            type: "number",
            tooltip: 'Maximum number of times this pixel should fire for a single user',
            editable: true,
            visible: true,
            suppressIfField: 'req', suppressIfValueContains: [true],
        },
        {     
            name: "cookie_req",
            label: "Skip if Cookie rejected",
            type: "checkbox",
            toolTip: 'If set to true, this pixel will fire only when client-specific cookie is present.',
            editable: true,
            visible: true,
        },
        {     
            name: "frequency",
            label: "Percentage",
            type: "number",
            toolTip: 'This value is the percentage of users this pixel will fire',
            editable: true,
            visible: true,
        },
        {     
            name: "pub",
            label: "Publisher Restriction",
            type: "radio",
            toolTip: 'Allow/Deny: "Publisher Domains" on which this pixel must/must-not fire. None: fire on all domains',
            editable: true,
            visible: true,
            options:[
                { label: "Allow", value: "ALLOW" },
                { label: "Deny", value: "DENY" },
                { label: "None", value: "NONE" }
            ]  
        },
        {     
            name: "pubAllow",
            label: "Publisher Allow Domains",
            type: "array",
            editable: true,
            visible: true,
            suppressIfField: 'pub', suppressIfValueDoesNotContains: ["ALLOW"] 
        },
        {
            name: "pubDeny",
            label: "Publisher Deny Domains",
            type: "array",
            editable: true,
            visible: true,
            suppressIfField: 'pub', suppressIfValueDoesNotContains: ["DENY"] 
        },
        {     
            name: "country",
            label: "User Country Restriction",
            type: "radio",
            toolTip: 'Allow/Deny: "User Countries" for this pixel must/must-not fire. None: fire for all countries',
            editable: true,
            visible: true,
            options:[
                { label: "Allow", value: "ALLOW" },
                { label: "Deny", value: "DENY" },
                { label: "None", value: "NONE" }
            ]  
        },
        {     
            name: "userAllow",
            label: "User Countries",
            type: "array",
            editable: true,
            visible: true,
            suppressIfField: 'country', suppressIfValueDoesNotContains: ["ALLOW"],
        },
        {     
            name: "userDeny",
            label: "User Countries",
            type: "array",
            editable: true,
            visible: true,
            suppressIfField: 'country', suppressIfValueDoesNotContains: ["DENY"],
        },            
        {
            name: "gpp.tcf.status",
            label: "TCF",
            tooltip: "TCF is applicable if gpp_sid=2: 1.BLOCKED:The traffic is blocked 2.DISABLED:TCF has been handled 3.ENABLED:TCF is handled",
            type: "radio",           
            editable: true,
            visible: true,
            options:[
                { label: "DISABLED", value: "DISABLED" },
                { label: "BLOCKED", value: "BLOCKED" },
                { label: "ENABLED", value: "ENABLED" }
            ]
        },
        {
            name: "gpp.tcf.gvlId",
            label: "TCF Gvl Id",
            tooltip: "TCF Global vendor List Identifier",                     
            editable: true,
            visible: true,
            defaultValue: null,
            validation: [
                { 
                    type: "regex", pattern: "^(null|[0-9]+)?$",
                    errMsg:"TCF Gvl Id should be a numeric value"
                }
            ]      
        },
        {
            name: "gpp.ccpa.status",
            label: "CPRA",
            type: "radio",  
            tooltip:"CPRA is applicable if gpp_sid=6: 1.BLOCKED:The traffic is blocked 2.DISABLED:CPRA has been handled 3.ENABLED:CPRA is handled",         
            editable: true,
            visible: true,
            options:[
                { label: "DISABLED", value: "DISABLED" },
                { label: "BLOCKED", value: "BLOCKED" },
                { label: "ENABLED", value: "ENABLED" }
            ]
        },
        {
            name: "gpp.usMultiStatePrivacy",
            label: "Us Multi State Privacy",
            toolTip: "When US Multi states and US National privacy is applicable, 1.BLOCKED: The traffic is blocked 2.DISABLED: Privacy has been handled 3.ENABLED: Privacy is handled",
            type: "custom", 
            toolTipHtml: "true",           
            editable: true,
            customComponent: true,
            component: "UsMultiStatePrivacy"

        },
        {     
            name: "fire_conditions_expression",
            label: "Additional Condition",
            toolTip: 'Expression on name labelled conditions in natural language. Only operations like AND, OR, NOT are allowed.',
            type: "text",
            editable: true,
            visible: true,
        },
        {     
            name: "rotationName",
            label: "Rotation Name",
            type: "text",
            tooltip:"Choose a rotation configuration to set rotation cookie",
            editable: true,
            visible: true,
        },
        {     
            name: "fire_conditions",
            label: "Sub-Condition",
            type: "Custom",
            tooltip:"Fire conditions based on variable",
            editable: true,
            visible: true,
            customComponent: true,
            component: "SubCondition"
        },
        {     
            name: "continent_frequency",
            label: "Content Throttling",
            type: "Custom",
            tooltip:"Set throttling frequency based on continent",
            editable: true,
            visible: true,
            customComponent: true,
            component: "ContentThrottling"
        },


    
    ]

}
export default getPixelFields;