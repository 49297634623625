<template>
    <div class="modal-card card">
        <form @submit.prevent="onEditClientSubscription()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Client Subscription for Product {{ product.sku }}
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <InputField label="Quantity" type="number" v-model="product.quantity" :editable="true"
                    :validate="[{ type: 'required', errMsg: 'quantity is required' }]" />

                <InputField label="Effective Date" type="calendar" v-model="product.effective_date" :editable="true" 
                    :inputConfig="eff_config" :validate="[{ type: 'required', errMsg: 'effective date is required' }]"/>

                <InputField label="End Date" type="calendar" v-model="product.end_date" :editable="true" :inputConfig="end_config"
                         :validate="[{ type: 'required', errMsg: 'end date is required' }]" />
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="btnDisable" type="submit">Update</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputField from "../InputField.vue";
import { mapActions } from 'vuex';
import dayjs from "dayjs";
export default {
    components: { InputField },
    name: "EditClientSubscription",
    data() {
        return {
            clientId: this.$store.state.currentClient.cid,
            product: null,
            eff_config: {
                wrap: true,
                dateFormat: 'Y-m-d',
            },
            end_config: {
                wrap: true,
                dateFormat: 'Y-m-d',
            },
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        btnDisable() {
            return !(this.product.id && this.product.quantity > 0 && this.product.effective_date && this.product.end_date)
        }
    },
    beforeMount() {
        this.product = this.modalData;
    },
    watch:{
        product(newVal){
            this.eff_config.maxDate = newVal.end_date?newVal.end_date.toString():null
            this.end_config.minDate = newVal.effective_date?newVal.effective_date.toString():null
        }
    },
    methods: {
        ...mapActions('admin', {
            updateClientSubscription: 'updateClientSubscription'
        }),
        async onEditClientSubscription() {
            let toastoptions;
            try {
                this.product["cid"]=this.$route.params.id;
                await this.updateClientSubscription(this.product)
                toastoptions = {
                    message: "Client product subscriptions updated successfully.",
                    type: "success",
                };
            } catch (error) {
                toastoptions = {
                    message: error.response.data.error.toString(),
                    type: "error"
                }
                return
            }
            this.$store.dispatch('addToast', toastoptions);
            this.$store.dispatch('hideModal')
            this.$store.dispatch("admin/fetchClientProducts",this.$route.params.id);
        }
    }
};
</script>

<style></style>