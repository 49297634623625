<template>
	<div class="modal-card card">
		<form @submit.prevent="onCreateIDRJob()">
			<div class="card-header">
				<!-- Title -->
				<h4 class="card-header-title">
					{{ title }}
				</h4>
				<!-- Close -->
				<button
					type="button"
					class="btn-close"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>
			</div>
			<div class="card-body overflow-visible">
				<form-fields :fields="jobFields" v-model="job" />
				<JobTemplateSelection  v-on:setCurrentTemplate="setCurrentTemplate" :jobTemplates="jobTemplates" v-model="dynamicFields"></JobTemplateSelection>
				<div v-if="error" class="text-danger">
					{{ error }}
				</div>
			</div>
			<div class="card-footer">
				<div class="text-end">
					<submit-button
						:fields="jobFields"
						v-model="job"
						:loading="loading"
					/>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import formFields from "../FormFields.vue";
	import submitButton from "../SubmitButton.vue";
	import { mapActions, mapState } from "vuex";
	import getSandboxJobFields from "../../utils/field-constants/sandboxJobFields";
	import JobTemplateSelection from '../JobTemplateSelection.vue'
	import macros from '../../utils/macros';
	export default {
		components: { formFields, submitButton, JobTemplateSelection },
		data() {
			return {
				error: "",
				job: {},
				jobFields: [],
				loading: false,
				currentTemplate: null,
				title:"Create Job",
				dynamicFields: {}
			};
		},
		name: "AddIDRJob",
		created() {
			this.jobFields = getSandboxJobFields();
			this.initMethod();
		},
		props: {
			modalData: {
				type: [Object, Array, String],
				default: "",
			},
		},
		computed: {
			...mapState({
				jobKinds: (state) => state.amdp.jobKinds,
			}),
			jobTemplates() {						
				return this.$store.state.admin.jobTemplates.filter((template) => {						
					const tags = (template.tag && typeof template.tag === "string")?template.tag.toUpperCase().split(","): template.tag
					return template.type == "mapper-v2" && tags?.includes("IDR");

				});
				
			},
		},		
		beforeMount() {
			this.job.kind = "TRANSFORM";
			this.$store.dispatch("admin/fetchJobTemplates");
			if (!this.jobKinds.length) {
				this.fetchJobKinds();
			}
		},
		methods: {
			...mapActions({
				createSandboxJob: "sandbox/createSandboxJob",
				fetchJobKinds: "amdp/fetchJobKinds",
			}),
			setCurrentTemplate(val) {
				this.currentTemplate = val;
			},
			async onCreateIDRJob() {
				this.loading = true;
				if (this.currentTemplate) {					
					let system_files =  JSON.parse(
						this.currentTemplate?.system_files
					);
                    let user_files =  JSON.parse(
						this.currentTemplate?.user_files
					);
					this.job.system_files = this.dynamicFields?macros.replaceDynamicFields(system_files, this.dynamicFields):system_files;
					this.job.user_files = this.dynamicFields?macros.replaceDynamicFields(user_files, this.dynamicFields):user_files;				
				} else {
					let system_files = [];
					let jobKindDetailsArr = this.jobKinds?.filter((jobKind) =>	jobKind.name == this.job.kind?.toLowerCase());
					let requiredFiles = jobKindDetailsArr.length?jobKindDetailsArr[0].required_files:["config.yaml", "vars.yaml"]
					requiredFiles.map((file) => {
						system_files.push({
							content: " ",
							relative_path: file,
						});
					});
					this.job.system_files = system_files;					
				}
				try {
					await this.createSandboxJob(this.job);
				} catch (error) {
					this.loading = false;
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;

					return;
				}
				this.loading = false;
				this.$store.dispatch("hideModal");

				this.$router.push({
					name: "IDR Job Configuration",
					params: {
						sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
						jobId: `${this.job.name}`,
					},
				});

				this.$store.dispatch("sandbox/fetchSandboxes");
			},			
			initMethod() {
				this.jobFields = this.jobFields.map((field) => {
					if (field.name == "kind") {
						field.display = false;
						field.options = [
							{ label: "Transform", value: "TRANSFORM" },
						];
					}
					return field;
				});
			},
		},
	};
</script>

<style></style>
