//Note: Reuseable common function can be added here
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat)
function DateTimeFormat(value){
    if(value && value!=="")
        return dayjs(value).format("MMM D, YYYY h:mm A")
    return value
}
//Return true if it is duplicate name
function isDuplicate(name, list, fieldName, referenceName) {
    //In edit - if Existing and new name are same no need to check duplicate
    if(name===referenceName){
        return false
    }
    return list.some(val => val[fieldName] === name)
} 

function secondsToHourMinSec(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  // Combine the time components with commas
  var result = [hDisplay, mDisplay, sDisplay].filter(Boolean).join(", ");
  return result;
}

function secondsTohms(d) {
  d = Number(d);
  if(d) {
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h> 0? (h + "h "):"";
    let mDisplay = m> 0 ?(m + "m "): "";
    let sDisplay = s> 0 ?(s + "s"): "";
    return hDisplay + mDisplay + sDisplay; 
    
  } else {
    return "0s"
  }
  
}

function secondsToClock(d) {
  d = Number(d);
  return d ? new Date(d * 1000).toISOString().slice(11, 19) : '00.00.00'
}

function formatBytes(bytes, decimals = 2) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
} 

function relativeTime(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
  
    var elapsed = current - previous;
  
    if (elapsed < msPerMinute) {
      let s = Math.round(elapsed / 1000);
      return s > 0 ? s + (s == 1 ? "second ago" : "seconds ago") : "";
    } else if (elapsed < msPerHour) {
      let m = Math.round(elapsed / msPerMinute);
      return m > 0 ? m + (m == 1 ? "minute ago" : "minutes ago") : "";
    } else if (elapsed < msPerDay) {
      let h = Math.round(elapsed / msPerHour);
      return h > 0 ? h + (h == 1 ? "hour ago" : "hours ago") : "";
    } else if (elapsed < msPerMonth) {
      let d = Math.round(elapsed / msPerDay);
      return d > 0 ? d + (d == 1 ? "day ago" : "days ago") : "";
    } else if (elapsed < msPerYear) {
      let m = Math.round(elapsed / msPerMonth);
      return m > 0 ? m + (m == 1 ? "month ago" : "months ago") : "";
    } else {
      let y = Math.round(elapsed / msPerYear);
      return y > 0 ? y + (y == 1 ? "year ago" : "years ago") : "";
    }
  }

  function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
  }
  
  function generateLastSixMonthOptions() {
    const monthOptions = [{'label':'Custom','value':'custom'}];
      for (let i = 0; i < 6; i++) {
          const date = dayjs().subtract(i, 'month');
          monthOptions.push({
          label: date.format('MMMM'), // Name of month
          value: date.format('MM')    // 12"
          });
      }
      return monthOptions;
  }
  function getFirstDayOfMonth(month, year = dayjs().year()) {  
      const currentDate = dayjs();
      const currentMonth = currentDate.format('MM');
      const currentYear = currentDate.year();

      // If the provided month is greater than the current month, it's from the previous year
      if (month > currentMonth && year === currentYear) {
          year = year - 1;
      }          
      return dayjs(`${year}-${month.toString().padStart(2, '0')}-01`)
          .startOf('month')
          .format("YYYY-MM-DD");
  }

  function getLastDayOfMonth(month, year = dayjs().year()) {
      const today = dayjs(); 
      const currentMonth = today.format('MM');
      const currentYear = today.year();

      // If the provided month is greater than the current month, it's from the previous year
      if (month > currentMonth && year === currentYear) {
          year = year - 1;
      }  
      // Create a date for the first day of the next month
      const nextMonth = dayjs(`${year}-${month.toString().padStart(2, '0')}-01`)
          .add(1, 'month');
      
      // Subtract one day to get the last day of the given month
      let lastDay = nextMonth.subtract(1, 'day');
      
      // If it's the current month and year
      if (month === today.format('MM') && year === today.year()) {
          lastDay = today;
      }
      
      return lastDay.format("YYYY-MM-DD");
  }
    


export { DateTimeFormat, isDuplicate, secondsTohms, secondsToHourMinSec, secondsToClock, formatBytes, relativeTime, deepEqual, generateLastSixMonthOptions, getFirstDayOfMonth, getLastDayOfMonth}