<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Copy Pixel</h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <div class="mb-3">
                        <h3 for="exampleFormControlInput1" class="form-label">Choose a pixel to copy</h3>
                        <select
                            class="form-select"
                            v-model="selectedPixel"
                        >                            
                            <option
                                v-for="option in pixels"
                                :key="option.beaconId"
                                :value="option.beaconId"
                            >
                                {{ option.beaconId }}
                            </option>
                        </select>
                    </div>                    
                </div>
                <div class="col-md-10">
                    <div class="mb-3" v-if="selectedPixel">
                        <h3 for="exampleFormControlInput1" class="form-label">Choose a class to copy to</h3>
                        <select
                            class="form-select"
                            v-model="selectedCls"
                        >                            
                            <option
                                v-for="cls in classes"
                                :key="cls.cls"
                                :value="cls.cls"
                            >
                                {{ cls.cls }}
                            </option>
                        </select>
                    </div>                    
                </div>               
            </div> 
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button
            >&nbsp;
            <button class="btn btn-primary" id="myButton" @click="copyPixel()">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    Copy
                </div>
            </button>&nbsp;            
        </div>
    </div>   
</template>

<script>

import axios from 'axios';
export default {
    data() {
        return {
            selectedCls: null,
            selectedPixel: null,
            pixels: this.modalData.pixels,
            loading: false,
            pixelData: null
        }
    },
    computed: {
        classes() {
            const currentCls = this.modalData.currentCls
            return this.modalData.classes.filter(cls => cls.cls !== currentCls);
        } 
    },
    watch: {
        selectedPixel(newVal) {
            this.pixelData = this.pixels.filter(pixel => pixel.beaconId === newVal)[0];
        }
    },
    mounted() {
        this.selectedPixel = this.modalData.currentPixel.beaconId;
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        }
    },
    methods: {
        copyPixel() {
            let self = this;
            this.loading = true;
            let toastOptions;
            axios.get(`${this.$store.state.mgmtUrl}/v1/staging/clients/${this.$store.state.currentClient.cid}/classes/${this.selectedCls}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then(async (response) => {
                await this.modalData.onCopyConfirm(response.data,self.selectedCls, {...self.pixelData}); 
                this.loading = false;
                this.$store.dispatch("hideModal");
                toastOptions = {
                        message: `pixel copied to class  ${this.selectedCls} successfully`,
                        type: "success"
                    }
                this.$store.dispatch('addToast', toastOptions);

            }).catch((err) => {
                this.loading = false;
                toastOptions = {
                        message: "Something went wrong when copying the pixel. Please contact support.",
                        type: "error"
                    }
                this.$store.dispatch('addToast', toastOptions);
                this.$store.dispatch("hideModal");
            })           
            
        }
    }

}
</script>

<style>

</style>