const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" }
        ]
    },
    {
        name: "value",
        label: "Value",
        type: "text",
        editable: true,
        validation: [
            { type: "required",errMsg: "Value is required" }
        ]
    },{
        name: "url",
        label: "URL",
        type: "text",
        editable: true,
        validation: [
            { type: "url", base:''}
        ]
    },
    {
        name: "description",
        label: "Description",
        type: "text",
        editable: true,
    },
    {
        name: "logo",
        label: "Logo",
        type: "image",
        editable: true,
        maxSize: 60,// maximum size in KB,
        format: 'png'
    }
];

export default Fields;