<template>    
    <div class="">       
        <div class="card">
            <datatable tableId="jobListTable" :columns="columns" :formatColumn="formatColumn"  :orderColumn="orderColumn" :data="jobList" :compact="true"
                :detailColumn="{
                    renderBaseUrl: (data) => {
                        return `${data.name}/run-history`;
                    },
                }"
                uniqueField="name"
                :dropDownFilterColumn=dropDownFilterColumn
                />
        </div>
    </div>
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import { secondsTohms } from '../../../utils/commonFunction';

export default {
    components: { Datatable },
    name: "Jobs",
    data() {
       
        return {
            activeTab: "config",
            orderColumn: [3, 'desc'],
            columns: [
                {
                    data: "name",
                    title: "Job Name"
                },
                {
                    data: "type",
                    title: "Job Type",
                    render: function (data, type, row) {
                        let jobType = data;
                        switch (jobType) {
                            case '1': jobType = 'Collation'; break;
                            case '2': jobType = 'Ingestion'; break;
                            case '3': jobType = 'Analysis'; break;
                            case '4': jobType = 'Other'; break;
                            case '5': jobType = 'Sandbox'; break;
                            case '6': jobType = 'Distribution'; break;
                            case 'Undefined': jobType = 'Other'; break;
                        }
                        //To have this render value in dropdown filter setting valur for type
                        row.type=jobType
                        return jobType
                    },
                },               
                {
                    data: "last_run_started_at", 
                    title: "Last Run At"
                },
                {
                    data: "last_run_time", 
                    title: "Last Run Time",
                    render: function (data, type, row) {
                        return secondsTohms(data)
                    },
                },
                {
                    data: "last_run_status", 
                    title: "Last Run Status"
                },
                {
                    data: "created_at", 
                    title: "Created"
                },
            ],
            //Column number for creating select dropdown
            dropDownFilterColumn: [ 2 ],
            formatColumn:[
                {
                    index:3,
                    type:"datetime"
                },
                {
                    index:6,
                    type:"datetime"
                }
            ],
        };
    },
    props: ["selectedSandbox"],
    computed: {       
        jobList(){
            let jobList=[];
            if(this.$store.state.amdp.jobs) {
                this.$store.state.amdp.jobs.map((job)=>{
                    let status = job.last_run_status?job.last_run_status.toLowerCase():'';
                    let statusStr = job.last_run_status;
                    if(status==="succeeded" || status==="completed"){
                        statusStr = "<span class='text-success'>●</span> "+job.last_run_status;
                    } else if(status==="failed"){
                        statusStr = "<span class='text-danger'>●</span> "+job.last_run_status;
                    } else if(status==="running"){
                        statusStr = "<span class='text-primary'>●</span> "+job.last_run_status;
                    } else if(status==="submitted"){
                        statusStr = "<span class='text-warning'>●</span> "+job.last_run_status;
                    } else if(status==="cancelled"){
                        statusStr = "<span class='text-secondary'>●</span> "+job.last_run_status;
                    }
                    job["last_run_status"] = statusStr;
                    jobList.push(job);
                })
            }            
            return jobList;
        }          
    },
      
    beforeMount() {
        this.$store.dispatch("amdp/fetchJobs", this.$route.params.sandboxId);  
    }
};
</script>

<style></style>