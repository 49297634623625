import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'
import admin from './modules/admin.store'
import amdp from './modules/amdp.store'
import aut from './modules/aut.store'
import aio from './modules/aio.store'
import agm from './modules/agm.store'
import sandboxUnitTest from './modules/sandbox-unit-test.store'
import sandbox from './modules/sandbox.store'
import VuexPersistence from 'vuex-persist'
import { useToast, POSITION } from 'vue-toastification'
import { FAILSAFE_SCHEMA } from 'js-yaml'

const toast = useToast()

const vuexLocal = new VuexPersistence({
    //Specifiy the field(s) you want to persist on refesh. If empty, retains everything by default.
    modules: ['aut'],
    reducer: (state) => ({
        aut: {
            currentClass : state.aut.currentClass
        },        
        currentClient : state.currentClient, 
        toastNotif: state.toastNotif,
        forwardTo: state.forwardTo,
        redirectPath: state.redirectPath,
        paginationCache: state.paginationCache
    }),
    storage: window.localStorage
})
  
const initialState = () => {

    var environment =
        (function (h) {
            if (h.includes('preprod')) {
                return "pre_prod";
            } else if (h.includes('localhost')) {
                return "test";
            } else if (h.includes('dev')) {
                return "dev";
            } else {
                return (h.includes('staging')) ? "pre_prod" : "prod";
            }
        })(document.location.hostname);

    var authPortalUrl = null;
    var authUrl = null;
    var countsUrl = null;
    var dmsUrl = null;
    var docsUrl = null;
    var imdsUrl = null;
    var lakeviewUrl = null;
    var mgmtUrl = null;
    var clientMgmtUrl = null;
    var pailUrl = null;
    var surfaceUrl = null;
    var cookieDomain = null;
    var cidLoading = false;
    var tagUrl = 'c901.aut-prod.aqfer.net';

    switch (environment) {
        case 'dev':
            cookieDomain = 'api-dev.aqfer.net'
            authPortalUrl = 'https://auth.api-dev.aqfer.net'
            authUrl = 'https://auth.api-dev.aqfer.net'
            countsUrl = 'https://counts.api-dev.aqfer.net'
            docsUrl = 'https://docs-dev.aqfer.net'
            dmsUrl = 'https://dms.api-dev.aqfer.net'
            lakeviewUrl = 'https://lakeview.api-dev.aqfer.net'
            imdsUrl = 'https://imds.api-dev.aqfer.net'
            mgmtUrl = 'https://atm-config.api-dev.aqfer.net'
            clientMgmtUrl = 'https://client-mgmt.api-dev.aqfer.net'
            pailUrl = 'https://pail.api-dev.aqfer.net'
            surfaceUrl = 'https://surface.api-dev.aqfer.net'
            break;
        case 'test':
            cookieDomain = 'api-dev.aqfer.net'
            authPortalUrl = 'http://localhost:3000/auth'
            authUrl = 'http://localhost:3000/auth'
            countsUrl = 'http://localhost:3000/counts'
            docsUrl = 'http://localhost:3000/docs'
            dmsUrl = 'http://localhost:3000/dms'
            lakeviewUrl = 'http://localhost:3000/lakeview'
            imdsUrl = 'http://localhost:3000/imds'
            mgmtUrl = 'http://localhost:3000/mgmt'
            clientMgmtUrl = 'http://localhost:3000/client-mgmt'
            pailUrl = 'http://localhost:3000/pail'
            surfaceUrl = 'http://localhost:3000/surface'
            break;
        case 'pre_prod':
            cookieDomain = 'app-staging.aqfer.net'
            authPortalUrl = 'https://auth.app-staging.aqfer.net'
            authUrl = 'https://auth.api-preprod.aqfer.net'
            countsUrl = 'https://counts.api-preprod.aqfer.net'
            dmsUrl = 'https://dms.api-preprod.aqfer.net'
            docsUrl = 'https://docs-staging.aqfer.net'
            lakeviewUrl = 'https://lakeview.api-preprod.aqfer.net'
            imdsUrl = 'https://imds.api-preprod.aqfer.net'
            mgmtUrl = 'https://atm-config.api-preprod.aqfer.net'
            clientMgmtUrl = 'https://client-mgmt.api-preprod.aqfer.net'
            pailUrl = 'https://pail.api-preprod.aqfer.net'
            surfaceUrl = 'https://surface.api-preprod.aqfer.net'
            break;
        default:
            cookieDomain = 'app.aqfer.net'
            authPortalUrl = 'https://auth.app.aqfer.net'
            authUrl = 'https://auth.api.aqfer.net'
            countsUrl = 'https://counts.api.aqfer.net'
            dmsUrl = 'https://dms.api.aqfer.net'
            docsUrl = 'https://docs.aqfer.net'
            lakeviewUrl = 'https://lakeview.api.aqfer.net'
            imdsUrl = 'https://imds.api.aqfer.net'
            mgmtUrl = 'https://atm-config.api.aqfer.net'
            clientMgmtUrl = 'https://client-mgmt.api.aqfer.net'
            pailUrl = 'https://pail.api.aqfer.net'
            surfaceUrl = 'https://surface.api.aqfer.net'
            break;
    }


    return {
        environment: environment,
        cookieDomain:cookieDomain,
        authPortalUrl: authPortalUrl,
        authUrl: authUrl,
        countsUrl: countsUrl,
        dmsUrl: dmsUrl,
        docsUrl: docsUrl,
        lakeviewUrl: lakeviewUrl,
        imdsUrl: imdsUrl,
        mgmtUrl: mgmtUrl,
        clientMgmtUrl: clientMgmtUrl,
        pailUrl: pailUrl,
        surfaceUrl: surfaceUrl,
        tagUrl: tagUrl,
        currentUser: null,
        currentClient: null,
        clients: null,
        docsToken: null,
        loading: false,
        restrictedAccess: false,
        collapseNav: false,
        showModal: false,
        modalComponent: null,
        modalData: null,
        toastNotif: true,
        forwardTo: null,
        redirectPath: null,
        datatableCache:{},
        paginationCache:{},
        activeTab:null,
        breadcrumbs:[],
        navItems: [
            {
                name: 'Home',
                icon: 'home',
                route: '/'
            }, {
                name: 'Admin',
                icon: 'unlock',
                roles: ["aqfer_admin","aqfer_user","client_admin"],
                children: [{
                    name: 'Users',
                    icon: 'user-group',
                    route: '/admin/users',
                    roles: ['aqfer_admin', 'client_admin'],
                }, {
                    name: 'Clients',
                    icon: 'users-gear',
                    route: '/admin/clients',
                    roles: ['aqfer_admin'],
                }, {
                    name: 'Entitlement Usage (beta)',
                    icon: 'chart-column',
                    route: '/admin/usage',
                    roles: ['aqfer_admin','client_admin','aqfer_user'],
                    domainSpecific: "@aqfer.com",
                },{
                    name: 'Infrastructure Usage',
                    icon: 'chart-column',
                    route: '/admin/infrastructure-usage',
                    roles: ['aqfer_admin','client_admin', 'aqfer_user'],
                    domainSpecific: "@aqfer.com",
                },
                 {
                    name: 'Reports',
                    icon: 'clipboard-list',
                    children: [                        
                        {
                            name: 'Sign Ins',
                            route: '/admin/reports/sign-ins'
                        },
                    ],
                    roles: ['aqfer_admin', 'client_admin'],
                }, {
                    name: 'Dashboards',
                    icon: 'chart-line',
                    route: '/admin/dashboards',
                    roles: ['aqfer_admin','aqfer_user','client_admin'],
                },
                {
                    name: 'Tokens',
                    icon: 'key',
                    route: '/admin/tokens',
                    roles: ['aqfer_admin','client_admin'],
                },
                {
                    name: 'EncryptedURLs',
                    icon: 'link',
                    route: '/admin/encrypted-urls',
                    roles: ['aqfer_admin'],
                    domainSpecific: "@aqfer.com",
                },
                {
                    name: 'Products',
                    icon: 'cubes',
                    route: '/admin/products',
                    roles: ['aqfer_admin'],
                    //environmentRestrictions: ['dev','pre_prod'],
                    domainSpecific: "@aqfer.com",
                }, 
                {
                    name: 'Entitlements',
                    icon: 'id-card',
                    route: '/admin/entitlements',
                    roles: ['aqfer_admin'],
                    //environmentRestrictions: ['dev','pre_prod'],
                    domainSpecific: "@aqfer.com",
                },
                {
                    name: 'Job Templates',
                    icon: 'file',
                    route: '/admin/job-templates',
                    roles: ['aqfer_admin','client_admin'],
                    domainSpecific: "@aqfer.com",
                },
            ]
            }, {
                name: 'aUT',
                featureId: 'TAG_MANAGER',
                icon: 'tag',
                optional: true,
                children: [                        
                    {
                        name: 'Settings',
                        icon: 'gear',
                        route: '/:cid/aut/settings',
                    },
                    {
                        name: 'Classes',
                        icon: 'layer-group',
                        route: '/:cid/aut/classes'    
                    },
                    {
                        name: "Tag Template Library",
                        icon: 'user-tag',
                        route: '/:cid/aut/tag-templates'
                    },
                    {
                        name: "Initiators",
                        icon: 'database',
                        route: '/:cid/aut/initiators',
                        roles: ['aqfer_admin']
                    },
                    {
                        name: "Initiator Fields",
                        icon: 'list',
                        route: '/:cid/aut/initiator-fields',
                        roles: ['aqfer_admin']
                    },
                    {
                        name: "Browse Tag Templates",
                        icon: 'tags',
                        route: '/:cid/aut/browse-tag-templates'
                    },
                    {
                        name: "Generate Tag",
                        icon: 'regular fa-circle-play',
                        route: '/:cid/aut/generate-tag'
                    },
                    {
                        name: "Configure Hosts",
                        icon: 'globe',
                        route: '/:cid/aut/configure-hosts'
                    },
                    {
                        name: 'Reports',
                        icon: 'clipboard-list',
                        route: '/:cid/aut/reports',
                    }, 
                    {
                        name: 'Dashboard',
                        icon: 'chart-line',
                        route: '/:cid/aut/dashboards'
                    },{
                        name: 'Usage',
                        icon: 'chart-column',
                        route: '/:cid/aut/usage',
                        domainSpecific: "@aqfer.com",
                    }]
            }, {
                name: 'aMDP',
                featureId: 'DATA_LAKE',
                icon: 'database',
                optional: true,
                children: [{
                    name: 'Named Queries',
                    icon: 'clipboard-question',
                    route: '/:cid/amdp/queries',
                }, {
                    name: 'Credentials',
                    icon: 'key',
                    route: '/:cid/amdp/credentials',
                },
                {
                    name: 'Jobs',
                    icon: 'list-check',
                    route: '/:cid/amdp/:sandboxId/jobs',
                    sandboxTypes: ['prod','prodV2']
                },
                {
                    name: 'Dashboards',
                    icon: 'chart-line',
                    route: '/:cid/amdp/jobs-dashboard',
                    featureItemID: "jobs_dashboards"
                },
                {
                    name: 'Usage',
                    route: '/:cid/amdp/usage',
                    icon: 'chart-column',
                    domainSpecific: "@aqfer.com",
                },
                {
                    name: 'Sandbox',
                    icon: 'box-open',
                    children: [{
                        name: 'Unit Test',
                        route: '/:cid/amdp/sandbox/unit-test',
                        roles: ['nobody']
                    },
                    {
                        name: 'Management',
                        route:'/:cid/amdp/sandboxes',
                        roles: ['aqfer_admin']
                    },
                    {
                        name: 'Users',
                        route:'/:cid/amdp/sandbox/users',
                        roles: ['aqfer_admin','client_admin']
                    },{
                        name: 'Job List',
                        route:'/:cid/amdp/sandbox/:sandboxId/jobs',
                        sandboxTypes: ['dev']
                    },{
                        name: 'Promotions',
                        route:'/:cid/amdp/sandbox/:sandboxId/promotions',
                        stateConditional: 'sandbox/hasProdV2Sandbox',
                        sandboxTypes: ['prodV2']
                    },{
                        name: 'File Browser',
                        route:'/:cid/amdp/:sandboxId/file-browser',
                        roles: ['aqfer_admin'],
                        domainSpecific: "@aqfer.com",
                        sandboxTypes: ['dev']
                    },
                    {
                        name: 'Credentials',
                        route: '/:cid/amdp/:sandboxId/credentials',
                        sandboxTypes: ['dev']
                    },
                ]
                },]
            }, {
                name: 'aGM',
                icon: 'circle-nodes',
                featureId: "AGM",
                optional: true,
                children: [{
                    name: 'Dashboards',
                    route: '/agm/dashboards',
                    icon: 'chart-line',
                },{
                    name: 'IDR',
                    icon: 'list-check',
                    route:'/:cid/agm/sandbox/:sandboxId/jobs',
                    sandboxTypes: ['dev'],
                }]
            }, {
                name: 'aIO',
                icon: 'arrow-right-arrow-left',
                featureId: "AIO",
                optional: true,
                children: [{
                    name: 'Dashboards',
                    route: '/:cid/aio/dashboards',
                    icon: 'chart-line',
                },{
                    name: 'Usage',
                    route: '/:cid/aio/usage',
                    icon: 'chart-column',
                    domainSpecific: "@aqfer.com",
                },{
                    name: 'Handler Sandbox',
                    icon: 'box-open',
                    route: '/:cid/aio/:sandboxId/handler-sandbox',
                    featureItemID: "aio_sandbox_beta",
                    sandboxTypes: ['dev'],
                    domainSpecific: "@aqfer.com",
                }]
            },  {
                name: 'Apps',
                icon: 'laptop-code',
                children: [{
                    name: 'Demo 1',
                    route: '/apps/demo1',
                }, {
                    name: 'Demo 2',
                    route: '/apps/demo2',
                }]
            }

        ]
    }
};

const store = createStore({
    state: initialState(),
    mutations: {
        setInitialState(state) {
            state = initialState()
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setRestrictedAccess(state, accessState) {
            state.restrictedAccess = accessState;
        },
        setCollapseNav(state, collapseNav) {
            state.collapseNav = collapseNav
        },
        setShowModal(state, showModal) {
            state.showModal = showModal
        },
        setModalComponent(state, modalComponent) {
            state.modalComponent = modalComponent
        },
        setModalData(state, modalData) {
            state.modalData = modalData
        },
        setModalValue(state, modalValue) {
            state.modalValue = modalValue;
        },
        
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken
        },
        setCurrentUser(state, currentUser) {
            state.currentUser = currentUser
        },
        setCurrentClient(state, currentClient) {
            state.currentClient = currentClient
        },
        setClients(state, clients) {
            state.clients = clients
        },
        setDocsToken(state, docsToken) {
            state.docsToken = docsToken
        },
        setCidLoading(state, cidLoading) {
            state.cidLoading = cidLoading
        },        
        setForwardTo(state, forwardTo) {
            state.forwardTo = forwardTo
        },
        setRedirectPath(state, redirectPath) {
            state.redirectPath = redirectPath
        },
        setDatatableCache(state, datatableCache) {
            state.datatableCache = datatableCache
        },
        setPaginationCache(state, paginationCache) {
            state.paginationCache = paginationCache
        },
        setActiveTab(state,activeTab){
            state.activeTab = activeTab
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
    },
    actions: {
        setInitialState({ commit }) {
            commit('setInitialState')
        },
        setLoading({ commit }, loading) {
            commit('setLoading', loading)
        },
        setRestrictedAccess({commit}, accessState) {
           commit('setRestrictedAccess', accessState)
        },
        showModal({ commit }, {component, data, value}) {
            commit('setModalComponent', component)
            commit('setModalData', data)
            commit('setModalValue', value )
            commit('setShowModal', true)
        },
        hideModal({ commit }) {
            commit('setShowModal', false)
        },
        setModalComponent({ commit }, modalComponent) {
            commit('setModalComponent', modalComponent)
        },
        setModalData({ commit }, modalData) {
            commit('setModalData', modalData)
        },
        setCollapseNav({ commit }, collapseNav) {            
            commit('setCollapseNav', collapseNav)
        },
        setModalValue({ commit }, modalValue) {            
            commit('setModalValue', modalValue)
        },
        setAccessToken({ commit }, accessToken) {
            commit('setAccessToken', accessToken)
        },
        setForwardTo({ commit }, forwardTo) {
            commit('setForwardTo', forwardTo)
        },
        setRedirectPath({ commit }, redirectPath) {
            commit('setRedirectPath', redirectPath)
        },
        setDatatableCache({ commit }, datatableCache) {
            commit('setDatatableCache', datatableCache)
        },
        setPaginationCache({commit}, paginationCache) {
            commit('setPaginationCache', paginationCache)
        },
        setActiveTab({ commit }, activeTab) {
            commit('setActiveTab', activeTab)
        },
        setBreadcrumbs({ commit }, breadcrumbs) {
            commit('setBreadcrumbs', breadcrumbs)
        },
        onClientChange() {
            this.dispatch('amdp/onClientChange')
            this.dispatch('aut/onClientChange')
            this.dispatch('sandbox/onClientChange')
            this.dispatch('aio/onClientChange')
        },
        afterClientChange() {
            this.dispatch('sandbox/afterClientChange')
        },
        setCurrentClient({ commit }, clientId) {
            commit('setCidLoading', true)
            this.dispatch('onClientChange')
            this.dispatch('fetchCurrentClient',clientId)
            .then(async (response) => {
                commit('setCurrentClient', response.data)
                commit('setCidLoading', false);
                await this.dispatch('sandbox/fetchSandboxes', clientId)
                //this.dispatch("updatePathParams", {cid: clientId});
             })
            
        },
        // Action for views to subscribe when cid changed from sidebar dropdown
        setCidChangeTrigger({ commit } , trigger) {
            commit('setCidChangeTrigger', trigger);
        },
        updatePathParams({ commit }, newParams) {
            console.log("inside update path params");
            const currentParams = router.currentRoute.params;  
            const mergedParams = { ...currentParams, ...newParams };  
            // When router is not supplied path or name,
            // it simply tries to update current route with new params or query
            // Almost everything is optional.
            router.push({ params: mergedParams });
        },
        setUpdatedCurrentClient({commit}, clientId) {
            this.dispatch('fetchCurrentClient',clientId)
            .then((response) => {
                commit('setCurrentClient', response.data)
             })
        },
        async fetchCurrentClient({state},clientId){
            return axios.get(state.mgmtUrl + `/v1/staging/clients/${clientId}?detail=pixel`, {
                    headers: {
                        Authorization: `Bearer ${state.accessToken}`,
                    },
                })
        },
        addToast({commit}, {type, message, config}) {
            if(!config)
            config = { 
                            position: POSITION.BOTTOM_CENTER,
                            transition: "Vue-Toastification__fade",
                            timeout: 2400
                        };
             
            if(type == "error") {
                toast.error(message, config);
            } 
            else if(type == "info") {
                toast.info(message, config);
            } 
            else if(type == "warning") {
                toast.warning(message, config);
            } 
            else if(type == "success"){
                toast.success(message, config);
            }
            else {
                toast.info(message, config);
            }
        },
        dismissToast({commit}, toastId) {
            toast.dismiss(toastId);
        },
        login({ state }) {
            state.toastNotif = true;
            const failureUrl = encodeURIComponent(window.location.href)
            const successUrl = encodeURIComponent(window.location.origin)
            const gConfigUrl = `${state.authUrl}/v1/config?success_redirect_url=${successUrl}&failure_redirect_url=${failureUrl}`

            axios(gConfigUrl).then((response) => {
                //console.log(response)
                const gConfig = response.data.result
                const returnPath = `${state.authPortalUrl}/v1/openid-connect`
                const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${gConfig.clientId}&response_type=code&scope=openid%20email&redirect_uri=${returnPath}&login_hint=sub&state=${gConfig.state}`
                //console.log(googleUrl)
                location.replace(googleUrl);
            });
        },
        handleUserData({ commit }, userData) {
            if (userData.email) {
                commit('setCurrentUser', userData)
            }
        },
        handleClientList({ state, commit }, params) {  
            let clientsData = params.clientsData;
            let routeParams = params.routeParams;  
            if (clientsData) {
                commit('setClients', clientsData);

                let cid = clientsData[0].cid
                if(routeParams.cid && clientsData.some(client => client.cid === routeParams.cid)) {
                    cid = routeParams.cid;
                } else if(state.currentClient && clientsData.some(client => client.cid === state.currentClient.cid)){
                    cid = state.currentClient.cid;
                }
                                
                //getting detailed view of client
                this.dispatch('setCurrentClient', cid)
            }
        },
        handleDocsAuthData({ commit }, docsAuthData) {
            if (docsAuthData.docsToken) {
                commit('setDocsToken', docsAuthData.docsToken)
            }
        },
        logout({ state, commit }) {
            commit('setInitialState')
            axios.post(state.authUrl + '/v1/logout', null, {
                headers: {
                    'Authorization': `Bearer ${state.accessToken}`
                }
            })
            .finally((res) => {
                const {$cookies} = router.app.config.globalProperties
                //access-token is not removed properly so restting to empty and expiring the date
                $cookies.set('access-token','',new Date().toUTCString(),"/",state.cookieDomain,null,'Strict')
                $cookies.remove('authExpireTime', null)
                router.push('/login')
            })
        },
        whoAmICheck({state}){ 
            return axios.get(state.authUrl + '/v1/who-am-i', {
                headers: {
                    'Authorization': `Bearer ${state.accessToken}`
                }
            })
            .then((res) => {
                //From Who-ma-i getting Token expire time and creating new cookie variable
                if(new Date(res.data.token_expires_at).getTime()<new Date().getTime() && store.state.environment != 'test'){
                    store.dispatch('setRedirectPath', window.location.pathname)
                    store.dispatch('logout');
                    return
                }
                return res;
            })
            .catch((error) => {
                console.error(error)
            })
        },
        getBlogData(){
            return  axios.get('https://aqfer.com/wp-json/wp/v2/posts')
            .then(response => {
                return response.data
            });
        },
        async getHelpjuiceURL({rootState}) {
            return axios.get(rootState.surfaceUrl + "/v1/getPortalHelpjuiceURL", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const url = response.data;
                return url
            }).catch((error) => {
                console.log(error)
            });
        },
    },
    getters: {
        userRoles() {
            const rolesRank = {
                aqfer_admin: 1,
                aqfer_user: 2,
                client_admin: 3,
                client_user: 4,
            };
        
            let currentUserRoles = store.state.currentUser && store.state.currentUser.roles?store.state.currentUser.roles: ["client_user"];    
        
            const isAqferAdmin = currentUserRoles.includes("aqfer_admin")
                                ? true
                                : false;
            const isClientAdmin = currentUserRoles.includes("client_admin")
                                ? true
                                : false;
        
            const getRoleRank = (roles) => {
                let rank = 5;
                roles.forEach(function (role) {
                    if (rolesRank[role] < rank) {
                        rank = rolesRank[role];
                    }
                });
                return rank;
            };

            const getUserRole = (roles) => {  
                let rank = 5;
                let highestRole = "client_user";
                roles.forEach(function (role) {
                    if (rolesRank[role] < rank) {
                        rank = rolesRank[role];
                        highestRole = role;
                    }
                });
                return highestRole;
            };

            const isAccessByRole = (accessRoles) => {
                return store.state.currentUser.roles.some(r => accessRoles.indexOf(rolesRank[r]) >= 0);                                
            }
        
            const userRoleRank = getRoleRank(currentUserRoles);
        
            return { isAqferAdmin, isClientAdmin, getRoleRank, userRoleRank, isAccessByRole, getUserRole };
        }
    },
    modules: {
        admin,
        amdp,
        aut,
        aio,
        sandboxUnitTest,
        sandbox,
        agm

    },
    plugins: [vuexLocal.plugin]
})

export default store